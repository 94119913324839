import { TriggerService } from '../../@uno-entity/service/entity-trigger.service';
import { EM, } from '../../@uno/api/event-mgmt.service';
import { Common, Router } from '../../@uno/api/common.service';

class SessionManagerImpl {
    private DEFAULT_STORAGE = (Common.Screen.isSmall() || Common.Screen.isMobile()) ? window.localStorage : window.sessionStorage;
    private DEFAULT_ID = 'sessions';

    private StorageMap: any = {};

    constructor() {
        if (!this.getSessions()) {
            this.persist({});
        }

        EM.register(Common.Event.SIGNED_OUT, (data: any) => {
            SessionManager.clearSession(data.appID);
        });

        EM.register(Common.Event.SIGNED_IN, (data: any) => {
            SessionManager.setSession(data.appID, data.session, false, data.route);
        });

        EM.register(Common.Event.APP_SWITCHED, async (data: any) => {
            const appID = data.id;
            const storage = await this.initStorage(appID);
            const session = this.getSession(appID);
            console.log('Session Manager: App Switched: ', appID, session, storage);
            SessionManager.setSession(appID, session, false);
        });

    }

    private aSession: any;

    private set activeSession(session: any) {
        this.aSession = session;
    }

    public get activeSession() {
        return this.aSession;
    }

    getSessions(id: string = this.DEFAULT_ID) {
        const data = this.getStorage(id).getItem(id);
        if (data) {
            return Common.safeParse(data);
        }
        return {};
    }

    async setSession(appID: string, session: any, updateOnly = false, route?: string) {
        // this.activeSession = session;
        // trigger event.
        if (!updateOnly) {
            if (session) {
                // Common.showMessage('Ready to take off. Fasten your seat belt.', true, 100);
                const result = await TriggerService.send({ appID: appID, session: session, }, Common.Event.SIGNED_IN, false);
                if (result?.message) {
                    Common.showMessage(result.message);
                }
                session = result.session;
            } else {
                // Common.showMessage('Collecting the garbage...', true, 100);
                const result = await TriggerService.send({ appID: appID, session: this.activeSession, }, Common.Event.SIGNED_OUT, false);
                if (result?.message) {
                    Common.showMessage(result.message);
                }
            }
        }
        // update the session in local cache.
        await this.updateSession(appID, session);
        /*
        const sessions = await this.getSessions(appID);
        sessions[appID] = session;
        await this.persist(sessions, appID);
        this.activeSession = session;
        */

        // trigger user session event
        if (!updateOnly) {
            if (this.activeSession) {
                // Common.showMessage('Zooming up.');
                EM.emit(Common.Event.USER_SESSION_CREATED, { appID: appID, session: this.activeSession, route: route });
            } else {
                // Common.showMessage('Just Flushing');
                EM.emit(Common.Event.USER_SESSION_CLEARED, { appID: appID, route: route });
            }
        }

    }

    async updateSession(appID: string, session: any) {
        // update the session in local cache.
        const sessions = this.getSessions(appID);
        sessions[appID] = session;
        await this.persist(sessions, appID);
        this.activeSession = session;
    }

    async persist(sessions: any, id: string = this.DEFAULT_ID) {
        const data: any = Common.stringify(sessions);
        await this.addToStorage(id, data, id);
    }

    getSession(appID: string) {
        return this.getSessions(appID)[appID];
    }

    clearSession(appID: string) {
        this.setSession(appID, undefined);
    }

    getRoute = (appID: string, path: string,) => {
        if (appID) {
            return `/${appID}/${path}`;
        } else {
            return '';
        }
    }

    getStorage = (id: string = this.DEFAULT_ID): Storage => {
        const storage = this.StorageMap[id] || this.DEFAULT_STORAGE;
        return storage;
    }

    addToStorage = async (id: string, value: string, appID: string) => {
        const storage = await this.initStorage(appID);
        storage.setItem(id, value);
    }

    async initStorage(appID: string): Promise<Storage> {
        const Map = this.StorageMap;

        if (!Map[appID]) {
            const result = await TriggerService.send({ appID: appID, session: this.activeSession, event: 'GET_APP_STORAGE_TYPE' });
            switch (result?.type) {
                case 'local':
                    Map[appID] = window.localStorage;
                    break;
                case 'session':
                    Map[appID] = window.sessionStorage;
                    break;
                default:
                    Map[appID] = this.DEFAULT_STORAGE;
            }
        }

        return Map[appID];
    }

    getSignInRoute = (appID: string) => {
        return this.getRoute(appID, 'do/signin');
    }

    getSignUpRoute = (appID: string) => {
        return this.getRoute(appID, 'do/signup');
    }

    getHomeRoute = (appID: string) => {
        return this.getRoute(appID, '');
    }

    getViewProfileRoute = (appID: string) => {
        return Router.buildRoute(appID, Common.CategoryID.Person, Router.CatAction.VIEW, SessionManager.activeSession?.person?._id);
    }

    getEditPasswordRoute = () => {
        return this.activeSession?.edit;
    }
}

export const SessionManager = new SessionManagerImpl();
