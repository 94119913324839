import React, { useEffect, useRef } from 'react';
import * as iink from 'iink-ts';
import { EntityConstants } from '../../../api';
import { DesignerConstants, UnoComponent } from '../../../core';
import { UnoCoreBaseComp } from './../uno-core.base.comp';

const WritePadComp = (props: any) => {
    let content: any = undefined;
    let editorEle: any = undefined;
    let editor: any = undefined;
    const editorRef: any = useRef(null);

    const editorStyle = {
        'minWidth': '100px',
        'minHeight': '100px',
        'width': '100vw',
        'height': 'calc(100vh - 190px)',
        'touchAction': 'none',
    };

    useEffect(() => {
        const init = async () => {
            editorEle = editorRef.current;
            editor = await iink.Editor.load(editorRef.current, "INTERACTIVEINK" /* or INTERACTIVEINKSSR or INKV2 or INKV1 */,
                // options
                {
                    configuration: {
                        server: {
                            applicationKey: 'c0d22404-bdaa-4b3e-b406-614b2f9559f5',
                            hmacKey: '93ed935d-0251-46b6-85e3-9f9ae302304e',
                        }
                    }
                }
            );

            editorEle.addEventListener('exported', (evt: any) => {
                const getText = () => {
                    let text = ``;
                    try {
                        let elements: Array<any> = evt?.detail["application/vnd.myscript.jiix"].elements || [];
                        elements?.forEach((e: any) => {
                            const words = e.words || [];
                            words.forEach((w: any) => {
                                text += w.label + ' ';
                            })
                        });

                    } catch (e) { }

                    return text.trim();
                }

                // console.log('Write Pad Exported: ', evt?.detail);
                if (evt) {
                    content = getText();
                    // console.log('Written Input: ', content);
                }
            });

            window.addEventListener('resize', () => {
                editor.resize()
            });

            return () => {
                window.removeEventListener('resize', () => { editor.resize() });
                editor.close();
            }

        }

        init();
    }, []);

    return (
        <div>
            <button
                onClick={
                    () => {
                        const onSubmit = props.onSubmit;
                        if (onSubmit && content) {
                            onSubmit(content);
                        }
                    }
                }
            >
                Submit
            </button>
            <div style={editorStyle} ref={editorRef} touch-action="none">
            </div>
        </div>
    )
}


// export default WritePad;

@UnoComponent({
    id: 'WritePad',
    label: 'Write Pad',
    group: DesignerConstants.PaletteGroup.Editor.id,
    props: [
        { id: 'onSubmit', label: 'onSubmit Handler', dataType: EntityConstants.PropType.FUNCTION },
        { id: 'options', label: 'Options', dataType: EntityConstants.PropType.JSON },
        { id: 'mic_on', label: 'On Mic Indicator:', dataType: EntityConstants.PropType.IMAGE },
        { id: 'mic_off', label: 'Off Mic Indicator:', dataType: EntityConstants.PropType.IMAGE },
    ],
    paletteable: false,
})
export default class WritePad extends UnoCoreBaseComp {
    buildComp(): JSX.Element | null | undefined {
        return (<WritePadComp {...this.state} />);
    }
}