import 'aframe';
import 'aframe-particle-system-component';
import { Entity, Scene } from 'aframe-react';
import React from 'react';
import { Common } from '../../../@uno/api';
import { DesignerConstants, UnoComponent, UnoCoreBaseComp } from '../../../@uno/core';

@UnoComponent(
    {
        id: 'AFrame',
        label: 'AFrame VR',
        paletteable: true,
        props: [
        ],
        group: DesignerConstants.PaletteGroup.Integration.id,
    }
)
export class AframeComp extends UnoCoreBaseComp {
    private vizID = Common.getUniqueKey('aframe_viz_');


    buildComp(): JSX.Element {
        return (
            <>
                (
                <Scene>
                    <Entity geometry={{ primitive: 'box', width: 5 }} material={{ color: 'red' }} position={{ x: 500, y: 500, z: -5 }} />
                    <Entity particle-system={{ preset: 'snow' }} />
                    <Entity light={{ type: 'point' }} />
                    <Entity gltf-model={{ src: 'virtualcity.gltf' }} />
                    <Entity text={{ value: 'Hello, WebVR!' }} />
                </Scene>
                );
            </>
        );
    }

}