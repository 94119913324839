import React from 'react';
import { BaseEntity, Common, EntityConstants, FilterConstants } from '../../../@uno/api';
import { Images, UC, UnoComponent, UnoCoreBaseComp } from '../../../@uno/core';
import { HistoryService } from '../../service/history.service';

const HistoryPropIDs = {
    TS: 'timestamp',
    DELTA: 'delta',
    ACTOR: 'actor'

}

// ENTITY - NEW, EDIT
@UnoComponent({
    id: 'ChangeHistory',
    label: 'Change History',
    props: [
    ],
    getDesign: () => { return (<div>History - Design</div>); }
})
export class ChangeHistory extends UnoCoreBaseComp {
    showing = false;
    loaded = false;
    records: Array<BaseEntity> = [];

    componentDidMount(): void {
        super.componentDidMount();
        this.loadRecords();
    }

    render() {
        return this.viewHistory();

        // return <>{this.showing ? this.viewHistory() : this.buildTool()}</>
    }

    buildTool = (title: string = this.getTitle()) => {
        return (
            <img
                src={Images.Icon.history}
                className='tool-icon'
                title={title} alt={title}
                onClick={
                    () => {
                        this.toggleView();
                    }
                } />
        );
    }

    viewHistory = () => {
        return this.loaded ? this.viewRecords() : <UC.Loading target={() => { this.setState({}) }} />;
        /*
        return (
            <>
                <UC.Dialog onClose={() => { this.toggleView(); }} title='Change History' >
                    {this.loaded ? this.viewRecords() : <UC.Loading />}
                </UC.Dialog>
            </>
        );
        */
    }

    viewRecords = () => {
        const recordViews = this.records?.map(
            r => {
                const actor = EntityConstants.build(r.getValue(HistoryPropIDs.ACTOR));
                const time = new Date(r.getValue(HistoryPropIDs.TS));
                const deltaObj = r.getValue(HistoryPropIDs.DELTA);
                // console.log(`Delta: `, deltaObj);
                const deltaView = (deltaObj && deltaObj.delta) ? (
                    deltaObj.delta.map(
                        (d: any) => {
                            return (
                                <div>
                                    <b>{d.propID}</b> :
                                    <i>
                                        <div dangerouslySetInnerHTML={{ __html: Common.stringify(d.oldValue) || '' }} />
                                    </i>
                                    &gt;&gt;
                                    <i>
                                        <div dangerouslySetInnerHTML={{ __html: Common.stringify(d.newVal) || '' }} />
                                    </i>
                                </div>
                            );
                        }
                    )
                ) : (<></>);
                console.log(`Delta: `, deltaObj);
                return (
                    <div>
                        <div>
                            <b>{r.getName()}</b>
                        </div>
                        <div>
                            {deltaView}
                        </div>
                        <hr />
                    </div>
                );
            }
        );
        return (
            <>{recordViews}</>
        )
    }

    getTitle() {
        return `History`;
    }

    toggleView = () => {
        this.showing = !this.showing;
        if (this.showing) {
            // reload
            this.loadRecords();
        } else {
            this.loaded = false;
        }
        this.setState({});
    }

    loadRecords = async () => {
        let entity = this.state.entity;
        if (!entity) {
            console.log('No Entity for History', this.state);
            return;
        }
        entity = EntityConstants.build(entity);
        const appID = entity.getAppID() || this.getAppID();

        const F = FilterConstants;
        const filters = [
            F.category(Common.CategoryID.History),
            F.is('entity', entity),
            // F.desc('timestamp'),
        ];
        this.records = await F.find(filters, { limit: Number.MAX_SAFE_INTEGER }, appID);
        console.log('History Filters: ', filters, this.records);

        this.loaded = true;
        this.reRender({ loaded: this.loaded, records: this.records });
    }

}