import React from 'react';
import { DesignerConstants, UC, UnoCompEvents, UnoComponent, UnoCoreBaseComp } from '../../@uno/core';
import { EntityConstants } from '../../@uno/api';
import { UnoSvgBase } from './uno-svg-base.comp';

@UnoComponent({
    id: 'UnoSvgRect',
    label: 'SVG Rectangle',
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Graphics.id,
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    props: [
        {
            groupID: 'Config',
            id: 'width',
            label: 'Width',
        },
        {
            groupID: 'Config',
            id: 'height',
            label: 'Height',
        },
        {
            groupID: 'Config',
            id: 'x',
            label: 'Left Position',
            dataType: EntityConstants.PropType.NUMBER,
        },
        {
            groupID: 'Config',
            id: 'y',
            label: 'Top Position',
            dataType: EntityConstants.PropType.NUMBER,
        },
        {
            groupID: 'Config',
            id: 'rx',
            label: 'Horizontal Corner (X) Radius',
            dataType: EntityConstants.PropType.NUMBER,
        },
        {
            groupID: 'Config',
            id: 'ry',
            label: 'Vertical Corner (Y) Radius',
            dataType: EntityConstants.PropType.NUMBER,
        },

    ],
    isContainer: false,
})
export class UnoSvgRect extends UnoSvgBase {

    buildSVG() {
        return (
            <rect
                key={this.getInputID()}
                style={this.getStyles()}
                className={this.getStyleClasses()}

                width={this.getWidth()}
                height={this.getHeight()}
                x={this.getX()}
                y={this.getY()}
                rx={this.getRX()}
                ry={this.getRY()}

            />
        );
    }

    getWidth() {
        return this.state.width || '100%';
    }

    getHeight() {
        return this.state.height || '100%';
    }

    getX() {
        return this.state.x || '0';
    }

    getY() {
        return this.state.y || '0';
    }

    getRX() {
        return this.state.rx || '0';
    }

    getRY() {
        return this.state.ry || '0';
    }
}

