import React from 'react';
import { Common, Router } from '../../../@uno/api';
import { EntityConstants } from '../../../@uno/api/entity.service';
import { DesignerConstants, UC, UnoComponent } from '../../../@uno/core';
import { EntityViewTypes } from '../../service/entity-view.service';
import { ENTITY_LIST_COMP_PROPS, EntityListView } from './entity-list.view.comp';

// ENTITY - LIST VIEW 
@UnoComponent({
    id: EntityConstants.ListViewTypes.Chips.id,
    label: EntityConstants.ListViewTypes.Chips.label,
    props: ENTITY_LIST_COMP_PROPS,
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Entity.id,
})
export class EntityChipsView extends EntityListView {

    buildContent() {
        const eViews = this.buildEntities()?.map(view => {
            return (
                <UC.Chip
                    key={Common.getUniqueKey()}
                    // onClose={() => { alert('Will remove this during edit.') }}
                    styles={{ ...this.getStyles(), }}
                    className={this.getStyleClasses()}
                >
                    {view}
                </UC.Chip>
            );
        });

        return (
            <div
                key={Common.getUniqueKey()}
                // style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}
                className=' flex-container '
            >
                {eViews}
            </div>
        )
    }
}
