import React from 'react';
import { DesignerConstants, UC, UnoCompEvents, UnoComponent, UnoCoreBaseComp } from '../../@uno/core';
import { Common, EntityConstants } from '../../@uno/api';
import { UnoSvgBase } from './uno-svg-base.comp';

@UnoComponent({
    id: 'UnoSvgEllipse',
    label: 'SVG Ellipse',
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Graphics.id,
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    props: [
        {
            groupID: 'Config',
            id: 'rx',
            label: 'Horizontal (X) radius',
            dataType: EntityConstants.PropType.NUMBER,
            validators: [EntityConstants.Validator.REQUIRED,],
        },
        {
            groupID: 'Config',
            id: 'ry',
            label: 'Vertical (Y) radius',
            dataType: EntityConstants.PropType.NUMBER,
            validators: [EntityConstants.Validator.REQUIRED,],
        },
        {
            groupID: 'Config',
            id: 'center',
            label: 'Center',
            dataType: EntityConstants.PropType.JSON,
            editor: 'SVGPointEditor',
            viewer: 'SVGPointViewer'
        },
    ],
    isContainer: false,
})
export class UnoSvgEllipse extends UnoSvgBase {

    buildSVG() {

        return (
            <ellipse
                key={this.getInputID()}
                style={this.getStyles()}
                className={this.getStyleClasses()}

                rx={this.getRadiusX()}
                ry={this.getRadiusY()}
                cx={this.getCX()}
                cy={this.getCY()}

            />
        );
    }

    getRadiusX() {
        return this.state.rx || '0';
    }

    getRadiusY() {
        return this.state.ry || '0';
    }

    getCX() {
        return this.getCenter()?.x || 0;
    }

    getCY() {
        return this.getCenter()?.y || 0;
    }

    getCenter() {
        return Common.safeParse(this.state.center);
    }
    
}

