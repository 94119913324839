import { Common } from './common.service';

const envVars = process.env;
console.log('Env vars: ', Object.keys(envVars), Object.values(envVars));

const nodeEnv = process.env.NODE_ENV;
console.log('Node Process ENV: ', nodeEnv);

const apiServer = process.env.REACT_APP_API_SERVER || 'http://127.0.0.1:9898/';
console.log('API Server: ', apiServer);

const remoteURL: string = nodeEnv === 'development' ? `${window.location.origin}/api/` : apiServer;

const HEADERS = {
    'Content-Type': Common.ContentType.JSON,
};

class RemoteServiceImpl {

    getOptions = (data: any, httpMethod: string = 'POST', corsMode: string = 'cors', headers = {}) => {
        return {
            method: httpMethod, // *GET, POST, PUT, DELETE, etc.
            mode: corsMode, // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: Common.stringify(data) // body data type must match 'Content-Type' header
        }
    };

    upload(fileToUpload: File, headers: any = {}, appID?: any, session?: any): Promise<Response> {
        const endpoint = 'file/up';
        const formData: FormData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        // console.log('To Upload file: ', appID, fileToUpload, session);
        return this.post(endpoint, formData,
            {
                ...headers,
                'APP-ID': appID,
                'MULTI-PART': true,
                'SESSION-ID': session?.id,
                'SERVER-ID': session?.serverID,
            },
        );
    }

    get = async (url: string, headers?: any) => {
        // console.log('GET request: ', url);
        return this.request(url, 'GET', undefined, headers);
    }

    post = async (url: string, body: any = {}, headers?: any) => {
        // console.log('POST request: ', url, body);
        return this.request(url, 'POST', body, headers);
    }

    request = async (url: string, method = 'GET', body = {}, headers?: any) => {
        url = this.getFullURL(url);

        const fetchThen = (val: any) => {
            // console.log('Fetch response: ', url, method, body, val);
            return val;
        }

        const fetchCatch = (e: any) => {
            // console.log('Fetch ERROR: ', url, method, body, e);
            const resp: any = { json: async () => { return { error: e.message }; } };
            return resp;
        }

        return new Promise<Response>((resolve, reject) => {
            const isMultiPart = (headers && headers['MULTI-PART']);
            headers = headers ? (isMultiPart ? headers : { ...HEADERS, ...headers }) : { ...HEADERS };

            const input: any = {
                headers: headers,
                method: method,
            };

            if (input.method === 'POST') {
                if (isMultiPart) {
                    input.body = body;
                    // console.log('Multi Part Request: ', url, input);
                } else {
                    input.body = Common.stringify(body);
                }

                //input.body = Common.stringify(body);
            }

            try {
                fetch(url, input)
                    .then(v => {
                        resolve(fetchThen(v))
                    })
                    .catch(e => {
                        resolve(fetchCatch(e));
                    });
            } catch (e) {
                resolve(fetchCatch(e));
            }

        });
    }

    async getData(fetchResponse: any) {
        let data: any = undefined;
        if (fetchResponse?.json) {
            try {
                data = await fetchResponse.json();
            } catch (e) { }
        } else {
            data = fetchResponse;
        }
        return data;
    }

    getFullURL(url: string): string {
        url = '' + url;
        if (url.startsWith('http://') || url.startsWith('https://')) {
            return url
        }
        return this.getRemoteURL().concat(url);
    }


    getRemoteURL(): string {
        return remoteURL;
    }

}

const RemoteService = new RemoteServiceImpl();
export { RemoteService };

