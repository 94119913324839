import React from 'react';
import { EM, EntityConstants, FilterConstants, Router } from '../../@uno/api';
import { Common, UC, UnoComponent, Images, UnoCoreBaseComp } from '../../@uno/core';
import { EntityBaseComp } from './entity-base.comp';
import { EntitySearch } from './entity-search.comp';

const EVT_VIEW_VISIBILITY_CHANGED = 'EVT_VIEW_VISIBILITY_CHANGED';
const MainActions = {
    // ['new', 'filter', 'list', 'detail']
    New: 'new',
    Filter: 'filter',
    List: 'list',
    Detail: 'detail',
}

const views: any = {}
const getView = (id: string) => {
    if (!views[id]) {
        views[id] = {};
    }
    return views[id];
}

const isViewVisible = (id: string) => {
    return getView(id).visibility;
}

const getViewStyle = (id: string) => {
    return document.getElementById(id)?.style;
}


const getCompAction = () => {
    return Router.CatAction.MAIN;
}

const updateViewVisibility = (viewIDs: Array<string> = Object.keys(views)) => {
    viewIDs.forEach(
        id => {
            getView(id).visibility = !getView(id).visibility;
        }
    );
}

const setViewWidth = (viewID: string, width: number) => {
    if (Common.isSmallScreen() && width > 0) {
        width = 100;
    }
    getView(viewID).width = `${width}%`;
    const style = getViewStyle(viewID);
    if (style) {
        style.width = getView(viewID).width;
    }
}

const deleteViewVisibility = (viewIDs: Array<string>) => {
    viewIDs.forEach(
        id => {
            delete getView(id).visibility;
        }
    );
}

const toggleVisibility = (id: string) => {

    const styles = getViewStyle(id);
    if (styles) {
        if (styles.display === 'none') {
            styles.display = 'block';
        } else {
            styles.display = 'none';
        }
    }

    updateViewVisibility([id]);
    EM.emit(EVT_VIEW_VISIBILITY_CHANGED, id);
}


// ENTITY - MAIN
@UnoComponent({ id: 'EntityMain' })
export class EntityMain extends EntityBaseComp {
    protected filterConditions: any = [];
    private ViewIDs = {
        filterViewID: Common.getUniqueKey('_filterView'),
        detailViewID: Common.getUniqueKey('_detailView'),
        listViewID: Common.getUniqueKey('_listView'),
    }
    private _handlerEntityChangeEvent: any;
    private _handlerEVT_VIEW_VISIBILITY_CHANGED: any;

    constructor(props: any) {
        super(props);

        // set initial visibility state.
        getView(this.ViewIDs.filterViewID).visibility = false;
        getView(this.ViewIDs.listViewID).visibility = true;
        getView(this.ViewIDs.detailViewID).visibility = false;
        this.updateViewWidths();
    }

    canProfile(): boolean {
        return false;
    }

    componentDidMount(): void {
        super.componentDidMount();

        this._handlerEntityChangeEvent = EM.register(this.getEntityChangeEvent(),
            () => {
                const detailViewID = this.ViewIDs.detailViewID;
                if (isViewVisible(detailViewID) === false) {
                    toggleVisibility(detailViewID);
                }
            }
        );

        this._handlerEVT_VIEW_VISIBILITY_CHANGED = EM.register(EVT_VIEW_VISIBILITY_CHANGED, (id: string) => {
            this.updateViewWidths();
        });

    }

    componentWillUnmount(): void {
        EM.unregister(this._handlerEntityChangeEvent);
        EM.unregister(this._handlerEVT_VIEW_VISIBILITY_CHANGED);
        super.componentWillUnmount();

        deleteViewVisibility(Object.values(this.ViewIDs));
    }

    buildContent(): JSX.Element {
        this.filterConditions = [...this.getDefaultFilters()];
        // console.log('Filter Conditions: ', this.filterConditions);

        const layout = this.buildDesigner();
        return (
            <>
                {layout ? layout :
                    (
                        <>
                            {this.buildPageHeaderView()}
                            {this.buildPageContentView()}
                        </>
                    )
                }
            </>
        );
    }

    // View units...

    buildPageContentView() {
        return (
            <div className='flex-container'>
                {this.buildFilterView()}
                {this.buildListView()}
                {/* this.buildDetailView() */}
            </div>
        );

    }

    buildFilterView() {
        const category = this.getCategory();
        const categoryID = category?.id || this.getCategoryID();
        const filterViewID = this.ViewIDs.filterViewID;

        const oProps = {
            ...this.getOtherProps(),
            title: 'Find',
            hideTitle: true,
            onShowResult: (conditions: any) => {
                // console.log(`Filter Conditions changed: `, conditions);
                this.filterConditions = conditions;
                EM.emit(this.getFilterChangeEvent(), conditions);
            }
        };

        return (
            <div
                id={filterViewID}
                //className='flex-child'
                style={{
                    // flexGrow: 1,
                    display: this.getDisplay(filterViewID),
                    width: this.getViewWidth(filterViewID),
                    // overflowX: 'auto',
                }}
            >
                <EntitySearch
                    category={category}
                    categoryID={categoryID}
                    otherProps={oProps}
                    key={Common.getUniqueKey()}
                />
            </div>
        );
    }

    buildDetailView() {
        const detailViewID = this.ViewIDs.detailViewID;

        return (
            <div
                id={detailViewID}
                // className='flex-child'
                style={{
                    // flexGrow: 5,
                    display: this.getDisplay(detailViewID),
                    width: this.getViewWidth(detailViewID),
                    // overflowX: 'auto'
                }}
            >
                <div>
                    <DetailViewComp
                        entityChangeEvent={this.getEntityChangeEvent()}
                        filterChangeEvent={this.getFilterChangeEvent()}
                        key={Common.getUniqueKey()}
                    />
                </div>
            </div>
        );
    }

    buildListView() {
        const listViewID = this.ViewIDs.listViewID;
        this.profiler.log('Building List View', listViewID);
        return (
            <div
                id={listViewID}
                // className='flex-child'
                style={{
                    // flexGrow: 1,
                    display: this.getDisplay(listViewID),
                    width: this.getViewWidth(listViewID),
                    padding: '0px 0px',
                    overflow: 'auto',
                }}
            >
                <ListViewComp
                    conditions={this.filterConditions}
                    onNoResult={`No ${this.getCategoryLabel()} found.`}
                    filterChangeEvent={this.getFilterChangeEvent()}
                    entityChangeEvent={this.getEntityChangeEvent()}
                    key={Common.getUniqueKey()}
                    viewType={this.getListViewType()}
                    category={this.getCategory()}
                    pageNavPosition={this.getPageNavPositionConfig()}
                    noPageNav={this.getNoPageNavConfig()}
                />
            </div>
        );
    }

    updateViewWidths = () => {
        const screenWidth = window.screen.width;
        const screenFactor = (100 / screenWidth);

        let filter = 0, list = 0, detail = 0;
        if (isViewVisible(this.ViewIDs.filterViewID)) {
            filter = Math.floor(screenFactor * 400);
        }
        setViewWidth(this.ViewIDs.filterViewID, filter);
        /*
        if (isViewVisible(this.ViewIDs.detailViewID)) {
            detail = Math.floor(screenFactor * 600);
        }
        setViewWidth(this.ViewIDs.detailViewID, detail);
        */
        if (isViewVisible(this.ViewIDs.listViewID)) {
            list = Math.floor(100 - (filter + detail));
        }
        setViewWidth(this.ViewIDs.listViewID, list);
    }

    getNoPageNavConfig() {
        const noPageNav = this.getCategoryConfigs()?.noPageNav;
        if (noPageNav !== undefined) {
            return Boolean(noPageNav).valueOf();
        } else {
            return false;
        }
    }

    getPageNavPositionConfig() {
        const pageNavPosition = this.getCategoryConfigs()?.pageNavPosition;
        if (pageNavPosition !== undefined) {
            return pageNavPosition;
        } else {
            return undefined; //'bottom';
        }

    }

    getListViewType() {
        let listViewType = this.getCategory()?.extras?.listViewType;
        if (!listViewType) {
            listViewType = this.getDefaultListViewType();
        }
        return listViewType;
    }

    getDefaultListViewType = () => {
        let viewType: string = EntityConstants.ListViewTypes.Table.id;
        if (Common.isSmallScreen()) {
            viewType = EntityConstants.ListViewTypes.Grid.id;
        }
        return viewType;
    }

    getDisplay(id: string) {
        // return 'block';
        return isViewVisible(id) ? 'block' : 'none';
    }

    getViewWidth(id: string) {
        return getView(id).width;
    }

    buildPageHeaderView() {
        // Add Actions from Category
        const actions = this.getCategoryMainActions();
        const catActions = this.getCategoryActions(getCompAction());

        return (
            <div className='flex-container'>
                <div className='flex-child' style={{ flexGrow: 4 }}>
                    <h3>{this.getTitle()}</h3>
                </div>
                <div className='flex-child' style={{ flexGrow: 4, }}>
                    <div style={{ float: 'right' }}>
                        <ActionTools
                            ViewIDs={this.ViewIDs}
                            appID={this.getAppID()}
                            categoryID={this.getCategoryID()}
                            actions={actions}
                            extraActions={catActions}
                            viewType={this.getListViewType()}
                        />
                    </div>
                </div>
            </div>
        );
    }

    getTitle() {
        let title: any = `${this.getCategoryLabel()} List`;
        if (this.getCategoryConfigs()?.noTitle) {
            title = undefined;
        } else if (this.getCategoryConfigs()?.title) {
            title = this.getCategoryConfigs()?.title;
        }
        return title;
    }
    // Utilities...

    getEntityChangeEvent = () => {
        return this.ViewIDs.listViewID;
    }

    getFilterChangeEvent = () => {
        return this.ViewIDs.filterViewID;
    }

    getCategoryMainActions() {
        // return this.getCategory()?.extras?.actions?.mainActions;
        return this.getCategoryConfigs()?.actions;
    }

}

class ActionTools extends UnoCoreBaseComp {
    private _handler_EVT_VIEW_VISIBILITY_CHANGED: any;

    componentDidMount(): void {
        super.componentDidMount();
        this._handler_EVT_VIEW_VISIBILITY_CHANGED = EM.register(EVT_VIEW_VISIBILITY_CHANGED, (id: string) => {
            if (Object.values(this.props.ViewIDs).includes(id)) {
                this.setState({});
            }
        });
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        EM.unregister(this._handler_EVT_VIEW_VISIBILITY_CHANGED);
    }

    buildComp() {
        const ViewIDs = this.props.ViewIDs;

        const createAction = (label: string = 'Close', icon: any = Images.Icon.close, action?: Function, params?: any) => {
            return {
                id: label,
                icon: icon,
                action: () => { if (action) { action(params); } },
            };
        }

        const getActionLabel = (id: string, label: string) => {
            return `${isViewVisible(id) ? 'Hide' : 'Show'} ${label}`;
        }

        const actions: Array<any> = [];
        let supportedActions: any = undefined;

        const actionConfigs = this.state.actions;
        if (Array.isArray(actionConfigs?.enabled)) {
            supportedActions = [...actionConfigs.enabled];
        }

        // const supportedActions: Array<string> = (this.props.actions?.length > 0) ? this.props.actions : Object.values(MainActions);
        if (!supportedActions) {
            supportedActions = Object.values(MainActions);
        }

        supportedActions.forEach(
            (a: any) => {
                const aID = (Common.checkType.Object(a)) ? a.id : a;
                const aLabel = (Common.checkType.Object(a)) ? a.label : undefined;

                switch (aID) {
                    case MainActions.New:
                        actions.push({ id: 'Create New', label: aLabel, icon: Images.Icon.plus, to: Router.buildRoute(this.props.appID, this.props.categoryID, Router.CatAction.CREATE), });
                        break;
                    case MainActions.Filter:
                        actions.push(createAction(aLabel ? aLabel : getActionLabel(ViewIDs.filterViewID, 'Filters'), Images.Icon.search, toggleVisibility, ViewIDs.filterViewID));
                        break;
                    case MainActions.List:
                        // actions.push(createAction(aLabel ? aLabel : getActionLabel(ViewIDs.listViewID, 'List'), this.getListViewIcon(), toggleVisibility, ViewIDs.listViewID));
                        break;
                    case MainActions.Detail:
                        // actions.push(createAction(aLabel ? aLabel : getActionLabel(ViewIDs.detailViewID, 'Details'), Images.Icon.view, toggleVisibility, ViewIDs.detailViewID));
                        break;
                }
            }
        );

        /*
            { id: 'Create New', icon: Images.Icon.plus, to: Router.buildRoute(this.props.appID, this.props.categoryID, Router.CatAction.CREATE), },
            createAction(getActionLabel(ViewIDs.filterViewID, 'Filters'), Images.Icon.search, toggleVisibility, ViewIDs.filterViewID),
            createAction(getActionLabel(ViewIDs.listViewID, 'List'), this.getListViewIcon(), toggleVisibility, ViewIDs.listViewID),
            createAction(getActionLabel(ViewIDs.detailViewID, 'Details'), Images.Icon.view, toggleVisibility, ViewIDs.detailViewID),

        */

        const extraActions = Common.safeParse(this.props.extraActions);
        extraActions?.forEach(
            (ca: any) => {
                actions.push(ca);
            }
        );

        const isToolbar = Common.isSmallScreen() ?
            false : (
                (actionConfigs?.isToolbar !== undefined) ?
                    actionConfigs?.isToolbar :
                    true
            );

        return (
            <>
                {
                    /*             
                    <UC.Navigation navs={actions} isToolbar={(!Common.isSmallScreen())} key={Common.getUniqueKey('navs')} orientation='h' />
                    */
                    <UC.AccessControlledActions actions={actions} isToolbar={isToolbar} horizontal={true} key={Common.getUniqueKey('navs')} />
                }
            </>
        );
    }

    getListViewIcon() {
        const viewType = this.props.viewType;
        switch (viewType) {
            case EntityConstants.ListViewTypes.Carousel.id:
                return Images.Icon.carousel;
            case EntityConstants.ListViewTypes.Table.id:
                return Images.Icon.table;
            default:
                return Images.Icon.grid;
        }
    }
}

class DetailViewComp extends React.Component<any, any> {
    private _entitySelectionChangeHandler: any;
    private _filterChangeEventhandler: any;

    private selectedEntity: any;

    componentDidMount(): void {
        this._entitySelectionChangeHandler = EM.register(this.props.entityChangeEvent, (entity: any) => {
            this.selectedEntity = entity;
            this.setState({});
        });

        this._filterChangeEventhandler = EM.register(this.props.filterChangeEvent, (entity: any) => {
            this.selectedEntity = undefined;
            this.setState({});
        });
    }

    componentWillUnmount(): void {
        EM.unregister(this._entitySelectionChangeHandler);
        EM.unregister(this._filterChangeEventhandler);
    }

    render(): React.ReactNode {
        if (this.selectedEntity) {
            const entity = EntityConstants.build(this.selectedEntity);
            return (
                <>
                    <UC.EntityView
                        entityID={entity.getID()}
                        categoryID={entity.getCategoryID()}
                        appID={entity.getAppID()}
                        key={Common.getUniqueKey()}
                    />
                </>
            );
        } else {
            return (
                <>
                    <h4>Select an Item for the detailed view.</h4>
                </>
            );
        }
    }
}

class ListViewComp extends UnoCoreBaseComp {
    private _filterChangeHandler: any;
    private conditions: any;

    constructor(props: any) {
        super(props);
        this.conditions = this.props.conditions;
    }

    componentDidMount(): void {
        super.componentDidMount();
        this._filterChangeHandler = EM.register(this.props.filterChangeEvent, (conditions: any) => {
            // console.log(`Update List view for changed conditions: `, conditions);
            this.conditions = conditions;
            this.setState({});
        });
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        EM.unregister(this._filterChangeHandler);
    }

    buildComp() {
        if (this.conditions) {
            const catID = FilterConstants.getCategoryID(this.conditions);
            // console.log('Building Filter Result for Main: ', this.conditions, this.props.category);
            return (
                <>
                    <UC.FilterResults
                        conditions={this.conditions}
                        onNoResult={this.state.onNoResult}

                        pageNavPosition={this.state.pageNavPosition}
                        noPageNav={this.state.noPageNav}
                        category={this.state.category}
                        globalSort={true}

                        otherProps={
                            {
                                listViewType: this.props.viewType,
                                listViewAction: getCompAction(),
                                onSelected: (entity: any, index: number) => {
                                    if (entity?.category === catID) { // additional safety
                                        EM.emit(this.props.entityChangeEvent, entity);
                                    }
                                },
                                // canEdit: true,
                                // canViewFull: true,
                                canViewMain: false,
                                filterChangeEvent: this.props.filterChangeEvent,
                            }
                        }

                        key={Common.getUniqueKey()}
                    />
                </>
            );
        } else {
            return (
                <>
                    <h4>Select an Item for the detailed view.</h4>
                </>
            );
        }
    }


}