import React from 'react';
import { AppInfoService } from '../../@uno-app/service/app.info.service';
import { SessionManager } from '../../@uno-app/service/session.service';
import { Common, Images, Profiler } from '../api/common.service';
import { BaseEntity, EntityCategory, EntityConstants, EntityProp } from '../api/entity.service';
import { Source } from '../api/source.service';

const profiler = Profiler.init('uno-comp');

export const DesignerConstants = {
    TCPC: 'tcpc',
    Mode: Common.Designer.Mode,
    PaletteGroup: Common.Designer.PaletteGroup,

    getPaletteGroup: (id: string) => {
        const palettes = Object.values(DesignerConstants.PaletteGroup);
        for (let i = 0; i < palettes.length; i++) {
            const palette: any = palettes[i];
            if (palette.id === id) {
                return palette;
            }
        }
        return undefined;
    },

    getStyle: (config: any) => {
        const cStyles: any = {};
        if (config.styles) {
            const styleEntity: any = EntityConstants.build(config.styles);
            Object.assign(cStyles, styleEntity);
            if (styleEntity.other) {
                delete cStyles.other;
                const others = Common.parse(styleEntity.other);
                if (Common.checkType.Object(others)) {
                    // profiler.log('Applying Other styles on component');
                    Object.assign(cStyles, others);
                }
            }
        }

        if (config.styles) {
            // profiler.log(`Component Styles: `, config.styles, cStyles);
        }
        return cStyles;
    },

    getRendererFunction: (compDef: ComponentDef, mode?: string) => {
        const rendererDefault = compDef.getLive;
        let rendererFunction: any;
        switch (mode) {
            case DesignerConstants.Mode.DESIGN:
                rendererFunction = compDef.getDesign;
                break;
            case DesignerConstants.Mode.PREVIEW:
                rendererFunction = compDef.getPreview;
                break;
            case DesignerConstants.Mode.LIVE:
                rendererFunction = compDef.getLive;
                break;
            default:
                rendererFunction = rendererDefault;
        }

        return (rendererFunction) ? rendererFunction : rendererDefault;
    },

    isLiveMode: (theComp: any) => {
        const displayMode: any = theComp?.props?.displayMode;
        return (!displayMode || displayMode === DesignerConstants.Mode.LIVE);
    },

    isPreviewMode: (theComp: any) => {
        const displayMode: any = theComp?.props?.displayMode;
        return (displayMode === DesignerConstants.Mode.PREVIEW);
    },

    isDeignMode: (theComp: any) => {
        const displayMode: any = theComp?.props?.displayMode;
        return (displayMode === DesignerConstants.Mode.DESIGN);
    },
}

export const UnoCompEvents = {
    onLoad: 'onLoad',
    onUnLoad: 'onUnLoad',
    onViewUpdate: 'onViewUpdate',
    onClick: 'onClick',
    onPropChanged: 'onPropChanged',
    onEnter: 'onEnter',
    onKeyPress: 'onKeyPress',
};

export const DEFAULT_PROPS = {
    StyleClasses: {
        groupID: 'Style',
        id: 'styleClasses',
        label: 'Style Classes',
        description: 'space-separated list of CSS class names',
    },
    Styles: {
        groupID: 'Style',
        id: 'styles',
        label: 'Styles',
        dataType: EntityConstants.PropType.JSON,
    },
};

const DEFAULT_EVENTS: Array<string> = [
    UnoCompEvents.onLoad,
    UnoCompEvents.onUnLoad,
    // UnoCompEvents.onClick,
];

const CHILD: EntityProp = {
    id: 'child',
    label: 'Child',
    dataType: EntityConstants.PropType.LAYOUT,
    multiplicity: 1,
};

export const EventHandlerCategory: EntityCategory = {
    id: 'event_handler',
    label: 'Event Handler',
    notInNavs: true,
    props: [
        { id: 'event_id', label: 'Event ID' },
        { id: 'event_fn', label: 'Function', dataType: EntityConstants.PropType.FUNCTION }
    ],
    isCore: true,
}

export interface ComponentDef {
    id: string,
    label?: string,
    icon?: string,
    group?: string,
    serialNo?: number,
    template?: any,

    props?: Array<EntityProp>,
    events?: Array<string>,
    help?: any; // Layout or Text

    isContainer?: boolean,
    paletteable?: boolean,

    getDesign?(config?: any, clbk?: any, buildChildren?: Function): any,
    getPreview?(config?: any, clbk?: any, buildChildren?: Function): any,
    getLive?(config?: any, clbk?: any, buildChildren?: Function): any,
}

export class ContentConfig {
    static genID = () => { return Common.getUniqueKey('s_'); }

    id: string;

    compID: string;
    screen?: BaseEntity;
    eProps?: any;

    styles?: any;
    children?: Array<any>;
    props?: any;
    inheritedProps?: any;
    events?: any;
    handlers?: any;

    constructor(compID: string = 'VSection') {
        this.id = ContentConfig.genID();
        this.compID = compID;
    }
}

export class ComponentDefImpl implements ComponentDef {
    id: string = Common.getUniqueKey();
    label?: string;
    template?: any;
    props?: Array<EntityProp>;
    events?: Array<string> = []; //Object.keys(CompEvents);

    getDesign(config?: any, clbk?: any, buildChildren?: Function): any {
        return this.buildVisual(config, clbk, DesignerConstants.Mode.DESIGN, buildChildren);
    }

    getPreview(config?: any, clbk?: any, buildChildren?: Function): any {
        return this.buildVisual(config, clbk, DesignerConstants.Mode.PREVIEW, buildChildren);
    }

    getLive(config?: any, clbk?: any, buildChildren?: Function): any {
        return this.buildVisual(config, clbk, DesignerConstants.Mode.LIVE, buildChildren);
    }

    private buildChildren = (configChilden: any, clbk?: any, mode: string = DesignerConstants.Mode.PREVIEW) => {
        const isPreviewMode = () => {
            return (DesignerConstants.Mode.PREVIEW === mode);
        }
        // profiler.log('Build Children for Config : ', config);

        let children: Array<any> = [];

        if (configChilden?.length > 0) {
            // profiler.log(`Found children : `, config.children);
            const TheChildComp = isPreviewMode() ? UnoComponentManager.UNO_COMPS.PropViewer : UnoComponentManager.UNO_COMPS.PropEditor;
            const otherProps: any = {
                hideLabel: true,
                extras: {
                    mode: mode,
                }
            };

            children = configChilden?.map(
                (item: any, index: number) => {
                    otherProps.extras.onClear = () => {
                        // config.children.splice(index, 1);
                        // profiler.log(`Modified Config: `, config);
                        if (clbk) {
                            clbk();
                        }
                    }
                    return <TheChildComp
                        defaultValue={item}
                        entityProp={CHILD}
                        otherProps={otherProps}
                        key={EntityConstants.getUniqueKey()}
                        onPropChanged={(eProp: any, value: any) => {
                            value = Common.parse(value);
                            // profiler.log(`Changed Item # ${index}: `, value, ' Old Config: ', config);
                            configChilden.splice(index, 1, value);
                            // profiler.log(`Modified Config: `, config);
                            if (clbk) {
                                // clbk(DesignerConstants.Action.SAVE);
                            }
                        }}
                    />
                }
            );
        } else {
            // mode = DesignerConstants.Mode.PREVIEW;
        }
        return children;

    }

    private getPropValues(props: Array<EntityProp>, propVals?: any, parentPropVals?: any) {
        const propValsEntity = EntityConstants.build(propVals);
        const parentPropValsEntity = EntityConstants.build(parentPropVals);
        const propValues: any = {}
        props.forEach(p => {
            let pVal = EntityConstants.getValue(p, propValsEntity);
            if (!pVal) {
                pVal = EntityConstants.getValue(p, parentPropValsEntity);
            }
            propValues[p.id] = pVal;
        });
        return propValues;
    }

    private buildVisual(config: ContentConfig, clbk?: any, mode: string = DesignerConstants.Mode.PREVIEW, fnBuildChildren: Function = this.buildChildren) {
        if (!this.template) {
            return (<></>);
        } else {
            // identify target component def, 
            const Target = this.template;

            // build comp props
            const props = UnoComponentManager.getProps(config, mode, fnBuildChildren);

            // build children
            const buildChildrenComp = (theConfig: ContentConfig) => {
                return fnBuildChildren(Common.safeParse(theConfig.children), clbk, mode);
            }
            if (['FilterResults', ''].includes(config.compID)) {
                // profiler.log('Building comp: ', mode, { ...config });
            }
            return (
                <Target
                    {...props}
                    key={Common.getUniqueKey()}
                >
                    {buildChildrenComp(config)}
                </Target>
            );
        }
    }
}

export class DragDropManager {
    private static _instance: DragDropManager | undefined;
    private data: { config?: ContentConfig, remover?: Function } = {};
    private constructor() {

    }

    static getInstance = () => {
        if (!DragDropManager._instance) {
            DragDropManager._instance = new DragDropManager();
        }
        return DragDropManager._instance;
    }

    resetDragDrop = () => {
        this.data.config = undefined;
        this.data.remover = undefined;
    }

    initDrag = (config: any, remover?: Function) => {
        this.data.config = config;
        this.data.remover = remover;
    }

    finishDrop = (targetConfig: any, adder: Function) => {
        if (targetConfig === this.data.config) {
            alert('Dropped on itself. Cancel');
        } else if (this.data.config && adder) {

            const duplicate = UnoComponentManager.refreshID(this.data.config);
            // profiler.log('Adding Child:', this.data.config, duplicate);

            adder(duplicate);
            if (this.data.remover) {
                this.data.remover();
            }
        }
        this.resetDragDrop();
    }
}

function unoComponent(config: ComponentDef) {
    return (target: any) => {
        // profiler.log(`Registering Component. Target: ${target.name}, ID: ${config.id}, Icon: `, config.icon);
        UnoComponentManager.register(target, config);
    }
}

// Manager

class UnoComponentManagerImpl {
    public UNO_COMPS: any = {};
    private componentList: Array<{ id: string, config: ComponentDef, target: any }> = [];

    public getCompLabel(compID: string) {
        return this.find(compID)?.config?.label || compID;
    }

    public register(target: Function, config?: ComponentDef,) {
        if (!config?.id) {
            // profiler.log(`Invalid Component Config. `, config, target);
            return;
        }

        // add default props
        if (config.props && config.props?.length > 0) {
            const configProps = config.props;
            Object.values(DEFAULT_PROPS).forEach(
                defP => {
                    let existing = false;
                    for (let cp of configProps) {
                        if (cp.id === defP.id) {
                            existing = true;
                        }
                    }
                    if (!existing) {
                        configProps.push(defP);
                    }
                }
            );
        } else {
            config.props = Object.values(DEFAULT_PROPS);
        }

        // add default events
        if (config.events && config.events?.length > 0) {
            const configEvents = config.events;
            DEFAULT_EVENTS.forEach(
                defE => {
                    let existing = false;
                    for (let ce of configEvents) {
                        if (ce === defE) {
                            existing = true;
                        }
                    }
                    if (!existing) {
                        configEvents.push(defE);
                    }
                }
            );
        } else {
            config.events = [...DEFAULT_EVENTS];
        }

        // transfer to new instance of ComponentDefImpl;
        config = Object.assign(new ComponentDefImpl(), config);
        if (!config.template) {
            config.template = target;
        }
        if (!config.id) {
            config.id = target.name;
        }
        if (!config.label) {
            config.label = config.id;
        }

        if (!config.icon) {
            config.icon = Images.Icon.component;
        }

        let mComp = this.find(config.id);

        if (!mComp) {
            mComp = { id: config.id, config: config, target: target };
            // profiler.log(`Registered Component. ID: ${mComp.id}, Target: ${mComp.target.name}`)
            this.componentList.push(mComp);
        }

        const unoCompTemplate = config.template;
        if (unoCompTemplate) {
            this.UNO_COMPS[config.id] = unoCompTemplate;
            // profiler.log('Uno Comps: ', this.UNO_COMPS);
        }
        return mComp;
    }

    public unregister(id: string) {
        for (let i = 0; i < this.componentList.length; i++) {
            const m = this.componentList[i];
            if (m.id === id || m.target.name === id) {
                delete this.UNO_COMPS[id];
                this.componentList.splice(i, 1);
                return;
            }
        }
    }

    public find(id: string) {
        for (let i = 0; i < this.componentList.length; i++) {
            const m = this.componentList[i];
            if (m.id === id) {
                return m;
            } else if (m.target.name === id) {
                return m;
            }
        }
        // profiler.log(`No Component Def found for id: ${id} in `, this.listCompIDs());
        return undefined;
    }

    public getDef(id: string) {
        const comp: any = this.find(id);
        if (comp) {
            return comp.config;
        } else {
            // profiler.log(`No Comp for ${id}`, comp);
        }
        return undefined;
    }

    public getComponent(id: string) {
        return this.find(id)?.config.template;
    }

    public listCompIDs() {
        return this.componentList.map(
            m => {
                return m.id;
            }
        );
    }

    public isContainerComp = (compID: string): boolean => {
        if (compID) {
            const compDef = this.getDef(compID);
            return compDef?.isContainer ? true : false;
        }
        return true;
    }

    public inflate(template: any, options: Array<any> = [], mode: string = DesignerConstants.Mode.PREVIEW) {
        // profiler.log(`Inflating in mode: `, mode, template);
        options.push({ Globals: Source.API.Globals });
        return EntityConstants.inflate(template, options, mode !== DesignerConstants.Mode.DESIGN);
    }

    public getProps(config: ContentConfig, mode: string, fnBuildChildren?: Function) {
        const appInfo: any = AppInfoService.getActiveApp();
        const session: any = SessionManager.activeSession;

        let ePropVals: any = config.eProps;
        if (ePropVals) {
            ePropVals = { ...ePropVals, category: undefined, _id: undefined };
        }

        let compEventHandlers: any = Common.safeParse(config.events);
        if (compEventHandlers) {
            compEventHandlers = { ...compEventHandlers, category: undefined, _id: undefined };
            Object.keys(compEventHandlers).forEach(id => {
                const ehVal = compEventHandlers[id];
                const ehValFn = Source.getFunction(ehVal);
                if (ehValFn) {
                    compEventHandlers[id] = ehValFn;
                }
            });
        }

        // generic event handlers
        const handledEvents: Array<string> = [];
        let handlers: any = Common.safeParse(config.handlers);

        if (handlers) {
            handlers = handlers.map((h: any) => {
                const event = h.event_id;
                handledEvents.push(event);
                const fn = Source.getFunction(h.event_fn ? h.event_fn : ``);
                return { event, fn };
            });
        }

        // add default event handlers
        const addHandler = async (eventID: string, fnLogic: string = ``) => {
            if (handledEvents.includes(eventID) == false) {
                if (!handlers) {
                    handlers = [];
                }
                const handler =
                {
                    event: eventID,
                    fn: Source.getFunction(fnLogic),
                };

                handlers.push(handler);
            }
        }

        addHandler(
            `${Common.Event.PRINTABLE_CONTENT_REQUESTED}_${config.id}`,
            `
                const printCompID = data?.compID;
                const theConfig = theComp.props.compConfig;
                const match = theConfig  && theConfig.id === printCompID;
                if(match){
                    const contentNode = await API.DOM.findDOMNode(theComp);
                    const printableContent = {compID: printCompID, content: contentNode?.innerHTML, contentNode: contentNode,}
                    // profiler.log(printCompID, ' - Printable Content PREPARED: ', printableContent);
                    // profiler.log('HTML:', API.DOM.findDOMNode(theComp)?.innerHTML);
                    API.EM.emit('${Common.Event.PRINTABLE_CONTENT_RECEIVED}', printableContent);
                }
            `
        );


        const propVals: any = config.props;
        const parentPropVals: any = config.inheritedProps;

        const compProps = {
            activeRoute: window.location.pathname,
            compConfig: config,
            fnBuildChildren: fnBuildChildren,
            compID: config.compID,
            displayMode: mode,
            app: appInfo,
            session: session,
            user: session?.person,
            styles: DesignerConstants.getStyle(config),
            ...parentPropVals,
            ...ePropVals,
            ...propVals,
            ...compEventHandlers,
            handlers: handlers,
            Icon: Images.Icon,
        };

        return compProps;
    }

    public getDefs() {
        return this.componentList.map(comp => {
            return comp.config;
        });
    }

    // refresh id of each comp in the tree;
    public refreshID = (config: ContentConfig) => {
        config = { ...config }; // duplicate
        config.id = ContentConfig.genID();
        config.children = config.children?.map(c => {
            return this.refreshID(c);
        });
        return config;
    }



    /*
    registerHandlers(comp: any) {
        const thisProps: any = comp.props;
        // profiler.log(`Register handlers for : `, thisProps);
        const handlers = thisProps?.handlers?.map((h: BaseEntity) => {
            h = EntityConstants.build(h);
            const eventFn = h.getValue('event_fn');
            const eventID = h.getValue('event_id');
            // profiler.log(`Registering Handler for: `, eventID);
            const handler = EM.register(eventID, async (data: any) => {
                // profiler.log(`Handling Event - ${eventID}: `, data);
                const result = await Source.execute(eventFn, {
                    inputs: data,
                    theComp: comp,
                    API: Source.API,
                });
            });
            return handler;
        });
        return handlers;
    }

    unregisterHandlers(handlers: Array<any>) {
        handlers?.forEach(h => {
            EM.unregister(h);
        });
    }
    */
}

export const UnoComponentManager = new UnoComponentManagerImpl();

export const UnoComponent = unoComponent;

