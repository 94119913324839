import React from 'react';
import { DesignerConstants, UC, UnoCompEvents, UnoComponent, UnoCoreBaseComp } from '../../@uno/core';
import { Common, EntityConstants } from '../../@uno/api';
import { UnoSvgBase } from './uno-svg-base.comp';

@UnoComponent({
    id: 'UnoSvgPolyline',
    label: 'SVG Polyline',
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Graphics.id,
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    props: [
        {
            groupID: 'Config',
            id: 'points',
            label: 'Points',
            dataType: EntityConstants.PropType.JSON,
            multiplicity: -1,
            validators: [EntityConstants.Validator.REQUIRED,],
            editor: 'SVGPointEditor',
            viewer: 'SVGPointViewer'
        },

    ],
    isContainer: false,
})
export class UnoSvgPolyline extends UnoSvgBase {

    buildSVG() {

        return (
            <polyline
                key={this.getInputID()}
                style={this.getStyles()}
                className={this.getStyleClasses()}

                points={this.getPoints()}

            />
        );
    }

    getPoints() {
        let pointVals = '';
        const points = Common.safeParse(this.state.points || []);
        for (let point of points) {
            pointVals = `${pointVals} ${point.x || 0},${point.y || 0}`;
        }

        console.log('Polygon Points: ', pointVals);
        return pointVals;
    }
}

