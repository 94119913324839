import React from 'react';
import { EntityCategory, EntityConstants, EntityProp } from '../../../@uno/api/entity.service';
import { UC, UnoComponent, Images, Common, DesignerConstants } from '../../../@uno/core';
import { EntityBaseComp } from '../entity-base.comp';
import { EntityCategoryService } from '../../../@uno-app/service/entity.category.service';

// initialize icons;
const Icons: any = {};
const initIcons = () => {
    Icons[EntityConstants.ListViewTypes.Carousel.id] = Images.Icon.caraousel;
    Icons[EntityConstants.ListViewTypes.Table.id] = Images.Icon.table;
    Icons[EntityConstants.ListViewTypes.Grid.id] = Images.Icon.grid;
    //Icons[EntityConstants.ListViewTypes.Tree.id] = Images.Icon.tree;
}
initIcons();

// MULTI ENTITY - MULTI VIEW 
@UnoComponent({
    id: 'MultiEntityView',
    label: 'Entity List View',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Entity.id,
})
export class MultiEntityView extends EntityBaseComp {
    private seletingProps = false;
    private selProps: Array<string> = [];
    private listViewType: string = Common.isSmallScreen() ? EntityConstants.ListViewTypes.Grid.id : EntityConstants.ListViewTypes.Table.id;

    constructor(props: any) {
        super(props);
        const oProps = this.getOtherProps();
        if (oProps) {
            if (oProps.listViewType) {
                this.listViewType = oProps.listViewType;
            }
        }

        this.selProps = oProps.visibleProps || [];

        if (!this.getCategory() && this.getEntityList()?.length > 0) {
            const entity = EntityConstants.build(this.getEntityList()[0]);
            const category = EntityCategoryService.getAppCategory(entity.getCategoryID(), entity.getAppID());
            if (category) {
                this.profiler.log('Setting category based on Entity List. ', { ...category });
                this.setCategory(Common.safeParse(category), false);
            } else {
                this.profiler.log('No category found based on an item in Entity List. ', entity.getCategoryID(), entity.getAppID(), [...this.getEntityList()]);
            }
        } else {
            this.profiler.log('Category pre-specified for entity list.', { ...this.getCategory() }, [...(this.getEntityList() || [])]);
        }

        this.profiler.log('Init Multi Entity View: ', this.getCategory(), this.getEntityList());
    }

    canProfile(): boolean {
        return false;
    }

    async loadCategory() {
        if (!this.getCategory()) {
            await super.loadCategory();
        }
    }

    buildContent() {
        const eList = this.getEntityList()
        if (eList) {
            if (eList.length === 0) {
                // return (<>0 records found</>);
                // return <UC.Empty />;
            }

            const categoryCopy: EntityCategory = Common.copy(this.getCategory()); //{ ...this.getCategory() };
            if (this.selProps?.length > 0) {

                const props: Array<any> = [];
                this.selProps.forEach(sp => {
                    const p = EntityConstants.getPropByID(categoryCopy.props || [], sp);
                    if (p) {
                        props.push(p);
                    }
                });

                categoryCopy.props = props;
                this.profiler.log('Apply selProps - ', this.getEntityList()?.length, ' entities using - ', this.listViewType, this.selProps, categoryCopy, this.getCategory());
            }

            this.profiler.log('Listing - ', this.getOtherProps(), eList,);
            let ListView = this.buildDesigner({ entities: eList, category: categoryCopy, entity: undefined });
            if (!ListView) {
                const ListViewComp = UC[this.listViewType];
                ListView = (
                    <ListViewComp
                        key={this.getUniqueKey()}
                        category={categoryCopy}
                        categorID={this.getCategoryID()}
                        entities={eList}
                        appID={this.getAppID()}
                        otherProps={this.getOtherProps()}
                    />
                );
            }

            return (
                <div style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'auto' }}>
                    {(eList.length === 0 || this.getLayout()) ? undefined : this.buildSettings()}
                    {ListView}
                </div>
            );
        } else {
            return <UC.Loading target={this.reRender} />
        }

    }

    private buildSettings() {
        const settingChoices: Array<any> = [];
        /*
        settingChoices.push({
            id: 'selProps',
            label: 'Properties',
            // icon: icon_prop_selector,
            action: () => { this.seletingProps = true; this.setState({}); }
        });
        */

        const viewChoices = this.getViewChoices();
        if (viewChoices?.length > 0) {
            settingChoices.push({
                id: 'viewAs',
                label: 'View As',
                icon: Images.Icon.viewAs,
                children: viewChoices,
            });
        }

        return (
            <>
                {this.buildPropSelector()}
                <UC.Navigation
                    navs={settingChoices}
                    orientation='h'
                    isToolbar={true}
                />
            </>
        );
    }

    getAction() {
        return this.getOtherProps()?.listViewAction;
    }

    getPrinterComp() {
        return null;
        // return MultiEntityView;
    }

    setListViewType = (viewType: any) => {
        if (this.listViewType !== viewType) {
            this.listViewType = viewType;
            this.setState({});
        }
    }

    private getViewChoices(): any[] {
        if (this.getOtherProps()?.listViewType) {
            return [];
        }
        return Object.values(EntityConstants.ListViewTypes).map(vt => {
            return {
                id: vt.id,
                label: vt.label,
                icon: Icons[vt.id],
                isSelected: (vt.id === this.listViewType),
                action: (opt: any) => {
                    this.setListViewType(opt.id);
                }
            };
        });
    }

    buildPropSelector() {
        if (this.seletingProps) {
            const entityProp: EntityProp = {
                id: 'selectedProps',
                label: 'Properties',
                dataType: EntityConstants.PropType.STRING,
                category: this.getCategoryID(),
                extras: { multiSelect: true, },
            };

            return (
                <UC.Dialog
                    onClose={() => {
                        this.seletingProps = false;
                        this.setState({});
                    }}
                    title={entityProp.label} >
                    <UC.PropertySelector
                        entityProp={entityProp}
                        defaultValue={this.selProps}
                        otherProps={
                            {
                                onPropChanged: (eProp: EntityProp, selProps: any) => {
                                    // console.log(`Selected props: `, selProps);
                                    this.selProps = selProps;
                                    // this.setState({});
                                },
                            }
                        }
                    />
                </UC.Dialog>
            );
        } else {
            return <UC.Empty />;
        }
    }

}
