import ASYNC from 'async';
import React from 'react';
import { EntityConstants } from '../../@uno/api';
import { Common, DesignerConstants, EM, UC, UnoComponent } from '../../@uno/core';
import { AppMenuService } from '../service/app.menu.service';
import { SessionManager } from '../service/session.service';
import { UnoAppBaseComp } from './uno-app.base.comp';

type MenuGroup = { index: any, label?: string, navs: Array<any>, noLabel?: boolean }

@UnoComponent({
    id: 'PrimaryMenu',
    label: 'Primary Menu',
    props: [
        { groupID: 'Settings', id: 'orientation', label: 'Orientation' },
        { groupID: 'Settings', id: 'noHome', label: 'No Home Menu', dataType: EntityConstants.PropType.BOOLEAN },
        { groupID: 'Settings', id: 'homeLabel', label: 'Label for Home', },

        { groupID: 'Style', id: 'styleClasses', label: 'Style Classes', dataType: EntityConstants.PropType.JSON, },
    ],
    /*
    getPreview: (config?: any, clbk?: any, buildChildren?: Function) => {
        return (<div>The Primary Menu - PREVIEW</div>);
    },
    getDesign: (config?: any, clbk?: any, buildChildren?: Function) => {
        return (<div>The Primary Menu - DESIGN</div>);
    },
    */
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Layout.id,
})
export class PrimaryMenu extends UnoAppBaseComp {
    private menuList: any = {};

    private _handler_SignedIn: any;
    private _handler_SignedOut: any;

    componentDidMount() {
        super.componentDidMount();
        // load via prop
        // console.log('Primary Menu Props: ', this.props);
        this._handler_SignedIn = EM.register(Common.Event.USER_SESSION_CREATED, (data: any) => {
            // console.log(`User signed in. Refresh Primary Menu`);
            this.refreshNavs();
        });

        this._handler_SignedOut = EM.register(Common.Event.USER_SESSION_CLEARED, (data: any) => {
            // console.log(`User signed OUT. Refresh Primary Menu`);
            this.refreshNavs();
        });

        this.refreshNavs();
    }

    componentWillUnmount() {
        EM.unregister(this._handler_SignedIn);
        EM.unregister(this._handler_SignedOut);

        super.componentWillUnmount();
    }

    buildComp() {
        const orientation: string = this.props.orientation;
        const isHorizontal = () => {
            return (orientation === 'h');
        }
        // console.log(`Building ${this.navsList.length} primary navs`);
        const navViews = Object.keys(this.menuList).sort().map(id => {
            const navMenu = this.menuList[id];
            let navs = navMenu.navs;
            if (!navs || navs.length === 0) {
                return null;
            }

            if (navs?.length > 0) {
                return (
                    <UC.Menu
                        key={Common.getUniqueKey()}
                        navs={navs}
                        orientation={orientation}
                        visibleChildCount={10}
                        styleClasses={this.state.styleClasses}
                    />
                );
            } else {
                return (<UC.Empty />);
            }
        });

        return (
            <>
                <div key={Common.getUniqueKey()} className='primary-menu'>
                    {(navViews && navViews.length > 0) ? navViews : undefined}
                </div>
            </>
        );
    }

    refreshNavs = (appID: string = this.getAppID()) => {
        const session = SessionManager.activeSession;
        // console.log('Refresh menu for app - ', appID, session?.id);
        if (appID === undefined) {
            return;
        }

        this.menuList = {};
        ASYNC.parallel(
            [
                async (clbk: Function) => {
                    const myChoiceNavs = await AppMenuService.getMenuPrimary(appID, session, this.state.noHome, this.state.homeLabel);
                    if (myChoiceNavs?.length > 0) {
                        this.addToList({ index: 1, label: 'My Choices', noLabel: true, navs: myChoiceNavs });
                    }
                },
                async (clbk: Function) => {
                    const myDataNavs = await AppMenuService.getMenuNonCoreCat(appID, session);
                    // console.log('My Data Navs: ', myDataNavs)
                    if (myDataNavs?.length > 0) {
                        this.addToList({ index: 2, label: 'My Data', noLabel: true, navs: myDataNavs });
                    }
                },
                async (clbk: Function) => {
                    if (SessionManager.activeSession) {
                        const adminNavs = await AppMenuService.getMenuCoreCats(appID, session)
                        if (adminNavs.length > 0) {
                            this.addToList({ index: 3, label: 'Administration', noLabel: true, navs: adminNavs });
                        }
                    }
                },
            ],
            () => {
                // console.log('Primary menu refreshed');
                // this.setState({});
            }
        );

    }

    addToList = (mg: MenuGroup) => {
        const id = mg.index;
        this.menuList[id] = mg;
        this.reRender();
    }

}
