import 'aframe';
import 'aframe-particle-system-component';
import { Entity, Scene } from 'aframe-react';
import React from 'react';
import { Common } from '../../../@uno/api';
import { DesignerConstants, UnoComponent, UnoCoreBaseComp } from '../../../@uno/core';

declare var Calendly: any;

const CalendlyType = {
    Inline: { id: 'inline', label: 'Embed Inline' },
    Widget: { id: 'widget', label: 'Popup Widget' },
    Text: { id: 'text', label: 'Popup Text' },
}

@UnoComponent(
    {
        id: 'Calendly',
        label: 'Calendly',
        paletteable: true,
        props: [
            {
                groupID: 'General',
                id: 'url',
                label: 'URL',
            },
            {
                groupID: 'General',
                id: 'label',
                label: 'Label'
            },
            {
                groupID: 'General',
                id: 'type', label: 'Type', extras: {
                    options: Object.values(CalendlyType),
                },
                viewer: 'OptionViewer',
                editor: 'OptionSelector'
            },
        ],
        group: DesignerConstants.PaletteGroup.Integration.id,

        getPreview: (config?: any, clbk?: any, buildChildren?: Function) => {
            return (<div>The Calendly- PREVIEW</div>);
        },
        getDesign: (config?: any, clbk?: any, buildChildren?: Function) => {
            return (<div>The Calendly - DESIGN</div>);
        },
    }
)
export class CalendlyComp extends UnoCoreBaseComp {

    componentDidMount(): void {
        super.componentDidMount();

        Common.addScript(
            'https://assets.calendly.com/assets/external/widget.js',
            'text/javascript',
            false,
        );

        switch (this.getType()) {
            case CalendlyType.Text.id:
                Common.AppSettings.setLink('stylesheet', 'https://assets.calendly.com/assets/external/widget.css');
                break;
            case CalendlyType.Widget.id:
                Common.AppSettings.setLink('stylesheet', 'https://assets.calendly.com/assets/external/widget.css');
                break;
            case CalendlyType.Inline.id:
            default:
        }

    }

    buildComp(): JSX.Element {
        const type = this.getType();

        const initBadgeWidget = () => {
            // console.log('Init Calendly Popup Widget: ', type);
            if (type === CalendlyType.Widget.id) {
                const fn = async () => {
                    await Common.pause(10);

                    Calendly?.initBadgeWidget({
                        url: this.getURL(),
                        text: this.getLabel(),
                        color: '#0069ff',
                        textColor: '#ffffff',
                        branding: false,
                    });
                }
                fn();
            }
            return undefined;
        }

        let Cal = undefined;
        switch (type) {
            case CalendlyType.Text.id:
                Cal = (
                    <a
                        href="#"
                        onClick={
                            () => {
                                if (Calendly !== undefined) {
                                    Calendly.initPopupWidget({ url: this.getURL(), branding: false });
                                }
                                return false;
                            }
                        }>
                        {this.getLabel()}
                    </a>
                );
                break;
            case CalendlyType.Widget.id:
                Cal = initBadgeWidget();
                break;
            case CalendlyType.Inline.id:
            default:
                Cal = (
                    <div
                        className='calendly-inline-widget'
                        data-url={this.getURL()}
                        style={{ minWidth: '320px', height: '700px', ...this.getStyles() }}
                    >
                    </div >
                );
        }

        return (
            <div className={this.getStyleClasses()} >
                {Cal}
            </div>
        );
    }

    getURL() {
        return this.state.url || 'https://calendly.com/rpiitkgp';
    }

    getLabel() {
        return this.state.label || 'Schedule time with me';
    }

    getType() {
        return this.state.type || 'inline';
    }

}