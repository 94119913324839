import React from 'react';
import { BaseEntity, Common, EntityCategory, EntityConstants, EntityProp, Router } from '../../../@uno/api';
import { UC, UnoComponent, Images, DesignerConstants } from '../../../@uno/core';
import { ENTITY_LIST_COMP_PROPS, EntityListView } from './entity-list.view.comp';
import { EntityCategoryService } from '../../../@uno-app/service/entity.category.service';
import { ascending, pointer } from 'd3';

const GridConstants = {
    SortOrder: {
        ASC: { indicator: '&#9650;', value: 1 },
        DESC: { indicator: '&#9660;', value: -1 },
        NONE: { indicator: '&#9664;&#9654;', value: 0 },
        getDefault: () => {
            return GridConstants.SortOrder.NONE;
        }
    },

}

type ColumnConfig = { prop: EntityProp, size: string, sortOrder?: number }
const ExtraColProps = { CheckBox: { id: '_checkbox' }, Actions: { id: '_actions' } };

// MULTI ENTITY - TABLE VIEW 
@UnoComponent({
    id: EntityConstants.ListViewTypes.Table.id,
    label: EntityConstants.ListViewTypes.Table.label,
    props: ENTITY_LIST_COMP_PROPS,
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Entity.id,
})
export class EntityTableView extends EntityListView {
    protected sortByProp: EntityProp | undefined;
    protected sortAscending: boolean = true;

    private columns: Array<ColumnConfig> = [];
    private minColWidth = 100;
    private smallScreen = { width: 600, columns: 1 };

    private headerBeingResized: any;
    private columnBeingResized: any;

    private gridKey = Common.getUniqueKey('data-grid');

    canProfile(): boolean {
        return false;
    }

    async doLoadMore() {
        this.initColumns();
    }

    componentDidMount(): void {
        super.componentDidMount();
        // window.addEventListener('resize', this.reRender);
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        // window.removeEventListener('resize', this.reRender);
    }

    buildContent(): any {
        const entities = this.getEntities();
        if (!entities || entities.length === 0) {
            return undefined;
        }
        // this.initColumns();
        const maxWidth = document.documentElement.clientWidth * (Common.isSmallScreen() ? 0.95 : 0.95); // window.screen.availWidth, document.documentElement.clientWidth
        const maxHeight = document.documentElement.clientHeight * (Common.isSmallScreen() ? 0.9 : 0.7); // window.screen.availHeight, document.documentElement.clientHeight

        return (
            <div
                className='uno-data-grid'
                style={{
                    // maxHeight: `${maxHeight}px`,
                    maxWidth: `${maxWidth}px`,
                    overflow: 'auto',
                    ...this.state.styles,
                }}
            >
                <table id={this.gridKey} style={{ gridTemplateColumns: this.getGridTemplateColumns() }}>
                    <thead>
                        {this.buildTableColumns()}
                    </thead>
                    <tbody>
                        {this.buildEntities()}
                    </tbody>
                </table>
            </div>
        )
    }

    getGridTemplateColumns() {
        const gridTemplateColumns: Array<string> = [];
        /*
        if (this.showCheckBoxColumn()) {
            gridTemplateColumns.push('30px'); // checkbox
        }

        if (this.hideActionsColumn() === false) {
            gridTemplateColumns.push(`minmax(${this.minColWidth}px, auto)`);// actions // `minmax(${this.minColWidth}px, auto)`
        }
        */
        const smallScreen = Common.isSmallScreen();

        this.columns.forEach(
            (c, index) => {
                gridTemplateColumns.push(c.size); // for each column
                //gridTemplateColumns.push((smallScreen && index > (this.smallScreen.columns - 1)) ? '0px' : c.size); // for each column
            }
        );

        const gridTemplateColumnsVal = gridTemplateColumns.join(' ');
        return gridTemplateColumnsVal;
    }

    buildTableColumns() {

        const buildResizeHandle = (column?: any) => {
            return (
                <span
                    className='resize-handle'
                    onMouseDown={
                        (e: any) => {
                            this.initResize(e, column);
                        }
                    }
                >
                    |
                </span>
            );
        }

        const columnsViews: Array<any> = this.columns?.map(
            (column: ColumnConfig) => {
                let columnHeader: any = <UC.Empty />;
                const prop = column.prop;
                if (prop.id === ExtraColProps.CheckBox.id) {
                    columnHeader = (<> <input type='checkbox' onClick={evt => { this.doSelectAll(evt) }} /> </>);
                } else if (prop.id === ExtraColProps.Actions.id) {
                    columnHeader = (<> <span title='Actions'>...</span>  </>);
                } else {
                    columnHeader = (
                        <>
                            <span
                                title={prop.description ? prop.description : prop.label}
                                style={{ paddingLeft: '10px', }}
                            >
                                {prop.label}
                                <span style={{ fontSize: 'smaller' }}>&nbsp;{prop.description}</span>
                            </span>
                        </>
                    );
                }
                return (
                    <th key={this.getUniqueKey()}>
                        <UC.VSection
                            cols='90%, 10%'
                            rigidCols={true}
                            styles={{ width: '100%' }}
                        >
                            <UC.Section>
                                {this.buildSortBy(column)}
                                {columnHeader}
                            </UC.Section>
                            <UC.Section
                                styleClasses='right'
                            >
                                {buildResizeHandle(column)}
                            </UC.Section>
                        </UC.VSection>
                    </th>
                );
            }
        );

        return (
            <tr key={this.getUniqueKey()}>
                {columnsViews}
            </tr>
        );
    }

    buildSortBy(column: ColumnConfig) {
        const prop = column.prop;
        switch (prop.dataType) {
            case EntityConstants.PropType.DEFAULT:
            case EntityConstants.PropType.BOOLEAN:
            case EntityConstants.PropType.STRING:
            case EntityConstants.PropType.MULTILINE:
            case EntityConstants.PropType.HTML:
            case EntityConstants.PropType.NUMBER:
            case EntityConstants.PropType.DATE:
            case EntityConstants.PropType.TIME:
            case EntityConstants.PropType.DATETIME:
            case EntityConstants.PropType.EMAIL:
            case EntityConstants.PropType.ENTITY:
            case EntityConstants.PropType.ENTITY_INLINE:
                return <SortOrderComp column={column} onChange={this.setSortBy} />
            default:
                return this.buildEmptyElement();
        }

        //return <SortOrder column={column} onChange={this.setSortBy} />
    }

    buildEntities() {
        return this.getEntities().map(
            (entity: BaseEntity, index: number) => {
                return this.buildEntityRow(entity, index);
            }
        );
    }

    buildEntityRow(entity: BaseEntity, index: number) {

        const cells = this.columns?.map(
            c => {
                let cell: any = <UC.Empty />;
                const prop = c.prop;

                if (prop.id === ExtraColProps.CheckBox.id) {
                    cell = (<> <input type='checkbox' onClick={evt => { this.doSelectAll(evt); }} /> </>);
                } else if (prop.id === ExtraColProps.Actions.id) {
                    cell = (<> {this.buildEntityActions(entity, index)} </>);
                } else {
                    cell = (<> {this.buildCell(prop, entity)} </>);
                }

                return (
                    <td
                        key={this.getUniqueKey()}
                        style={{
                            overflow: (prop.id === ExtraColProps.Actions.id) ? 'visible' : 'hidden'
                        }}
                    >
                        <UC.Section styleClasses={this.getAlignment(prop)} >{cell} </UC.Section>
                    </td>
                );
            }
        );

        return (
            <tr key={this.getUniqueKey()}
                onClick={() => {
                    const onSelected = this.getOtherProps()?.onSelected;
                    if (onSelected) {
                        onSelected(entity, index);
                    }
                }}
            >
                {cells}
            </tr>
        );
    }

    isToolbarEntityActions(entity?: any, index?: number) {
        let isToolBar = super.isToolbarEntityActions(entity, index)
        if (isToolBar === undefined) {
            isToolBar = true;
        }
        return isToolBar;
    }

    private getAlignment(prop: EntityProp) {
        let alignment = 'left';
        switch (prop.dataType) {
            case EntityConstants.PropType.NUMBER:
                alignment = 'right';
                break;
            case EntityConstants.PropType.BOOLEAN:
                alignment = 'center';
                break;
            default:
                alignment = 'left';
        }
        return alignment;
    }

    getEntityActions(entity: BaseEntity, index: number) {
        // console.log('Table Action: ', this.getAction());
        const actions: Array<any> = super.getEntityActions(entity, index) || [];

        const oProps = this.getOtherProps();
        const canPreview = oProps?.canPreview;
        const noP = oProps?.noPreview;
        const doPreview = (canPreview !== undefined) ? canPreview : (noP !== undefined ? !noP : false);
        const viewInline = oProps?.viewInline;

        if (doPreview && (this.canViewFull() || viewInline)) {
            const preview = {
                id: 'Preview',
                action: (nav: any, evt: any) => { this.doPreview(entity, evt); },
                icon: Images.Icon.preview,
            };
            actions.unshift(preview);
        }

        // this.profiler.log(index, actions, entity);
        const actionLimit = this.getCategoryConfigs()?.actions?.limit;
        // this.profiler.log('Action Count Limit: ', actionLimit, this.getAction());
        if (actions.length > ((actionLimit !== undefined) ? Number.parseInt(actionLimit) : 10)) {
            return [{ id: '...', label: 'Choices', children: actions, icon: Images.Icon.v3dots }]
        } else {
            return actions;
        }

    }

    buildCell(prop: EntityProp, entity: BaseEntity) {
        let value = this.getPropValue(prop, entity);
        // this.profiler.log(`Category:${this.getCategory().id}, Prop: ${p.id}, Value: ${value}`);

        const otherProps = this.buildOtherPropsForEProp(true, prop);
        // There is no need to show entity actions in a prop of type entity.
        otherProps.entityActions = [];

        return (
            <UC.PropComp
                key={this.getUniqueKey(prop)}
                entityProp={prop}
                categoryID={this.getCategoryID()}
                defaultValue={value}
                entity={entity}
                otherProps={otherProps}
                appID={this.getAppID()}
            />
        );
    }

    doPreview = async (entity: BaseEntity, evt?: any) => {
        if (evt?.stopPropagation) {
            evt.stopPropagation();
        }

        if (entity) {
            entity = EntityConstants.build(entity);
            const category = await EntityCategoryService.getCategory(entity.getCategoryID(), true, entity.getAppID());
            if (!category) {
                this.profiler.log('Unable to load Category for preview: ', entity);
            }

            const oProps: any = {

            };

            const viewInline = this.getOtherProps()?.viewInline;
            if (viewInline) {
                oProps.noActions = true;
            }

            const dlgConfig = {
                id: `entity-inline-view-${entity.getID()}-${entity.getName()}`,
                comp: (
                    <>
                        <UC.EntityView
                            category={category}
                            entities={undefined}
                            entity={entity}
                            appID={entity.getAppID()}
                            key={Common.getUniqueKey()}
                            otherProps={oProps}
                            styles={{ border: '1px solid', padding: '5px' }}
                        />
                    </>
                ),
                onClose: () => {
                    Common.hideDialog(undefined, dlgConfig.id);
                },
                title: `Preview - ${entity.getName()}`
            };

            Common.showDialog(dlgConfig);
        }
    }

    // Utilities:

    doSelectAll = (evt: any) => {
        evt.stopPropagation();
    }

    // initialize the columns array
    initColumns = (props: Array<EntityProp> = this.getViewProps()) => {
        this.profiler.log(`Initializing Table Columns: `, this.getCategoryID(), [...props]);
        this.columns = [];
        if (this.showCheckBoxColumn()) {
            this.columns.push({ prop: ExtraColProps.CheckBox, size: '30px' });
        }

        const sortCfg = this.getOtherProps()?.sortConfig;
        this.profiler.log('Table Sort Config: ', sortCfg, this.getOtherProps());
        props?.forEach(
            (prop: any) => {

                let sortOrder = GridConstants.SortOrder.getDefault().value;
                if (sortCfg?.prop === prop.id) {
                    sortOrder = sortCfg.order;
                }

                // const max = this.getColumnTypeToRatio(prop) + 'fr';
                this.columns.push(
                    {
                        prop,
                        // The initial size value for grid-template-columns:
                        size: `minmax(${this.minColWidth}px, auto)`,
                        sortOrder: sortOrder,
                    }
                );
            }
        );

        // Add Actions Column
        // 0 = Hidden, -1 = At the end, (+ number) = actual index (with checkbox column visible)
        let actionColumnIndex = this.getActionsColumnIndex();
        const hideActionsColumn = (this.hideActionsColumn() || actionColumnIndex === 0);

        if (!hideActionsColumn) {
            //  If checkbox column is NOT present, Action column index is one less than specified
            actionColumnIndex = actionColumnIndex - (this.showCheckBoxColumn() ? 0 : 1);
            // this.profiler.log('Adding Action Column at index: ', actionColumnIndex);

            const actionsColumn = { prop: ExtraColProps.Actions, size: `minmax(${this.minColWidth}px, auto)` };
            if (actionColumnIndex < 0
                || actionColumnIndex >= this.columns.length
            ) {
                this.columns.push(actionsColumn);
            } else {
                this.columns.splice(actionColumnIndex, 0, actionsColumn);
            }
        }


        this.profiler.log(`Table Column initialized: `, this.columns?.length, this.getCategoryID());

        this.reRender();
    }

    setSortBy = (column: ColumnConfig, newOrder?: number) => {
        if (newOrder !== undefined) {
            column.sortOrder = newOrder;
        }
        // this.profiler.log(`Sort By - ${column.prop.id} in ${column.sortOrder}`);
        this.sortByProp = column.prop;
        this.sortAscending = (column.sortOrder === GridConstants.SortOrder.ASC.value)
        const onSort = this.getOtherProps().onSort;
        if (onSort) {
            // this.profiler.log('On Sort Handler: ', onSort);
            onSort({
                prop: this.sortByProp,
                order: column.sortOrder,
                column: column,
                category: this.getCategory(),
                entities: this.getEntities(),
            });
        }
        this.reRender();
    }

    getEntities() {
        let entities = super.getEntities();
        const sortBy: any = this.sortByProp;
        if (sortBy) {
            // this.profiler.log(`Sort By : `, sortBy);
            const propDataType = sortBy.dataType;
            entities = entities.sort(
                (first: any, second: any) => {
                    let compared: number = 0;
                    let propValFirst: any = EntityConstants.getValue(sortBy, EntityConstants.build(first));
                    let propValSecond: any = EntityConstants.getValue(sortBy, EntityConstants.build(second));;
                    if (propDataType === EntityConstants.PropType.ENTITY
                        || propDataType === EntityConstants.PropType.ENTITY_INLINE) {
                        propValFirst = EntityConstants.build(propValFirst).toString();
                        propValSecond = EntityConstants.build(propValSecond).toString();
                    }
                    if (propValFirst < propValSecond) {
                        compared = (this.sortAscending) ? -1 : 1;
                    } else if (propValFirst === propValSecond) {
                        compared = 0;
                    } else {
                        compared = (this.sortAscending) ? 1 : -1;
                    }

                    return compared;
                }
            );
        }
        return entities;
    }

    getViewProps(quickView = this.isQuickView(), category: EntityCategory = this.getCategory(),) {
        return super.getViewProps(quickView, category);
    }

    // The max (fr) values for grid-template-columns
    getColumnTypeToRatio = (prop: EntityProp) => {
        let ratio: number = 1;
        switch (prop?.dataType) {
            case EntityConstants.PropType.DEFAULT:
            default:
                ratio = 1;
                break;

        }
        return ratio;
    }

    isQuickView() {
        const visibleProps = this.getOtherProps()?.visibleProps;
        const quick = (visibleProps?.length > 0) ? false : true;
        // this.profiler.log('Visible Props: ', quick, visibleProps,);

        return quick;
    }

    getColumnByPropID = (propID: string) => {
        return this.columns.find(({ prop }) => { return prop.id === propID; });
    }

    // Resize column headers
    onMouseMove = (e: any) => requestAnimationFrame(() => {
        // this.profiler.log('onMouseMove');

        const table: any = document.getElementById(this.gridKey);
        // Calculate the desired width
        const horizontalScrollOffset = table.parentElement.scrollLeft; // the scrollable container of table.
        const width = (horizontalScrollOffset + e.clientX) - this.headerBeingResized.offsetLeft;

        // Update the column object with the new size value
        const column: any = this.getColumnByPropID(this.columnBeingResized?.prop.id);
        if (column) {
            // this.profiler.log(`resizing header of column: `, column.prop.id, width, column.size);
            column.size = Math.max(this.minColWidth, width) + 'px'; // Enforce our minimum
        }
        /* 
          Update the column sizes
        */
        table.style.gridTemplateColumns = this.getGridTemplateColumns();
    });

    // Clean up event listeners, classes, etc.
    onMouseUp = () => {
        // this.profiler.log('onMouseUp');

        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('mouseup', this.onMouseUp);
        this.headerBeingResized.classList.remove('header--being-resized');
        this.headerBeingResized = null;
        this.columnBeingResized = null;
    };

    // Get ready, they're about to resize
    initResize = (e: any, column: any) => {
        // this.profiler.log('initResize');

        this.headerBeingResized = e.target.parentNode;
        this.columnBeingResized = column;
        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('mouseup', this.onMouseUp);
        this.headerBeingResized.classList.add('header--being-resized');
    };


    showCheckBoxColumn = () => {
        return (this.getOtherProps()?.showCheckBox === true);
    }

    hideActionsColumn = () => {
        return (this.getOtherProps()?.noActions === true);
    }

    getActionsColumnIndex = () => {
        let index = this.getOtherProps()?.actionsIndex;
        if (!index) {
            index = this.getCategoryConfigs('eTable')?.actionsIndex;
        }
        if (!index) {
            return -1;
        } else {
            return Number.parseInt(index);
        }
    }

}

class SortOrderComp extends React.Component<{ column: ColumnConfig, onChange: Function }, any> {

    constructor(props: any) {
        super(props);
        this.state = { order: this.props.column.sortOrder }
    }

    render(): React.ReactNode {
        const indicator = this.getIndicator();

        const filterIcon = { __html: indicator };
        let toggleLink = (
            <span
                dangerouslySetInnerHTML={filterIcon}
                style={{ /*opacity: 0.5*,*/ cursor: 'pointer' }}
                onClick={() => { this.toggleOrder() }}
            >
            </span>
        );

        return toggleLink;
    }

    toggleOrder = (order: number = this.state.order) => {
        const Orders = GridConstants.SortOrder;
        switch (order) {
            case Orders.NONE.value:
                return this.setOrder(Orders.ASC.value);
            case Orders.ASC.value:
                return this.setOrder(Orders.DESC.value);
            case Orders.DESC.value:
                return this.setOrder(Orders.NONE.value);
        }
    }

    setOrder = (newOrder: number) => {
        this.props.onChange(this.props.column, newOrder);
        this.setState({ order: newOrder });
    }

    getIndicator = (order = this.state.order) => {
        const Orders = GridConstants.SortOrder;
        switch (order) {
            case Orders.NONE.value:
                return Orders.NONE.indicator;
            case Orders.DESC.value:
                return Orders.DESC.indicator;
            case Orders.ASC.value:
                return Orders.ASC.indicator;
            default:
                return Orders.getDefault().indicator;
        }
    }


}
