import React from 'react';
import { DesignerConstants, UnoComponent } from '../../core';
import { UnoCoreBaseComp } from './uno-core.base.comp';
import { EntityConstants } from '../../api';

@UnoComponent({
    id: 'FieldSet',
    label: 'Field Set',
    group: DesignerConstants.PaletteGroup.Frequent.id,
    props: [
        { groupID: 'General', id: 'legend', label: 'Legend', },
        { groupID: 'General', id: 'disabled', label: 'Is Disabled', dataType: EntityConstants.PropType.BOOLEAN },
        // { groupID: 'General', id: 'name', label: 'Name', },
        { groupID: 'Style', id: 'legendStyleClasses', label: 'Legend Style Classes', },
        { groupID: 'Style', id: 'legendStyles', label: 'Legend Style', dataType: EntityConstants.PropType.JSON },
    ],
    paletteable: true,
    isContainer: true,
})
export class FieldSet extends UnoCoreBaseComp {

    buildComp() {
        const children = this.state.children;
        const styles = {
            border: 'none',
            width: '100%',
            ...this.getStyles()
        }

        return (
            <fieldset
                style={styles}
                className={`${this.getStyleClasses()}`}
                disabled={this.state.disabled === true}
            >
                {this.buildLegend()}
                {children}
            </fieldset>
        )
    }

    buildLegend = () => {
        const legendText = (this.state.legend || '').trim();
        if (legendText?.length > 0) {
            return (
                <legend>{legendText}</legend>
            );
        } else {
            return undefined;
        }
    }
}