import React from 'react';
import { Common, Images, Router } from '../../../@uno/api';
import { BaseEntity, EntityConstants, EntityProp } from '../../../@uno/api/entity.service';
import { DesignerConstants, UC, UnoComponent } from '../../../@uno/core';
import { EntityViewTypes } from '../../service/entity-view.service';
import { EntityBaseComp } from '../entity-base.comp';
import { AppScreenService } from '../../../@uno-app/service/app.screen.service';

export const ENTITY_LIST_COMP_PROPS: Array<EntityProp> = [
    {
        groupID: 'Entity',
        id: 'categoryID',
        label: 'Category ID',
        editor: 'CategorySelector'
    },
    {
        groupID: 'Entity',
        id: 'the_category',
        label: 'Category',
        description: 'Custom Category Definition',
        dataType: EntityConstants.PropType.JSON,
    },
    {
        groupID: 'Entity',
        id: 'entities',
        label: 'The Entities',
        description: 'An array of entity objects',
        dataType: EntityConstants.PropType.JSON,
    },
    {
        groupID: 'Others',
        id: 'otherProps',
        label: 'Other Properties',
        dataType: EntityConstants.PropType.JSON,
    },
];

// ENTITY - LIST VIEW 
@UnoComponent({
    id: EntityConstants.ListViewTypes.Grid.id,
    label: EntityConstants.ListViewTypes.Grid.label,
    props: ENTITY_LIST_COMP_PROPS,
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Entity.id,
})
export class EntityListView extends EntityBaseComp {
    //protected entityLayout: any;
    protected recordsToList: number = -1;
    protected recordsToSkip: number = 0;

    canProfile(): boolean {
        return false;
    }

    async loadLayout() {
        let entityScreen = this.getEntityScreen();
        let layout = this.getEntityLayout();
        if (!layout && entityScreen) {
            entityScreen = EntityConstants.build(entityScreen);
            AppScreenService
                .getScreenByID(entityScreen.getID(), entityScreen.getAppID())
                .then(
                    layout => {
                        if (layout) {
                            this.reRender({ entityLayout: layout });
                        }
                    }
                );
        }
    }


    async loadCategory() {
        if (!this.getCategory()) {
            await super.loadCategory();
        }
    }

    buildContent() {
        if (!this.getEntityLayout()) {
            this.loadEntityLayout((this.getOtherProps()?.viewType === EntityViewTypes.QUICK ? Router.CatAction.QUICK_VIEW : Router.CatAction.VIEW));
        }

        const eViews = this.buildEntities();
        let colCnt = this.getOtherProps()?.cols || this.getExtras()?.cols || this.getCategory()?.extras?.cols || 1;
        const view = (
            <UC.VSection cols={`${colCnt}`} >
                {eViews}
            </UC.VSection>
        )
        return view;

    }

    getPrinterComp() {
        return null;
        // return EntityListView;
    }

    /*
    componentWillUnmount() {
        // EM.unregister(this.createCompletedListenerID);
    }
    */

    isListOptionSelected(id: number) {
        return (this.recordsToList === id);
    }

    isSkipOptionSelected(id: number) {
        return (this.recordsToSkip === id);
    }

    buildPaginationOptions() {
        const listOptions: Array<any> = [
            { id: -1, label: 'All', isSelected: this.isListOptionSelected(-1) },
            { id: 1, label: '1- One', isSelected: this.isListOptionSelected(1) },
            { id: 2, label: '2- Two', isSelected: this.isListOptionSelected(2) },
            { id: 3, label: '3 - Three', isSelected: this.isListOptionSelected(3) },
            { id: 4, label: '4 - Four', isSelected: this.isListOptionSelected(4) },
        ];
        const skipOptions: Array<any> = [
            { id: 0, label: '0 - Zero', isSelected: this.isSkipOptionSelected(0) },
            { id: 1, label: '1- One', isSelected: this.isSkipOptionSelected(1) },
            { id: 2, label: '2- Two', isSelected: this.isSkipOptionSelected(2) },
            { id: 3, label: '3 - Three', isSelected: this.isSkipOptionSelected(3) },
            { id: 4, label: '4 - Four', isSelected: this.isSkipOptionSelected(4) },
        ];
        return (
            <div>
                <div style={{ float: 'right', }}>
                    <span><b>Listing Options:: </b></span>
                    Skip: <UC.SelectBox options={skipOptions} onSelect={this.skipRecords} />
                    Show: <UC.SelectBox options={listOptions} onSelect={this.showRecords} />
                </div>
                <p style={{ clear: 'both' }}> </p>
            </div>
        )
    }

    buildEntities(): Array<any> {
        // this.profiler.log('re-render list - ', this.getCategoryID(), this.getEntities()?.length, { ...this.state });
        const ViewComp = this.getEntityViewComp();
        const oProps = this.getOtherProps();

        // a bit of optimization
        let eCategory = this.getCategory();
        if (eCategory) {
            eCategory = { ...eCategory };
        }

        const viewEntities = this.getEntities().map(
            (entity: any, index: number) => {
                const otherProps = { ...oProps, canViewFull: false, };

                if (this.getOtherProps().onSelected) {
                    otherProps.onSelected = () => {
                        this.profiler.log('List Item selected - ', index, entity)
                        this.getOtherProps().onSelected(entity, index);
                    }
                }

                const key = this.getUniqueKey();
                // this.profiler.log('Rendering list item - ', entity);
                return (
                    <div
                        key={key}
                    >
                        <ViewComp
                            entity={entity}
                            categoryID={this.getCategoryID()}
                            category={eCategory}
                            otherProps={otherProps}
                            appID={this.getAppID()}
                            // entityScreen={this.getEntityScreen()}
                            layout={this.getEntityLayout()}
                        // styles={{ borderWidth: 'var(--uno-size-2)', borderStyle: 'solid' }}
                        />
                        <UC.VSection styleClasses='right'>
                            {this.buildEntityActions(entity, index)}
                        </UC.VSection>
                    </div>
                );
            }
        );
        return viewEntities;
    }

    buildEntityActions = (entity: any, index: number) => {
        const actions = this.getEntityActions(entity, index);
        const isToolbar = this.isToolbarEntityActions(entity, index);

        return (
            <UC.AccessControlledActions
                actions={actions}
                horizontal={true}
                isToolbar={isToolbar}
            />
        );
    }

    getOtherProps(): any {
        const superOtherProps = super.getOtherProps();
        const oProps = {
            ...superOtherProps,
            styles: {
                // borderWidth: 'var(--uno-size-2)',
                //  borderStyle: 'solid',
                ...superOtherProps?.styles
            },
            viewType: superOtherProps?.viewType || EntityViewTypes.QUICK,
        };
        return oProps;
    }

    getEntityViewComp = () => {
        const viewComp = this.getExtras()?.viewComp || this.getCategory()?.extras?.viewComp;
        // this.profiler.log('Entity View Comp: ', this.getExtras());
        if (viewComp) {
            return UC[viewComp];
        } else {
            return UC.EntityView;
        }
    }

    isToolbarEntityActions(entity?: any, index?: number) {
        let isToolbar = this.getCategoryConfigs()?.actions?.isToolbar;
        if (isToolbar === undefined) {
            isToolbar = this.getOtherProps()?.isToolbar;
        }
        return isToolbar;
    }

    getEntityScreen() {
        const eScreen: any = this.props?.eScreen || this.getOtherProps()?.entityScreen;
        return eScreen;
    }

    getEntityLayout() {
        return this.state.entityLayout || this.getOtherProps()?.entityLayout;
    }

    getAction(): string {
        return Router.CatAction.LIST;
    }

    showRecords = (opt: any) => {
        if (opt) {
            this.recordsToList = parseInt(opt.id);
            // this.profiler.log(`Show - ${this.recordsToList} records at a time.`);
            this.setState({});
        }
    }

    skipRecords = (opt: any) => {
        if (opt) {
            this.recordsToSkip = parseInt(opt.id);
            // this.profiler.log(`Skip - ${this.recordsToList} records.`);
            this.setState({});
        }
    }

    getEntities() {
        let entitiesList = Common.safeParse(this.state.entities || []);
        // this.profiler.log('No of records to list = ', entitiesList?.length);
        if (this.recordsToList !== -1) {
            return entitiesList.slice(this.recordsToSkip, (this.recordsToList + this.recordsToSkip));
        }
        return entitiesList.map((e: any) => {
            return EntityConstants.build(e);
        });
    }

    isQuickView() {
        if (this.getOtherProps()?.viewType === EntityViewTypes.QUICK) {
            return true;
        }
        return false;
    }

    getEntityActions(entity: BaseEntity, index: number) {

        const actions: Array<any> = [];
        if (this.getOtherProps()?.noActions === true) {
            return actions;
        }

        if (this.canViewFull()) {
            const toView = Router.getViewRoute(entity);
            actions.push({ id: Router.CatAction.VIEW, label: 'View', to: toView, icon: Images.Icon.view });
        }

        if (this.canEdit()) {
            const toEdit = Router.getEditRoute(entity);
            actions.push({ id: Router.CatAction.EDIT, label: 'Edit', to: toEdit, icon: Images.Icon.edit });
        }

        const entityActions = this.getOtherProps().entityActions;
        if (entityActions?.length > 0) {
            entityActions.forEach(
                (ea: any) => {
                    const eaCopy = { ...Common.safeParse(ea) };
                    eaCopy.data = { entity: entity, index: index };
                    const action = eaCopy.action;
                    eaCopy.action = (obj: any) => {
                        if (action) {
                            return action(obj?.data?.entity, obj?.data?.index);
                        }
                    }
                    actions.push(eaCopy);
                }
            );
        }

        // add Cat Actions
        this.getCatActionNavs(Router.CatAction.VIEW).forEach(catAct => {
            // catAct = Common.copy(catAct)
            catAct.entity = entity;
            catAct.data = { entity: entity, index: index };
            // this.profiler.log('Cat Action: ', catAct);
            actions.push(catAct);
        });

        // this.profiler.log(index, actions, entity);
        /*
        const actionLimit = this.getCategoryConfigs()?.actions?.limit;
        // this.profiler.log('Action Count Limit: ', actionLimit, this.getAction());
        if (actions.length > ((actionLimit !== undefined) ? Number.parseInt(actionLimit) : 2)) {
            return [{ id: '...', label: 'Choices', children: actions, icon: Images.Icon.v3dots }]
        } else {
            return actions;
        }
        */
        return actions;

    }
}
