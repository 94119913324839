import React from 'react';
import { EntityFilterService } from '../../@uno-filter/service/entity-filter.service';
import { BaseEntity, Common, EntityConstants, FilterConstants } from "../../@uno/api";
import { DesignerConstants, UC, UnoComponent } from "../../@uno/core";
import { EntitySearch, SearchEvent } from './entity-search.comp';

@UnoComponent({
    id: 'EntitySelector',
    label: 'Entity Select Box',
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Entity.id,
    props: [
        { groupID: 'General', id: 'entityCategoryID', label: 'Category', editor: 'CategorySelector' },
        { groupID: 'General', id: 'canTypeahead', label: 'Can Typeahead?', dataType: EntityConstants.PropType.BOOLEAN },
        { groupID: 'Filters', id: 'filterOptions', label: 'Filter Options', dataType: EntityConstants.PropType.JSON, },
    ],
    events: [SearchEvent.onSelected],
})
export class EntitySelector extends EntitySearch {
    // private entities: Array<BaseEntity> = [];

    async handle_CategoryLoaded() {
        // if (this.getCategoryID()) { this.loadResult(); }
        await this.loadResult();
    }

    loadResult = async () => {
        let conditions = [...this.getDefaultFilters(),];

        const entities = await FilterConstants.find(conditions, this.getFilterOptions(), this.getAppID());
        this.reRender({ entities: entities });
        // console.log('Load Entity Selection Results: ', this.state.filters, conditions, entities);
    }

    buildContent() {
        const entities: Array<any> = Common.safeParse(this.getEntityList()) || [];

        const handleOnSelect = (opt: any) => {
            let e = entities?.find((entity, index, list) => { return (entity._id === opt.id); });

            // console.log('Selected Option: ', opt, e, entities);
            opt.isSelected = true;
            const onSelected = this.getOtherProps()?.onSelected;
            if (onSelected) {
                onSelected(e);
            }

            Common.notifyEvent(this, SearchEvent.onSelected, { theComp: this, entity: e, data: e, });
            this.setEntity(e, false);
        }

        let options: Array<any> = entities?.map(
            (e: any) => {
                // console.log('Is Selected: ', (e.getID() === this.getEntityId()), this.getEntity())
                return {
                    id: e.getID(),
                    label: e.toString(),
                    isSelected: (e.getID() === this.getEntityId()),
                    action: handleOnSelect
                };
            }
        );

        // console.log('Options: ', options);

        if (this.canTypeahead()) {
            const typeOptions = options.map(opt => {
                opt = { ...opt };
                if (opt.originalAction) {
                    opt.action = opt.action;
                }
                opt.isSelected = false;
                return opt;
            });

            const onTypeSelect = (opt: any) => {
                const option = options.filter(orgOpt => {
                    if (orgOpt.id = opt.id) {
                        orgOpt.isSelected = opt.isSelected;
                        return true;
                    } else {
                        return false;
                    }
                })[0];

                // console.log('Type Selected:', option);
                handleOnSelect(option);
                this.reRender({ option: options });
            }

            const typeAhead: any = {
                id: 'finder',
                label: 'Finder',
                canSelect: false,
                buildView: () => {
                    return (
                        <UC.TypeAhead
                            options={typeOptions}
                            onSelect={onTypeSelect}
                            key={this.getUniqueKey()}
                        // label={this.state.label}
                        />
                    )
                }
            }

            if (Array.isArray(options)) {
                options.unshift(typeAhead);
            } else {
                options = [typeAhead];
            }
        }

        return (
            <>
                <UC.SelectBox
                    options={options}
                    key={Common.getUniqueKey()}
                />
            </>
        );
    }

    getCategoryID() {
        const _props: any = this.props
        if (_props.entityCategoryID) {
            return _props.entityCategoryID;
        } else {
            super.getCategoryID();
        }
    }

    getFilterOptions() {
        let options = this.state.filterOptions || {};
        if (options) {
            options = Common.safeParse(options)
        }
        return options;
    }

    canTypeahead(): boolean {
        return this.state.canTypeahead || false;
    }

}