import React from 'react';
import { EntityCategory, EntityConstants, EntityProp, Images } from '../../../api';
import { Common, ComponentDef, ContentConfig, DDM, EM, UC, UnoComponent, UnoComponentManager } from '../../../core';
import { EventHandlerCategory } from '../../../defs/uno-component';

const Constants = {
    EVT_ACTIVE_CONTENT_CHANGED: 'active_content_changed',
    EVT_TREE_MODIFIED: 'content_tree_modified',
    EVT_REFRESH_TREE: 'refresh_content_tree',
    EVT_NODE_DELETED: 'content_node_deleted',
    EVT_SETTINGS_CHANGED: 'content_settings_changed',
    EVT_SCREEN_CHANGED: 'preview_screen_changed',

    STYLE_DRAG_OVER: 'designer-drag-over',
    STYLE_ACTIVE: 'designer-active-content',

    getScreenDPI: () => {
        const el = document.createElement('div');
        el.style.width = '1in';
        document.body.appendChild(el);
        const dpi = el.offsetWidth * window.devicePixelRatio;
        document.body.removeChild(el);
        return dpi;
    },

    calcRelativeResolution: (width: number) => {
        return (window.screen.width / width) * Constants.getScreenDPI();
    },

    isContainer: (config: ContentConfig) => {
        return UnoComponentManager.isContainerComp(config.compID);
    },

    addChild: async (parent: ContentConfig, child: ContentConfig, index: number = -1, clbk: any = () => { }) => {
        if (child === parent) {
            Common.showError('Cannot add to itself.');
            return;
        }

        await Constants.handleChildAction(child, Action.ADD.id, parent, index);

        if (clbk) {
            clbk();
            // this.saveConfig();
        }
    },

    // refresh id of each comp in the tree;
    refreshID: (config: ContentConfig) => {
        return UnoComponentManager.refreshID(config);
    },

    handleClickOnComp: (e: any, config: ContentConfig) => {
        e.preventDefault();
        e.stopPropagation();
        setActiveContent(config);
        // alert(`${this.config.id + ' - ' + this.config.compID} is active`);
    },

    handleDragOver: (e: any, config: ContentConfig) => {
        if (Constants.isContainer(config)) {
            e.preventDefault();
            e.stopPropagation();
            e.currentTarget?.classList.add(Constants.STYLE_DRAG_OVER);
        }
    },

    handleDragEnd: (e: any, config: ContentConfig) => {
        if (Constants.isContainer(config)) {
            e.preventDefault();
            e.stopPropagation();
            e.currentTarget?.classList.remove(Constants.STYLE_DRAG_OVER);
        }
    },

    removeSection: (config: ContentConfig, actionClbk: any = () => { }) => {
        const confirmation = window.confirm(`Are you sure to remove ${config.compID}?`);
        if (confirmation && actionClbk) {
            actionClbk(Action.REMOVE.id);
        }
    },

    handleChildAction: async (childConfig: any, action: string, parent?: ContentConfig, index: number = -1) => {
        index = (index >= 0) ? index : Constants.getChildIndex(parent, childConfig?.id,);

        const children = (parent && parent.children) ? parent.children : [];
        if (parent) {
            parent.children = children;
        }

        const doSwap = (index: number, targetIndex: number) => {
            const prev = { ...children[targetIndex] };
            const current = { ...children[index] };
            children[targetIndex] = current;
            children[index] = prev;
            setActiveContent(children[targetIndex], parent, targetIndex);
            EM.emit(Constants.EVT_TREE_MODIFIED);
        }

        // console.log(`Parent - ${this.getConfigLabel(this.config)}, action - ${action}, Child - ${this.getConfigLabel(childConfig)}, at index - ${index}`);
        switch (action) {
            case Action.ADD.id:
                // console.log('Adding a Child Comp: ', childConfig, index, parent?.id);
                if (index >= 0 && index < children.length - 1) {
                    children.splice(index, 1, childConfig);
                    setActiveContent(children[index], parent, index,);
                } else {
                    children.push(childConfig);
                    index = children.length - 1;
                    setActiveContent(children[index], parent, index);
                }
                EM.emit(Constants.EVT_TREE_MODIFIED);
                break;
            case Action.REMOVE.id:
                if (index >= 0) {
                    children.splice(index, 1);
                    if (children.length > 0) {
                        index = (children.length - 1 > index) ? index : children.length - 1;
                        setActiveContent(children[index], parent, index);
                    } else if (parent) {
                        setActiveContent(parent,);
                    }
                }
                EM.emit(Constants.EVT_TREE_MODIFIED);
                break;
            case Action.MOVE_UP.id:
                if (index > 0) {
                    doSwap(index, index - 1);
                }
                break;
            case Action.MOVE_DOWN.id:
                if (index >= 0 && index < (children.length - 1)) {
                    doSwap(index, index + 1);
                }
                break;
            case Action.COPY.id:
                const childText = Common.stringify(childConfig);
                if (childText) {
                    await navigator.clipboard.writeText(childText);
                    Common.showMessage('Copied');
                } else {
                    Common.showError('Unable to Copy');
                }
                break;
            case Action.PASTE.id:
                const readText = await navigator.clipboard.readText();
                if (readText) {
                    const child = Constants.refreshID(Common.safeParse(readText));
                    children.push(child);
                    Common.showMessage('Pasted');
                    setActiveContent(children[children.length - 1], parent);
                    EM.emit(Constants.EVT_TREE_MODIFIED);
                } else {
                    Common.showError('Unable to Paste');
                }
                break;
        }
        // this.saveConfig();
    },


    getChildIndex: (parent?: ContentConfig, childID?: any,): number => {
        let matched = -1;
        parent?.children?.forEach(
            (child: any, index: number) => {
                if (child.id === childID) {
                    matched = index;
                }
            }
        );
        return matched;
    },

    isActive(config: ContentConfig) {
        return (activeContent && activeContent.config && activeContent.config.id === config.id);
    }
};

const Action = {
    ADD: { id: 'add', label: 'Add', icon: Images.Icon.plus },
    REMOVE: { id: 'remove', label: 'Remove', icon: Images.Icon.delete },
    MOVE_UP: { id: 'moveup', label: 'Move Up', icon: Images.Icon.moveUp },
    MOVE_DOWN: { id: 'movedown', label: 'Move Down', icon: Images.Icon.moveDown },
    COPY: { id: 'copy', label: 'Copy', icon: Images.Icon.copy },
    PASTE: { id: 'paste', label: 'Paste', icon: Images.Icon.paste },
}

let activeCompDef: any;

const activeContent: { config?: ContentConfig, index?: number, parent?: ContentConfig } = {};
const setActiveContent = (config: ContentConfig, parent?: ContentConfig, index?: number,) => {
    const prevActive = { ...activeContent };
    if (config !== activeContent.config) {
        activeContent.parent = parent;
        activeContent.config = config;
        activeContent.index = (index && index >= 0) ? index : Constants.getChildIndex(parent, config?.id);

        EM.emit(Constants.EVT_ACTIVE_CONTENT_CHANGED, { current: activeContent, previous: prevActive });
    }
}

const deleteActiveContent = () => {
    EM.emit(Constants.EVT_NODE_DELETED, activeContent);
}

const Screens: any = {
    FULLSCREEN: {
        width: window.screen.width,
        height: window.screen.height,
        resolution: Constants.getScreenDPI(),
        factor: 1,
    },
    iPhone6S: {
        width: 375,
        height: 667,
        factor: 2,
        resolution: 326,
    },
};

@UnoComponent({ id: 'LayoutDesigner', label: 'Layout Designer' })
export class LayoutDesigner extends React.Component<{ config?: any, onSave?: Function }, any> {

    config: any;

    customScreen: any = {};

    constructor(props: any) {
        super(props);
        this.state = { ...this.props };
    }

    private _handler_content_tree_modified: any = undefined;

    componentDidMount() {
        this._handler_content_tree_modified = EM.register(Constants.EVT_TREE_MODIFIED, () => {
            EM.emit(Constants.EVT_REFRESH_TREE, this.config);
        });


        this.initConfig();
    }

    componentWillUnmount() {
        EM.unregister(this._handler_content_tree_modified);
    }

    render() {
        return (
            <UC.Dialog
                onClose={this.handleOnSave}
                title='Layout Designer'
                styles={{ width: '100%', height: '90%' }}
                titleStyles={{ color: 'blue', fontSize: '20px' }}
                screenMode='screen-full'
            >
                {this.buildDesigner()}
            </UC.Dialog>
        );
    }

    buildDesigner() {
        const maxHeight = 650; // (window.screen.availHeight - 100); // consider the height of the title bar.
        console.log('Screen Designer - Max Height : ', maxHeight)
        return (
            <UC.VSection cols='30%, 70%,'>
                <UC.VSection
                    cellStyles={{ paddingBottom: '5px' }}
                    styles={{ minWidth: '400px', maxHeight: `${maxHeight}px`, overflow: 'auto', padding: '10px' }}
                >
                    {this.buildComponentPalette(false)}
                    {this.buildContentTree(false)}
                    {this.buildSettings(false)}
                </UC.VSection>
                <UC.VSection
                    cellStyles={{ paddingBottom: '5px' }}
                    styles={{ padding: '10px' }}
                >
                    {this.buildCanvasHeader()}
                    {this.buildCanvas(maxHeight - 50)} {/* Consider the Canvas Header */}
                </UC.VSection>
            </UC.VSection>
        );

    }

    buildComponentPalette(minimized: boolean = false) {
        return (
            <UC.ComponentPalette
                minimized={minimized}
                onSelected={(comp: ContentConfig) => {
                    activeCompDef = comp;
                }}
                compList={this.getPalleteComps()}
            />
        );
    }

    buildCanvas(maxHeight?: number) {

        return (
            <div style={{ border: '1px solid', width: '100%', maxWidth: '1000px', maxHeight: `${maxHeight}px`, overflow: 'auto' }}>
                <Canvas config={this.getConfig()} />
            </div>
        );
    }


    buildCanvasHeader() {
        const actions: Array<any> = [
            /*
            {
                id: 'Delete',
                action: (nav: any, e: any) => {
                    e.stopPropagation();
                    deleteActiveContent();
                },
                icon: Images.Icon.clear,
            },
            */
            { id: 'Save', action: () => { this.handleOnSave(); }, icon: Images.Icon.save, },
        ];

        return (
            <UC.Section styleClasses=' center '>
                <UC.Navigation navs={actions} orientation='h' />
            </UC.Section>
        );

        /*
        const setScreen = (nav: any) => {
            console.log('Screen set: ', nav);
            EM.emit(Constants.EVT_SCREEN_CHANGED, nav.id);
        }

        return (
            <UC.VSection cols='20%, 80%'>
                <UC.Navigation navs={actions} orientation='h' />
                <UC.VSection cols='30%,70%'>
                    <div style={{ margin: '4px' }}>
                        Screen :
                        <UC.SelectBox
                            options={
                                Object.keys(Screens).map(
                                    (key: string) => {
                                        return { id: Screens[key], label: key };
                                    }
                                )
                            }
                            onSelect={setScreen}
                        />
                    </div>
                    <div style={{ zIndex: 1 }}>
                        <span> Custom Width: <input onChange={(evt) => { this.customScreen.width = evt.target.value.trim(); }} type='number' /> </span>
                        <span> Custom Height: <input onChange={(evt) => { this.customScreen.height = evt.target.value.trim(); }} type='number' /> </span>
                        <button onClick={() => {
                            try {
                                const w = Number.parseInt(this.customScreen.width);
                                const h = Number.parseInt(this.customScreen.height);
                                if (w && h) {
                                    const res = Constants.calcRelativeResolution(w);
                                    EM.emit(Constants.EVT_SCREEN_CHANGED, { width: w, height: h, resolution: res });
                                }
                            } catch (e) { }
                        }}>
                            Set
                        </button>
                    </div>
                </UC.VSection>
            </UC.VSection>
        );
        */
    }

    buildSettings(minimized: boolean = false) {
        const activeConfig = activeContent.config ? activeContent.config : this.getConfig();
        return (
            <ContentSettings config={activeConfig} minimized={minimized} />
        );
    }

    buildContentTree(minimized: boolean = false) {
        return (
            <ContentTree config={this.getConfig()} minimized={minimized} />
        );
    }

    getPalleteComps() {
        return this.state.paletteComps;
    }

    initConfig() {
        this.config = new ContentConfig();
        const inputConfig = this.props.config;
        if (inputConfig) {
            this.config = Common.safeParse(inputConfig);
        }
        setActiveContent(this.config);
        this.setState({});
    }

    handleOnClose = () => {
        return window.confirm('All unsaved changes will be lost. Are you sure to close?');
    }

    handleOnSave = () => {
        const onSave = this.props.onSave;
        if (onSave) {
            onSave(this.getConfig());
        }
    }

    getConfig() {
        return this.config;
    }

}

class Canvas extends React.Component<{ config: ContentConfig, }, any> {
    DEFAULT_SCREEN = Screens.FULLSCREEN;
    canvasScreen = this.DEFAULT_SCREEN;
    canvasID = Common.getUniqueKey('_layout_designer_');
    private _handler_screen_changed: any = undefined;

    constructor(props: any) {
        super(props);
        // console.log('Create Canvas');
    }

    componentDidMount() {
        this._handler_screen_changed = EM.register(Constants.EVT_SCREEN_CHANGED, async (selectedScreen: any) => {
            console.log('screen changed: ', selectedScreen);
            this.canvasScreen = selectedScreen;
            // this.setState({});
            const existingCanvas = document.getElementById(this.canvasID);

            if (existingCanvas) {
                const canvas = existingCanvas; // document.createElement('div');

                if (canvas) {
                    const transform = (this.DEFAULT_SCREEN.resolution / this.canvasScreen.resolution) * (this.canvasScreen.factor ? this.canvasScreen.factor : 1);
                    const styles = { width: `${this.canvasScreen.width}px`, height: `${this.canvasScreen.height}px`, transform: `scale(${transform})` }
                    canvas.style.transformOrigin = '0 0';
                    canvas.style.width = styles.width;
                    canvas.style.height = styles.height;
                    canvas.style.transform = styles.transform;
                    // canvas.style.color = 'red';
                    canvas.style.overflow = 'auto';
                    console.log('Tansformed: ', canvas.style);
                }

                /*
                const container = existingCanvas?.parentElement;
                existingCanvas?.childNodes.forEach(cn => {
                    canvas.appendChild(cn.cloneNode(true));
                });
                // container?.removeChild(existingCanvas);
                // existingCanvas.style.display = 'none';
                //canvas.id = this.canvasID;
                container?.appendChild(canvas);
                */
            }
        });
    }

    componentWillUnmount() {
        EM.unregister(this._handler_screen_changed);
    }

    render() {
        const transform = (this.DEFAULT_SCREEN.resolution / this.canvasScreen.resolution) * (this.canvasScreen.factor ? this.canvasScreen.factor : 1);
        return (
            <div id={this.canvasID} style={{}}>
                <ContentEditor config={this.props.config} />
            </div>
        );
    }


}

class ContentEditor extends React.Component<{ config: ContentConfig, actionClbk?: Function, minimized?: boolean, parent?: ContentConfig }, any> {
    config: ContentConfig = new ContentConfig();

    private _handler_content_node_deleted: any = undefined;
    private _handler_content_settings_changed: any = undefined;
    private _handler_active_content_changed: any = undefined;

    constructor(props: any) {
        super(props);
        if (this.props.config) {
            this.config = Common.safeParse(this.props.config);
        }
    }

    componentDidMount() {
        // console.log(`Content Config initialized - `, this.config);
        // this.setState({});

        this._handler_active_content_changed = EM.register(Constants.EVT_ACTIVE_CONTENT_CHANGED, (content: any) => {
            this.setState({});
            /*
            if (this.config.id === content?.previous?.config?.id) {
                this.setState({});
            }

            if (this.config.id === content?.current?.config?.id) {
                this.setState({});
            }
            */

        });

        this._handler_content_node_deleted = EM.register(Constants.EVT_NODE_DELETED, (node: any) => {
            if (node && node.config && this.config) {
                const deletedNodeConfig = node.config;
                if (deletedNodeConfig.id === this.config.id) {
                    // console.log('Deleting node - ', this.config.id, this.config.compID);
                    Constants.removeSection(this.config, this.triggerActionCallback);
                    EM.emit(Constants.EVT_TREE_MODIFIED);
                }
            }
        });

        this._handler_content_settings_changed = EM.register(Constants.EVT_SETTINGS_CHANGED, (config: any) => {
            if (config === this.config) {
                this.setState({});
            }
        });

    }


    componentWillUnmount() {
        EM.unregister(this._handler_content_node_deleted);
        EM.unregister(this._handler_active_content_changed);
        EM.unregister(this._handler_content_settings_changed);
    }

    render() {
        return (
            <>
                {this.buildContent()}
            </>
        );
    }

    buildContent() {
        let selectedCompDef: ComponentDef = this.getSelectedComp();
        let content = (selectedCompDef?.getPreview) ? selectedCompDef.getPreview(this.config, this.saveConfig, this.buildChildren) : <UC.Empty />;
        if (this.isActive() && selectedCompDef?.getDesign) {
            content = selectedCompDef.getDesign(this.config, this.saveConfig, this.buildChildren);
        }
        // console.log(`Content design ready: `, selectedCompDef, content);

        return (
            <div
                className={`${this.isActive() ? Constants.STYLE_ACTIVE : ''}`}
                title={`${this.config.compID} - ${this.config.id}`}
                style={{
                    minHeight: '20px',
                    margin: '5px',
                    border: this.isActive() ? '' : '1px dashed gray',
                }}

                onClick={(e) => { Constants.handleClickOnComp(e, this.config); }}

                draggable={true}
                onDragStart={
                    (e) => {
                        e.stopPropagation();
                        DDM.initDrag(this.config, () => { Constants.removeSection(this.config, this.triggerActionCallback) });
                    }
                }

                onDragOver={(e) => { Constants.handleDragOver(e, this.config) }}
                onDragLeave={(e) => { Constants.handleDragEnd(e, this.config) }}

                onDrop={
                    (e) => {
                        if (Constants.isContainer(this.config)) {
                            // console.log('Dropped');
                            e.preventDefault();
                            e.stopPropagation();
                            // console.log('Adding child...');
                            DDM.finishDrop(this.config,
                                (child: ContentConfig, index: number) => {
                                    Constants.addChild(this.config, child, index, this.refresh);
                                }
                            );
                            EM.emit(Constants.EVT_TREE_MODIFIED);
                        }
                    }
                }
            >
                {this.isActive() ?
                    (
                        <div style={{ textAlign: 'center' }}>
                            <ChildMover navData={{ config: this.config, parent: Common.safeParse(this.props.parent) }} />
                        </div>
                    )
                    : undefined}
                {content}
            </div>
        );
    }

    buildChildren = () => {
        // console.log(`Building children: `, this.config);
        if (this.config && this.config.children) {
            return this.config?.children?.map((childConfig: ContentConfig) => {
                return (
                    <ContentEditor
                        key={Common.getUniqueKey()}
                        config={childConfig}
                        parent={this.config}
                        actionClbk={async (action: string) => {
                            await Constants.handleChildAction(childConfig, action, this.config,);
                        }}
                    />
                );
            });
        } else {
            return <UC.Empty />;
        }
    }

    triggerActionCallback = (action: string) => {
        if (this.props.actionClbk && action) {
            // console.log(`Trigger ${action} callback on - ${this.getConfigLabel(this.config)} `);
            this.props.actionClbk(action);
        }
        this.refresh();
    }

    saveConfig = () => {
        this.refresh();
    }

    refresh = () => {
        this.setState({ config: this.config });
    }

    getSelectedComp(): ComponentDef {
        const compID = this.config.compID;
        return UnoComponentManager.getDef(compID);
    }

    isActive() {
        return Constants.isActive(this.config);
    }
}

class ContentTree extends React.Component<{ config: ContentConfig, minimized?: boolean }, any> {
    config: ContentConfig = new ContentConfig();

    private _handler_refresh_content_tree: any = undefined;

    componentDidMount() {
        this._handler_refresh_content_tree = EM.register(Constants.EVT_REFRESH_TREE, (config: any) => {
            //this.config = config;
            // console.log('Content Tree is modified', this.config);
            this.setState({});
        });

        this.initConfig();
    }

    componentWillUnmount() {
        EM.unregister(this._handler_refresh_content_tree);
    }

    render() {
        return this.buildContentTree();
    }

    buildContentTree() {

        const getNavItem = (navConfig: any, index: number = -1, parent: any = undefined) => {
            if (navConfig) {
                const navData = { config: navConfig, index: index, parent: parent };
                const compID = navConfig.compID;
                const compDef = UnoComponentManager.getDef(compID);
                if (!compDef) {
                    console.log('Invalid CompDef: ', compID, navConfig,);
                    return undefined;
                }
                const navItem: any = {
                    id: navData,
                    label: `${compDef.label} - ${navConfig.id}`,
                    icon: compDef.icon,
                    action: () => {
                        setActiveContent(navConfig, parent, index);
                    },

                    buildView: (nav: any) => {

                        const navLabel = nav.label;
                        let navIcon = undefined;
                        if (nav.icon) {
                            const navIconStyle = ` tool-icon `;
                            navIcon = (<img src={nav.icon} alt={navLabel} className={navIconStyle} title={navLabel} />)
                        }

                        const navLabelView = (
                            <span
                                style={
                                    {
                                        // fontWeight: activeContent.config?.id === navConfig.id ? 'bolder' : 'inherit'
                                    }
                                }
                            >
                                {navIcon} &nbsp; {navLabel} <br /> {<ChildMover navData={navData} />}
                            </span>
                        );

                        return (
                            <span
                                onClick={(e) => { Constants.handleClickOnComp(e, navConfig); }}
                                title={`${navConfig.compID} - ${navConfig.id}`}

                                draggable={true}
                                onDragStart={
                                    (e) => {
                                        e.stopPropagation();
                                        DDM.initDrag(navConfig, async () => {
                                            Constants.removeSection(navConfig, async (action: string) => {
                                                if (parent) {
                                                    await Constants.handleChildAction(navConfig, action, parent,);
                                                }
                                            });
                                        });
                                    }
                                }

                                onDragOver={(e) => { Constants.handleDragOver(e, navConfig) }}
                                onDragLeave={(e) => { Constants.handleDragEnd(e, navConfig) }}

                                onDrop={
                                    (e) => {
                                        if (Constants.isContainer(navConfig)) {
                                            // console.log('Dropped On: ', navConfig.id);
                                            e.preventDefault();
                                            e.stopPropagation();
                                            // console.log('Adding child...');
                                            DDM.finishDrop(navConfig,
                                                (child: ContentConfig, index: number) => {
                                                    Constants.addChild(navConfig, child, index, () => { this.setState({}) });
                                                }
                                            );
                                            EM.emit(Constants.EVT_TREE_MODIFIED);
                                        }
                                    }
                                }
                            >
                                {navLabelView}
                            </span>
                        );
                    }
                }

                if (navConfig.children) {
                    navItem.children = navConfig.children.map((child: any, index: number) => {
                        return getNavItem(child, index, navConfig);
                    });
                }
                return navItem;
            } else {
                return undefined;
            }
        }

        const navs: Array<any> = [getNavItem(this.config)];

        return (
            <UC.Section title='Content Tree' minimized={this.props.minimized} key={Common.getUniqueKey()} resizable={true}>
                <UC.Navigation navs={navs} isTree={true} />
            </UC.Section>
        );
    }

    initConfig() {
        if (this.props.config) {
            this.config = Common.safeParse(this.props.config);
        }
        // console.log(`Content Config initialized - `, this.config);
        this.setState({});
    }
}

class ContentSettings extends React.Component<{ config?: ContentConfig, minimized?: boolean }, any> {
    config: ContentConfig = new ContentConfig();

    private _handler_active_content_changed: any = undefined;

    componentDidMount() {
        this._handler_active_content_changed = EM.register(Constants.EVT_ACTIVE_CONTENT_CHANGED, (activeContent: any) => {
            this.config = activeContent.current?.config;
            this.setState({});
        });
        this.initConfig();
    }

    componentWillUnmount() {
        EM.unregister(this._handler_active_content_changed);
    }

    render() {
        return (
            <UC.VSection cellStyles={{ paddingBottom: '5px' }}>
                {this.buildProperties()}
                {this.buildLifeEventHandlers()}
                {this.buildHandlers()}
            </UC.VSection>
        );
    }

    buildProperties() {
        const propLabel = this.config.compID;
        const CDef = this.getCompDef();
        const props: Array<EntityProp> = CDef?.props;

        const propCategory: EntityCategory = {
            id: `TCPC`,
            label: propLabel,
            props: props,
        };

        const eProp: EntityProp = {
            id: 'prop',
            label: propCategory.label,
            dataType: EntityConstants.PropType.ENTITY_INLINE,
            category: propCategory.id,
        };

        let defaultValue: any = this.config.eProps ? EntityConstants.build(this.config.eProps) : EntityConstants.buildEmpty(propCategory.id);

        return (
            <UC.Section
                title={`Properties : ${this.getCompLabel()} -  ${this.getCompID()}`}
                minimized={this.props.minimized}
                key={Common.getUniqueKey()}
                resizable={true}
                styles={{
                    padding: '10px'
                }}
            >
                <UC.PropEditor
                    category={propCategory}
                    entityProp={eProp}
                    defaultValue={defaultValue}
                    otherProps={{
                        onPropChanged: (eProp: EntityProp, entity: any) => {
                            this.config.eProps = entity;
                            // console.log('props set - ', this.config.props);
                            this.setState({ config: this.config, editingProps: false });
                            EM.emit(Constants.EVT_SETTINGS_CHANGED, this.config);
                        },
                        hideLabel: true,
                        canViewMain: false,
                    }}
                />
            </UC.Section>
        );
    }

    buildLifeEventHandlers() {
        const propLabel = this.config.compID;
        const CDef = this.getCompDef();
        const compEvents: Array<string> = CDef?.events;
        if (!compEvents || compEvents.length === 0) {
            return <UC.Empty />;
        }

        const eventsCategory: EntityCategory = {
            id: `Events - ${propLabel}`,
            label: propLabel,
            props: compEvents.map(evt => {
                return {
                    groupID: evt,
                    id: evt,
                    dataType: EntityConstants.PropType.FUNCTION,
                }
            }),
        };

        const eProp: EntityProp = {
            id: 'prop',
            label: eventsCategory.label,
            dataType: EntityConstants.PropType.ENTITY_INLINE,
            category: eventsCategory.id,
        };

        let defaultValue: any = this.config.events ? EntityConstants.build(this.config.events) : EntityConstants.buildEmpty(eventsCategory.id);
        if (this.config.events) {
            // console.log('Life Cycle Event content: ', defaultValue, this.config.events, eventsCategory);
        }

        return (
            <UC.Section
                title={`Lifecycle Events : ${this.getCompLabel()} -  ${this.getCompID()}`}
                minimized={this.props.minimized}
                key={Common.getUniqueKey()}
                resizable={true}
                styles={{
                    padding: '10px'
                }}
            >
                <UC.PropEditor
                    category={eventsCategory}
                    entityProp={eProp}
                    defaultValue={defaultValue}
                    otherProps={{
                        onPropChanged: (eProp: EntityProp, entity: any) => {
                            this.config.events = entity;
                            // console.log('props set - ', this.config.props);
                            this.setState({ config: this.config, editingProps: false });
                            EM.emit(Constants.EVT_SETTINGS_CHANGED, this.config);
                        },
                        hideLabel: true,
                        canViewMain: false,
                    }}
                />
            </UC.Section>
        );
    }

    buildHandlers() {
        const propLabel = this.config.compID;

        const handlerProp: EntityProp = { id: 'handlers', label: 'Handlers', multiplicity: -1, category: EventHandlerCategory.id, dataType: EntityConstants.PropType.ENTITY_INLINE }

        const handlersCategory: EntityCategory = {
            id: `Handlers - ${propLabel}`,
            label: propLabel,
            props: [handlerProp],
        };

        const eProp: EntityProp = {
            id: 'prop',
            label: handlersCategory.label,
            dataType: EntityConstants.PropType.ENTITY_INLINE,
            category: handlersCategory.id,
        };

        let defaultValue: any = EntityConstants.buildEmpty(handlersCategory.id);
        defaultValue[handlerProp.id] = this.config.handlers;

        return (
            <UC.Section
                title={`Event Handlers : ${this.getCompLabel()} -  ${this.getCompID()}`}
                minimized={this.props.minimized}
                key={Common.getUniqueKey()}
                resizable={true}
                styles={{
                    padding: '10px'
                }}
            >
                <UC.PropEditor
                    category={handlersCategory}
                    entityProp={eProp}
                    defaultValue={defaultValue}
                    otherProps={{
                        onPropChanged: (eProp: EntityProp, entity: any) => {
                            this.config.handlers = entity ? entity[handlerProp.id] : undefined;
                            // console.log('props set - ', this.config.props);
                            this.setState({ config: this.config, });
                            EM.emit(Constants.EVT_SETTINGS_CHANGED, this.config);
                        },
                        hideLabel: true,
                        canViewMain: false,
                    }}
                />
            </UC.Section>
        );
    }

    getCompDef() {
        return UnoComponentManager.getDef(this.config.compID);
    }

    initConfig() {
        if (this.props.config) {
            this.config = Common.safeParse(this.props.config);
            // console.log(`Content Config initialized - `, this.config);
        }
        this.setState({});
    }


    getCompLabel() {
        return UnoComponentManager.getCompLabel(this.config.compID);
    }

    getCompID() {
        return this.config.id;
    }
}

class ChildMover extends React.Component<{ navData: { config: ContentConfig, parent?: ContentConfig, index?: number } }, any> {
    private _Handler_EVT_ACTIVE_CONTENT_CHANGED: any;

    componentDidMount(): void {
        this._Handler_EVT_ACTIVE_CONTENT_CHANGED = EM.register(
            Constants.EVT_ACTIVE_CONTENT_CHANGED, (ac: any) => {
                this.setState({});
            }
        )
    }

    componentWillUnmount(): void {
        EM.unregister(this._Handler_EVT_ACTIVE_CONTENT_CHANGED);
    }

    render() {
        let show = false;
        const navData = this.getNavData();
        const config = navData.config;
        const parent = navData.parent;
        const children = parent?.children;
        const index = Constants.getChildIndex(parent, config.id);

        if (Constants.isActive(config)) {
            show = true;
        }

        if (show) {
            const createNav = (navDef: any, actionFn?: any) => {
                return {
                    ...navDef,
                    action: async (nav: any) => {
                        if (actionFn) {
                            await actionFn(nav);
                        } else {
                            await Constants.handleChildAction(config, nav.id, parent,);
                        }
                    }
                }
            }

            const navs = [];
            if (children && children.length > 1) {
                if (index > 0) {
                    navs.push(createNav(Action.MOVE_UP));
                }

                if (index >= 0 && index < (children.length - 1)) {
                    navs.push(createNav(Action.MOVE_DOWN));
                }
            }

            if (Constants.isContainer(config)) {
                navs.push(
                    createNav(Action.ADD,
                        async (nav: any) => {
                            if (activeCompDef) {
                                const child = new ContentConfig(activeCompDef.id);
                                // console.log('To Add a child: ', child, config);
                                await Constants.handleChildAction(child, nav.id, config,)
                            }
                        }
                    )
                );
            }

            navs.push(createNav(Action.COPY));

            if (Constants.isContainer(config)) {
                navs.push(createNav(Action.PASTE, async (nav: any) => {
                    await Constants.handleChildAction(undefined, nav.id, config,)
                }));
            }

            if (parent) {
                navs.push(
                    createNav(
                        Action.REMOVE,
                        async (nav: any) => {
                            const confirmation = window.confirm(`Are you sure to remove ${config.compID}?`);
                            if (confirmation) {
                                await Constants.handleChildAction(config, nav.id, parent,);
                            }
                        }
                    )
                );
            }

            return (<UC.Navigation navs={navs} isToolbar={true} visibleChildCount={10} />);
        } else {
            return undefined;
        }
    }

    getNavData = () => {
        return this.props.navData;
    }


}