import React from 'react';
import { DesignerConstants, UC, UnoCompEvents, UnoComponent, UnoCoreBaseComp } from '../../@uno/core';
import { Common, EntityConstants } from '../../@uno/api';
import { UnoSvgBase } from './uno-svg-base.comp';

@UnoComponent({
    id: 'UnoSvgLine',
    label: 'SVG Line',
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Graphics.id,
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    props: [
        {
            groupID: 'Config',
            id: 'start',
            label: 'Start Point',
            dataType: EntityConstants.PropType.JSON,
            editor: 'SVGPointEditor',
            viewer: 'SVGPointViewer'
        },
        {
            groupID: 'Config',
            id: 'end',
            label: 'End Point',
            dataType: EntityConstants.PropType.JSON,
            editor: 'SVGPointEditor',
            viewer: 'SVGPointViewer'
        },
   ],
    isContainer: false,
})
export class UnoSvgLine extends UnoSvgBase {

    buildSVG() {

        return (
            <line
                key={this.getInputID()}
                style={this.getStyles()}
                className={this.getStyleClasses()}

                x1={this.getX1()}
                y1={this.getY1()}
                x2={this.getX2()}
                y2={this.getY2()}

            />
        );
    }

    getX1() {
        return this.getStart()?.x || 0;
    }

    getY1() {
       return this.getStart()?.y || 0;
    }

    getX2() {
        return this.getEnd()?.x || 0;
    }

    getY2() {
        return this.getEnd()?.y || 0;
    }

    getStart() {
        return Common.safeParse(this.state.start);
    }

    getEnd() {
        return Common.safeParse(this.state.end);
    }

}

