import { BaseEntity, EntityConstants, RemoteService, Router } from '../../@uno/api';
import { Common, EM, } from '../../@uno/core';
import { SessionManager } from './session.service';


class AppMenuServiceImpl {

    async getMenuPrimary(appID: string, session?: any, noHome: boolean = false, homeLabel: string = 'Home') {
        let menuItems: Array<any> = [];
        if (!noHome) {
            menuItems.push({ id: homeLabel, to: `/${appID}` })
        }

        const primaryMenus = await this.getMenu('app/menu/primary', appID, session);
        if (primaryMenus) {
            menuItems = menuItems.concat(primaryMenus);
        }
        return menuItems;

        /*
        return new Promise(
            (resolve, reject) => {
                RemoteService.post('app/menu/primary', { appID: appID, session: session })
                    .then(
                        resp => {
                            resp.json().then(
                                (menu: any) => {
                                    // console.log('App Menu Service Response: ', menu);
                                    menuItems = menuItems.concat(menu);
                                    // console.log('App Menu: ', menuItems);
                                    resolve(menuItems);
                                }
                            )
                        }
                    ).catch(err => { resolve(menuItems); });
            }
        );
        */
    }

    async getMenuNonCoreCat(appID: string, session?: any) {
        let menuItems: Array<any> = [];
        // console.log('Finding Other Menu Items: ', appID, session?.id);
        return await this.getMenu('app/menu/others', appID, session);
        /*
        return new Promise(
            (resolve, reject) => {
                RemoteService.post('app/menu/others', { appID: appID, session: session })
                    .then(
                        resp => {
                            resp.json().then(
                                (menu: any) => {
                                    // console.log('App Menu Service Response: ', menu);
                                    menuItems = menuItems.concat(menu);
                                    // console.log('Other Menu Items: ', menuItems);
                                    resolve(menuItems);
                                }
                            )
                        }
                    ).catch(err => { resolve(menuItems); });
            }
        );
        */
    }

    async getMenuCoreCats(appID: string, session?: any) {
        let menuItems: Array<any> = [
        ];
        return await this.getMenu('app/menu/core', appID, session);
        /*
        return new Promise(
            (resolve, reject) => {
                RemoteService.post('app/menu/core', { appID: appID, session: session })
                    .then(
                        resp => {
                            resp.json().then(
                                (menu: any) => {
                                    // console.log('App Menu Service Response: ', menu);
                                    menuItems = menuItems.concat(menu);
                                    // console.log('App Menu: ', menuItems);
                                    resolve(menuItems);
                                }
                            )
                        }
                    ).catch(err => { resolve(menuItems); });
            }
        );
        */
    }

    getMenu = async (service: string, appID: string, session: any = SessionManager.activeSession, menuID?: string, menu?: BaseEntity): Promise<Array<any>> => {
        let menuItems: Array<any> = [];
        // console.log('Finding Other Menu Items: ', appID, session?.id);

        return new Promise(
            (resolve, reject) => {
                RemoteService.post(service, { appID: appID, session: session, menuID: menuID, menu: menu })
                    .then(resp => {
                        resp.json().then(
                            (menu: any) => {
                                // console.log('App Menu Service Response: ', menu);
                                menuItems = menuItems.concat(menu);
                                // console.log('Other Menu Items: ', menuItems);
                                resolve(menuItems);
                            }
                        ).catch(e => {
                            resolve(menuItems);
                        });
                    }).catch(err => {
                        resolve(menuItems);
                    });
            }
        );
    }

    async find(appID: string, menuID?: string, menu?: BaseEntity, session?: any,) {
        return this.findMenu(appID, menuID, menu, session);
    }
    
    async findMenu(appID: string, menuID?: string, menu?: BaseEntity, session?: any,) {
        return await this.getMenu('app/menu/find', appID, session, menuID, menu,);
    }

    async setVisibility(nav: any, session: any = SessionManager.activeSession,) {
        return await RemoteService.getData(await RemoteService.post('app/menu/setVisibility', { nav: nav, session: session, }));
    }
}

export const AppMenuService = new AppMenuServiceImpl();