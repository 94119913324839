import { UnoModule } from '../../@uno/core';
import { CalendlyComp } from './component/calendly.comp';
import { SprintfulComp } from './component/sprintful.comp';
// The Module...

@UnoModule(
    {
        id: 'CalenderModule',
        imports: [
        ],
        components: [
            CalendlyComp,
            SprintfulComp,
        ],
    }
)
export default class CalenderModule {

}

