import React from 'react';
import { DesignerConstants, UC, UnoCoreBaseComp } from '../../@uno/core';


export class UnoSvgBase extends UnoCoreBaseComp {

    buildComp() {
        if (DesignerConstants.isLiveMode(this)
            // || DesignerConstants.isPreviewMode(this)
        ) {
            return this.buildSVG();
        } else {
            return (
                <UC.Section
                    title={this.getCompLabel()}
                    styles={{ padding: '0px 5px' }}>
                    {this.props.children}
                </UC.Section>
            );
        }
    }

    buildSVG() {
        return (<>{this.getCompLabel()}</>);
    }

}

