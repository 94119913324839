import React from 'react';
import { Common, DesignerConstants, UC, UnoCompEvents, UnoComponent } from "../../../@uno/core";
import { BasePropViewer } from "../prop-base.comp";
import { FilterConstants } from '../../../@uno/api';

@UnoComponent({
    id: 'FilterDefViewer',
    label: 'Filter Definition Viewer',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Viewer.id,
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    props: [],

})
export class FilterDefViewer extends BasePropViewer {

    buildValue() {
        const conditions = Common.safeParse(this.getDefaultValue());
        FilterConstants.build(conditions, this.getAppID()).then(res => { console.log('Conditions: ', conditions, 'Query: ', res) });
        return (
            <>
                <UC.VSection show={this.showDef()} accordion={true} >
                    <UC.JSONViewer defaultValue={this.getDefaultValue()} otherProps={{ hideLabel: true }} />
                </UC.VSection>
                <UC.VSection show={this.showResult()} accordion={true} >
                    <UC.FilterResults
                        title='Result:'
                        appID={this.getAppID()}
                        conditions={conditions}
                        key={Common.getUniqueKey()}
                        otherProps={this.getOtherProps()}
                    />
                </UC.VSection>
            </>
        );
    }

    showDef() {
        return (this.state.showDef === false) ? false : true;
    }

    showResult() {
        return (this.state.showResult === false) ? false : true;
    }
}