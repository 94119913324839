import React from 'react';
import { AppScreenService } from '../../../../@uno-app/service/app.screen.service';
import { EntityConstants, Images } from '../../../api';
import { Common, ComponentDef, ContentConfig, UC, UnoCompEvents, UnoComponent, UnoComponentManager } from '../../../core';
import { UnoCoreBaseComp } from './../uno-core.base.comp';

@UnoComponent({
    id: 'LayoutRenderer',
    label: 'Layout Renderer',
    paletteable: true,
    group: Common.Designer.PaletteGroup.Layout.id,
    icon: Images.Icon.maximize,
    props: [
        { groupID: 'Config', id: 'screen', label: 'Screen Entity', dataType: EntityConstants.PropType.ENTITY, category: 'uno_screen_def' },
        {
            groupID: 'Config',
            id: 'mode',
            label: 'Mode',
            extras: {
                options: Object.keys(Common.Designer.Mode).map(key => {
                    const modes: any = Common.Designer.Mode;
                    return { id: modes[key], label: key }
                }),
            },
            editor: 'OptionSelector',
            viewer: 'OptionViewer'
        },
        { groupID: 'Config', id: 'config', label: 'Configuration', dataType: EntityConstants.PropType.JSON },
        { groupID: 'Other', id: 'moreProps', label: 'Additional Props', dataType: EntityConstants.PropType.JSON, },
    ],
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],

})
export class LayoutRenderer extends UnoCoreBaseComp {

    componentDidMount() {
        super.componentDidMount();
        // perform initializations...
        // this.initConfig();
        this.loadScreen();
    }

    buildComp() {
        const config = this.getConfig();
        const mode = this.props.mode;
        const key = Common.getUniqueKey('layout_renderer_');
        // this.profiler.log('Render Layout: ', config);
        return (
            (config && Object.keys(config).length > 0) ?
                <ContentRenderer
                    config={config}
                    mode={mode}
                    key={key}
                    __id={key}
                />
                : <UC.Empty />
        );
    }

    initConfig(config?: any) {
        if (!config) {
            const inputConfig = this.state.config;
            if (inputConfig) {
                config = Common.safeParse(inputConfig);
            }
            // this.profiler.log('Initial Layout Config: ', inputConfig, this.props);
        }

        this.setState({ config: config });
    }

    getConfig() {
        let configCopy = Common.safeParse(this.state.config); // { ...this.state.config }; // UnoComponentManager.refreshID(this.state.config);
        const moreProps = Common.safeParse(this.state.moreProps);
        if (moreProps) {
            configCopy = { ...configCopy, props: { ...configCopy?.props, ...moreProps, }, };
        }
        return configCopy;
    }


    loadScreen = async (screenEntity?: any, force: boolean = false) => {
        let config = undefined;
        if (!screenEntity) {
            const screen = this.state.screen;
            // console.log('Loading Layout Screen: ', screen, this.props);
            screenEntity = Common.safeParse(screen);
        }

        if (screenEntity) {
            screenEntity = EntityConstants.build(screenEntity);
            config = await AppScreenService.getScreenByID(screenEntity.getID(), screenEntity.getAppID());
        }

        if (config) {
            this.initConfig(config);
        } else if (force) {
            console.log('Force Clear as no Config/Screen to load in this renderer. ', screenEntity);
            this.reRender({ config: undefined });
        }
    }

    setMoreProps = async (props: any = {}) => {
        this.reRender({ moreProps: props });
    }

}


class ContentRenderer extends UnoCoreBaseComp {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    canProfile(): boolean {
        // return ['FilterResults',].includes(this.getConfig().compID);
        return false;
    }

    buildComp() {
        const config = this.getConfig();
        let selectedCompDef: ComponentDef = this.getSelectedComp();
        /*
        const conf: any = this.config;
        if (conf.isScreen) {
            this.profiler.log('Screen Content Renderer: Building Content: ', conf, selectedCompDef, selectedCompDef?.getLive);
        }
        */

        let content = <UC.Empty />;

        if (this.props.mode === Common.Designer.Mode.PREVIEW && selectedCompDef?.getPreview) {
            content = selectedCompDef.getPreview(config, undefined, this.buildChildren);
        } else if (selectedCompDef?.getLive) {
            this.profiler.log('layout rendering comp: ', selectedCompDef.id, config.id, this.props.__id);
            content = selectedCompDef.getLive(config, undefined, this.buildChildren);
        }
        return content;
    }

    buildChildren = (configChildren: any = this.getConfig()?.children, clbk?: any, mode: string = Common.Designer.Mode.PREVIEW) => {
        if (configChildren?.length > 0) {
            return configChildren?.map(
                (childConfig: ContentConfig) => {
                    const key = Common.getUniqueKey();

                    if (mode === Common.Designer.Mode.LIVE) {
                        childConfig = { ...childConfig }; // duplicate in live mode.
                    }
                    const inheritedProps = { ...this.getConfig().inheritedProps, ...this.getConfig().props, ...this.state, ...childConfig.inheritedProps, };
                    delete inheritedProps.styles;
                    delete inheritedProps.styleClasses;

                    childConfig.inheritedProps = inheritedProps;
                    // this.profiler.log('Build Child Content: ', childConfig);
                    return (
                        <ContentRenderer
                            mode={mode}
                            config={childConfig}
                            key={key}
                            __id={key}
                        />
                    );
                }
            );
        } else {
            return <UC.Empty />;
        }
    }

    getSelectedComp(): ComponentDef {
        const compID = this.getConfig().compID;
        return UnoComponentManager.getDef(compID);
    }

    getConfig() {
        let config = this.state?.config;
        if (!config) {
            config = this.props.config;
        } else if (Common.checkType.String(config)) {
            config = Common.safeParse(config);
        }
        return config;
    }

}
