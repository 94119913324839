import { Common, EM } from "../../api";

declare var FB: any;

const Event = {
    INITED: 'FB_AUTH_INITED',
    SDK_INITED: 'FB_SDK_INITED',
};

const asynInit = function () {
    if (FB === undefined) {
        console.log('Facebook Auth Problem');
        return;
    } else {
        FB?.init({
            appId: FBLogin.appId,
            cookie: true,
            xfbml: true,
            version: 'v22.0'
        });

        FB?.AppEvents.logPageView();
    }

    FBLogin.fb = FB;
    // console.log('FB Async Init: ', FB);
    EM.emit(FBLogin.Event.INITED,);
};

EM.register(Event.SDK_INITED, async () => {
    while (window.FB === undefined) {
        await Common.pause(1);
    }
    asynInit();
});

export const FBLogin = {
    appId: '2231242150402662',
    Event: Event,
    fb: window.FB,

    init: async () => {
        try {
            window.fbAsyncInit = asynInit;

            (function (d, s, id) {
                var js: any, fjs: any = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) { return; }
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js?version=v2.8";
                fjs.parentNode.insertBefore(js, fjs);
                // console.log('FB SDK Inited: ', js);
                EM.emit(FBLogin.Event.SDK_INITED, { js: js });
            }(document, 'script', 'facebook-jssdk'));

        } catch (e) {
            console.log('FB Init Error: ', e);
        }
    }
}
