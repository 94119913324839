import { UnoModule } from '../defs/uno-module';
import { UnoIconsModule } from '../media/uno-icons.module';
import { Button } from './components/button.comp';
import { Carousel } from './components/carousel.comp';
import { Chip } from './components/chip.comp';
import { ComponentPalette } from './components/comp-palette.comp';
import { UnoContainersModule } from './components/containers/uno-containers.module';
import { Empty } from './components/empty.comp';
import { FieldSet } from './components/fieldset.comp';
import { GeoLocationComp } from './components/geo-location.comp';
import { IFrame } from './components/iframe.comp';
import { LayoutDesigner } from './components/layout/layout-designer.comp';
import { LayoutRenderer } from './components/layout/layout-renderer.comp';
import { Loading, Refresh } from './components/loading.comp';
import { Navigation } from './components/navigations/navigation.comp';
import { WindowNav } from './components/navigations/window-nav.comp';
import { PDFViewer } from './components/pdf/pdf-viewer.comp';
import { PhotoCaptureComp } from './components/photo-capture.comp';
import { Printer } from './components/printer.comp';
import { UnoQRCode } from './components/qrcode/qrcode.comp';
import { ScreenReaderComp } from './components/reader/reader.comp';
import { ScreenDefCompModule } from './components/screen-def/screen-def.comp';
import { SelectBox } from './components/select-box/selectbox.comp';
import { TypeAhead } from './components/select-box/typeahead.comp';
import { SessionTimer } from './components/session-timer.comp';
import { ShareComp } from './components/share/share.comp';
import { ShowDialog } from './components/show-dialog.comp';
import { ShowMessage } from './components/show-message.comp';
import { STT } from './components/stt/stt.comp';
import { TabbedPane } from './components/tabbed-pane.comp';
import { Timer } from './components/timer.comp';
import Toggler from './components/toggler/toggler.comp';
import { Translator } from './components/translator.comp';
import { UnoCoreBaseComp } from './components/uno-core.base.comp';
import { CsvUpload } from './components/upload/csv.upload.comp';
import { VideoRecorderComp } from './components/video/video.comp';
import { VoiceRecorderComp } from './components/voice/voice.comp';
import { WriterComp } from './components/writer/writer.comp';

@UnoModule(
    {
        id: 'UnoCoreModule',
        imports: [
            UnoIconsModule,
            UnoContainersModule,
            ScreenDefCompModule,
        ],
        components: [
            UnoCoreBaseComp,
            Empty,
            SessionTimer,
            ShowMessage,
            ShowDialog,
            STT,
            WriterComp,
            VoiceRecorderComp,
            VideoRecorderComp,

            Carousel,
            Chip,
            Timer,

            Navigation,
            WindowNav,
            Toggler,
            Button,
            ShareComp,

            PhotoCaptureComp,
            GeoLocationComp,
            SelectBox,
            TypeAhead,
            Loading,
            Refresh,

            TabbedPane,
            Printer,
            IFrame,

            CsvUpload,
            UnoQRCode,

            ComponentPalette,
            LayoutDesigner,
            LayoutRenderer,
            Translator,
            ScreenReaderComp,

            PDFViewer,
            FieldSet,
        ],
    }
)
export class UnoCoreModuleImpl {

}

export const UnoCoreModule = new UnoCoreModuleImpl();
