import { EntityViewTypes } from '../../@uno-entity/service/entity-view.service';
import { EntityConstants } from '../../@uno/api';
import { EntityProp } from '../../@uno/api/entity.service';
import { UC } from '../../@uno/core';

export { EntityViewTypes };

class EntityPropServiceImpl {
    private viewers: any = {};
    private editors: any = {};

    constructor() {
        this.initViewers();
        this.initEditors();
    }

    initViewers() {
        /*
        PropViewer, DateTimeViewer, ImageViewer, MultilineViewer, NumberViewer, OptionViewer, PasswordViewer
        */
        this.viewers[EntityConstants.PropType.DEFAULT] = 'BasePropViewer';
        this.viewers[EntityConstants.PropType.STRING] = 'BasePropViewer';
        this.viewers[EntityConstants.PropType.EMAIL] = 'EmailViewer';
        this.viewers[EntityConstants.PropType.PASSWORD] = 'PasswordViewer';

        this.viewers[EntityConstants.PropType.JSON] = 'JSONViewer';
        this.viewers[EntityConstants.PropType.DATE] = 'DateViewer';
        this.viewers[EntityConstants.PropType.TIME] = 'TimeViewer';
        this.viewers[EntityConstants.PropType.DATETIME] = 'DateTimeViewer';
        this.viewers[EntityConstants.PropType.NUMBER] = 'NumberViewer';
        this.viewers[EntityConstants.PropType.BOOLEAN] = 'BooleanViewer';
        this.viewers[EntityConstants.PropType.MULTILINE] = 'MultilineViewer';
        this.viewers[EntityConstants.PropType.HTML] = 'MultilineViewer';
        this.viewers[EntityConstants.PropType.FUNCTION] = 'SourceCodeViewer';
        this.viewers[EntityConstants.PropType.COLOR] = 'BasePropViewer';
        this.viewers[EntityConstants.PropType.ENCRYPT] = 'PasswordViewer';
        this.viewers[EntityConstants.PropType.IMAGE] = 'ImageViewer';
        this.viewers[EntityConstants.PropType.FILE] = 'FileViewer';
        this.viewers[EntityConstants.PropType.ENTITY] = 'PropEntityQuickViewer';
        this.viewers[EntityConstants.PropType.ENTITY_INLINE] = 'PropInlineEntityViewer';
        this.viewers[EntityConstants.PropType.ARRAY] = 'PropMultipleViewer';
        this.viewers[EntityConstants.PropType.LAYOUT] = 'LayoutViewer';
        this.viewers[EntityConstants.PropType.STANDARD_FUNCTION] = 'StandardFunctionViewer';
    }

    initEditors() {
        this.editors[EntityConstants.PropType.DEFAULT] = 'TextEditor';
        this.editors[EntityConstants.PropType.STRING] = 'TextEditor';
        this.editors[EntityConstants.PropType.EMAIL] = 'EmailEditor';
        this.editors[EntityConstants.PropType.PASSWORD] = 'PasswordEditor';
        this.editors[EntityConstants.PropType.DATE] = 'DateEditor';
        this.editors[EntityConstants.PropType.TIME] = 'TimeEditor';
        this.editors[EntityConstants.PropType.DATETIME] = 'DateTimeEditor';
        this.editors[EntityConstants.PropType.NUMBER] = 'NumberEditor';
        this.editors[EntityConstants.PropType.MULTILINE] = 'MultilineEditor';
        this.editors[EntityConstants.PropType.HTML] = 'HTMLEditor';
        this.editors[EntityConstants.PropType.JSON] = 'JSONEditor';
        this.editors[EntityConstants.PropType.FUNCTION] = 'SourceCodeEditor';
        this.editors[EntityConstants.PropType.BOOLEAN] = 'BooleanEditor';
        this.editors[EntityConstants.PropType.COLOR] = 'ColorEditor';
        this.editors[EntityConstants.PropType.ENCRYPT] = 'MD5EncryptedEditor';
        this.editors[EntityConstants.PropType.IMAGE] = 'ImageEditor';
        this.editors[EntityConstants.PropType.FILE] = 'FileEditor';
        this.editors[EntityConstants.PropType.ENTITY] = 'PropEntityEditor';
        this.editors[EntityConstants.PropType.ENTITY_INLINE] = 'PropInlineEntityEditor';
        this.editors[EntityConstants.PropType.ARRAY] = 'PropMultipleEditor';
        this.editors[EntityConstants.PropType.LAYOUT] = 'LayoutEditor';
        this.editors[EntityConstants.PropType.STANDARD_FUNCTION] = 'StandardFunctionSelector';
    }

    getDataType = (prop: EntityProp) => {
        let dataType = EntityConstants.PropType.DEFAULT;
        if (prop) {
            if (!prop.multiplicity || prop.multiplicity === 1) {
                if (prop.dataType) {
                    dataType = prop.dataType;
                } else if (prop.category) {
                    dataType = EntityConstants.PropType.ENTITY;
                }
            } else {
                dataType = EntityConstants.PropType.ARRAY;
            }
        }
        return dataType;
    }

    getViewer(prop: EntityProp) {
        let type: string = this.getDataType(prop);
        let viewer = (prop.viewer && (this.isEntity(prop) || type !== EntityConstants.PropType.ARRAY)) ? prop.viewer : this.viewers[type];
        if (!viewer) {
            viewer = this.viewers[EntityConstants.PropType.DEFAULT];
        }
        // console.log(`Viewer for prop type - ${type} is `, viewer);
        return UC[viewer];
    }

    getEditor(prop: EntityProp) {
        let type: string = this.getDataType(prop);
        let editor = (type !== EntityConstants.PropType.ARRAY && prop.editor) ? prop.editor : this.editors[type];
        if (!editor) {
            editor = this.editors[EntityConstants.PropType.DEFAULT];
        }
        // console.log(`Editor for prop type - ${type} is `, editor);
        return UC[editor];
    }


    isEntity(prop: EntityProp) {
        const datatype = prop?.dataType;
        return EntityConstants.isEntityType(datatype);
    }

    isEntityInline(prop: EntityProp) {
        const datatype = prop?.dataType;
        return (datatype === EntityConstants.PropType.ENTITY_INLINE);
    }

    isArray(prop: EntityProp) {
        const datatype = prop?.dataType;
        return (datatype === EntityConstants.PropType.ARRAY);
    }

}

export const EntityPropService = new EntityPropServiceImpl();