import React from 'react';
import { ComponentDef, UC, UnoComponent, UnoComponentManager, UnoModule, } from '../../../core';
import { UnoCoreBaseComp } from '../uno-core.base.comp';
import { Common, EM, EntityConstants, EntityProp, FilterConstants, Images, RemoteService } from '../../../api';

// Implementation: Custom Screen Comp 
const CUSTOM_SCREEN_COMP_ID = 'CustomScreenComp';
const DEFAULT_GROUP = Common.Designer.PaletteGroup.Custom.id;

let activeAppID: string | undefined = undefined;
let screenCompDefs: any = {};

@UnoComponent(
    {
        id: CUSTOM_SCREEN_COMP_ID,
    }
)
class CustomScreenCompRenderer extends UnoCoreBaseComp {

    buildComp() {
        // console.log('CustomScreenComp: ', this.props, this.state);
        const children = this.state.children;
        const compID = this.state.compID;
        let Layout = (<>Custom Screen CompRenderer - {compID}</>);

        let compScreenDef = Common.safeParse(compID);
        let screenLayout: any = undefined;
        if (Common.checkType.Object(compScreenDef)) {
            const appID = compScreenDef.app_id;
            const sDefs: Array<any> = screenCompDefs[appID];
            if (sDefs?.length > 0) {
                const sDef = sDefs.find(
                    (v) => {
                        return (v.id === compID);
                    }
                );
                screenLayout = sDef?.screen?.layout;
            }
        }

        if (screenLayout) {
            screenLayout = Common.safeParse(screenLayout);
            // console.log('Rendering Custom Comp via Screen: ', screenLayout, children);

            // add children
            const childConfigs = screenLayout.children || [];

            if (Array.isArray(children)) {
                children?.forEach((child: any) => {
                    const cConfig = Common.safeParse(child.props.config);
                    if (cConfig) {
                        childConfigs.push({ ...cConfig });
                    }
                });
            }
            screenLayout.children = childConfigs;

            screenLayout.props = { ...screenLayout.props, ...this.state };

            // render layout
            const mode = this.getDisplayMode();
            // console.log('Build Custom Comp Layout: ', mode, screenLayout);
            Layout = (
                <UC.LayoutRenderer
                    config={screenLayout}
                    mode={mode}
                    key={Common.getUniqueKey(compID)}
                />
            )
        }

        // get layout from screen-def

        return (
            <>
                {Layout}
            </>
        )
    }
}

class CustomScreenCompDef implements ComponentDef {
    paletteable = true;
    isContainer = false;
    id: string = CUSTOM_SCREEN_COMP_ID;
    label: string = this.id;
    group: string = DEFAULT_GROUP;
    icon = Images.Icon.component;
    target = CustomScreenCompRenderer;
    screenDef: any = undefined;
    props: Array<EntityProp> = [];
    events: Array<string> = [];

    help: any = undefined;
    comp: any = undefined;

    async init(sc: any) {
        this.id = Common.stringify(EntityConstants.truncate(sc)) || sc._id;
        this.screenDef = sc;
        this.label = sc.name;
        this.group = sc.palette || DEFAULT_GROUP;
        this.isContainer = sc.is_container !== undefined ? sc.is_container : false;
        this.events = sc.life_events || [];
        if (sc.icon) {
            const icon: any = await EntityConstants.build(sc.icon).reload();
            if (icon?.downlink) {
                this.icon = RemoteService.getFullURL(icon.downlink);
            }
        }

        this.props = (sc.properties || []).map((pDef: any) => {
            const eProp: EntityProp = {
                id: pDef.prop_id,
                label: pDef.name || pDef.prop_id,
                description: pDef.description,
                dataType: pDef.data_type || EntityConstants.PropType.DEFAULT,
                category: pDef.entity_type || undefined,
                multiplicity: pDef.multiplicity || 1,
                defaultValue: pDef.default_value,
                extras: Common.safeParse(pDef.extra),
                validators: pDef.validators || [],

                groupID: pDef.groupID,
                serialNo: pDef.serialNo,
                editor: pDef.editor,
                viewer: pDef.viewer,

                noNew: pDef.not_on_create || false,
                noEdit: pDef.not_on_edit || false,
                noQV: pDef.not_on_qv || false,
                noSearch: pDef.not_on_search || false,
                quickSearch: pDef.quick_search || false,
                translate: pDef.translate || false,

                hidden: pDef.hidden || false,
                disabled: pDef.disabled || false,
                dependsOnProps: pDef.dependsOnProps || [],
            };
            return eProp;
        });
        this.help = sc.help;

        // register
        this.comp = UnoComponentManager.register(CustomScreenCompRenderer, this);

        return this;
    }

    getDesign(config?: any, clbk?: any, buildChildren?: Function): any {
        return (
            <>Custom Screen Comp: DESIGN</>
        );
    }

    getPreview(config?: any, clbk?: any, buildChildren?: Function): any {
        return (
            <>Custom Screen Comp: PREVIEW</>
        );
    }

    getLive(config?: any, clbk?: any, buildChildren?: Function): any {
        return (
            <>Custom Screen Comp: LIVE</>
        );
    }


}


@UnoModule({
    id: 'ScreenDefCompModule'
})
export class ScreenDefCompModule {

    constructor() {
        EM.register(Common.Event.APP_SWITCHED, async (appInfo: any) => {
            // console.log('Loading Components for AppID: ', appInfo);
            await this.unloadScreenComps();
            activeAppID = appInfo.id;
            await this.loadScreenComps(activeAppID);
        });

        console.log('INITIALIZED : ScreenDefCompModule ');
    }

    loadScreenComps = async (appID = activeAppID) => {
        if (!appID) {
            return;
        }

        const filters = [
            FilterConstants.setCategory(Common.CategoryID.ScreenDef),
            FilterConstants.create('paletteable', true),
            FilterConstants.create('app-id', appID),
        ];

        const screenDefs = await FilterConstants.find(filters, { limit: Number.MAX_SAFE_INTEGER }, appID,);
        if (screenDefs?.length > 0) {
            // console.log('Custom Screen Comps: ', appID, screenDefs,);
            const defs = [];
            for (let sd of screenDefs) {
                const compDef = await (new CustomScreenCompDef().init(sd));
                defs.push({ id: compDef.id, comp: compDef, screen: sd });
                const comp = UnoComponentManager.register(CustomScreenCompRenderer, compDef);
                // console.log('Custom Component: ', comp, compDef);
            }

            screenCompDefs[appID] = defs;

            EM.emit(Common.Event.SCREEN_COMPS_LOADED, { appID: appID });
            // console.log('Custom Screen Loaded: ', appID, screenDefs,);
        }

        return screenDefs;
    }

    unloadScreenComps = async (appID = activeAppID) => {
        if (appID) {
            console.log('Unload Screen Comps for App: ', appID);
            const compDefs = screenCompDefs[appID] || [];
            if (compDefs?.length > 0) {
                for (let cDef of compDefs) {
                    UnoComponentManager.unregister(cDef.comp?.id);
                }
                delete screenCompDefs[appID];
                EM.emit(Common.Event.SCREEN_COMPS_UNLOADED, { appID: appID });
            }
        }
    }

}

