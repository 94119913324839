import React from 'react';
import { Redirect } from 'react-router-dom';
import { AppInfoService } from '../../@uno-app/service/app.info.service';
import { AppScreenService } from '../../@uno-app/service/app.screen.service';
import { EntityCategoryService } from '../../@uno-app/service/entity.category.service';
import { SessionManager } from '../../@uno-app/service/session.service';
import { BaseEntity, BaseEntityService, Common, EntityCategory, EntityConstants, EntityProp, FilterConstants, Images, printPDF, Router } from '../../@uno/api';
import { Source } from '../../@uno/api/source.service';
import { DesignerConstants, EM, UC, UnoCompEvents, UnoComponent, UnoComponentManager } from '../../@uno/core';
import { AuthorizationService } from '../service/auth.service';
import { TriggerService } from '../service/entity-trigger.service';
import { Profiler } from '../../@uno/api/common.service';

export interface EntityCompProps {
    appID?: string;
    category?: EntityCategory;
    categoryID?: string,
    entityID?: string,
    entity?: any;
    entities?: Array<any>;
    otherProps?: any;
    layout?: any;
    action?: string;
    entityScreen?: BaseEntity,
    styles?: any,
}

export const ENTITY_COMP_PROPS: Array<EntityProp> = [
    {
        groupID: 'Entity',
        id: 'categoryID',
        label: 'Category ID',
        editor: 'CategorySelector'
    },
    {
        groupID: 'Entity',
        id: 'the_category',
        label: 'Category',
        description: 'Custom Category Definition',
        dataType: EntityConstants.PropType.JSON,
    },
    {
        groupID: 'Entity',
        id: 'entity',
        label: 'The Entity',
        dataType: EntityConstants.PropType.JSON,
    },
    // { id: 'entityID', label: 'Entity ID', },
    // { id: 'action', editor: 'ActionSelector' },
    {
        groupID: 'Rendering',
        id: 'entityScreen', label: 'Layout',
        dataType: EntityConstants.PropType.ENTITY,
        category: Common.CategoryID.ScreenDef,
    },
    {
        groupID: 'Rendering',
        id: 'customComponent',
        label: 'Custom Component',
        editor: 'ComponentSelector',
    },
    {
        groupID: 'Others',
        id: 'otherProps',
        label: 'Other Properties',
        dataType: EntityConstants.PropType.JSON,
    },
    // { id: 'styles', label: 'Styles', dataType: EntityConstants.PropType.JSON },
]

const Defaults = {
    PERMISSIONS: false,
    CHANGE_HISTORY: false,
}

type EventTrail = {
    event: string,
    timestamp: number,
    inputs?: any,
    result?: any,
}

export const getEntityDesign = (config?: any) => { return (<span>{UnoComponentManager.getCompLabel(config.compID)} - Design</span>); };
export const getEntityPreview = (config?: any) => { return (<span>{UnoComponentManager.getCompLabel(config.compID)} - Preview</span>); };

@UnoComponent({
    id: 'EntityBaseComp',
    props: ENTITY_COMP_PROPS,
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    // getDesign: getEntityDesign,
    // getPreview: getEntityPreview,
})
export class EntityBaseComp extends React.Component<any, any> {
    inputID = Common.getUniqueKey(`${this.constructor.name}_`);
    protected profiler = Profiler.init(this.inputID);

    private eventTrail: Array<EventTrail> = [];
    eInstanceActions: Array<string> = [Router.CatAction.EDIT, Router.CatAction.VIEW, Router.CatAction.QUICK_VIEW];
    redirectTo: any;
    printViewID = Common.getUniqueKey('print_');
    _event_handlers: any = [];
    permission: any;

    Event = {
        Action: {
            getRequested: () => {
                return Common.Event.getActionStage(this.getAction(), Common.Event.Stage.REQUESTED);
            },
            getProgressing: () => {
                return Common.Event.getActionStage(this.getAction(), Common.Event.Stage.PROGRESSING);
            },
            getCompleted: () => {
                return Common.Event.getActionStage(this.getAction(), Common.Event.Stage.COMPLETED);
            },
            getFailed: () => {
                return Common.Event.getActionStage(this.getAction(), Common.Event.Stage.FAILED);
            },
        },
        Save: {
            getRequested: () => {
                return Common.Event.getActionStage(this.getSaveAction(), Common.Event.Stage.REQUESTED);
            },
            getProgressing: () => {
                return Common.Event.getActionStage(this.getSaveAction(), Common.Event.Stage.PROGRESSING);
            },
            getCompleted: () => {
                return Common.Event.getActionStage(this.getSaveAction(), Common.Event.Stage.COMPLETED);
            },
            getFailed: () => {
                return Common.Event.getActionStage(this.getSaveAction(), Common.Event.Stage.FAILED);
            },
        },
        getAction: () => {
            return this.getAction();
        },
        getID: (action: string, stage?: string) => {
            return Common.Event.getActionStage(action, stage);
        },
    }

    // protected category?: EntityCategory = undefined;
    protected originalEntity?: BaseEntity = undefined;

    constructor(props: any) {
        super(props);
        // enable profiler if required
        this.profiler.disabled = !this.canProfile();

        const otherProps = Object.assign({}, Common.safeParse(this.props.otherProps));
        this.state = {
            ...this.props,
            otherProps: otherProps,
            _uuid_: this.inputID,
        };
        this.originalEntity = Common.safeParse(this.props.entity);


        // this.doLoad();
        this.profiler.log('Constructed', this.getAction(), this.getCategoryID(), this.getEntity(), this.getEntityList());
        // this.profiler.log('Creating Entity Comp: ', this.getAction(), this.constructor.name, this.getCategoryID());
    }

    protected async doLoad() {
        this.profiler.log('Loading Category, Layout and More...', this.getAction(), { ...this.state });
        await this.loadCategory();
        await this.doLoadMore();
        await this.loadLayout();
        this.profiler.log('...LOADED Category, Layout and More.', this.getAction(), { ...this.state });
    };

    protected async doLoadMore() {
        // to be overriden in children
    }

    componentDidMount() {
        this.profiler.log('Mounted', { ...this.state });
        this.doLoad();
        this._event_handlers = [...this._event_handlers, ...Common.registerEventHandlers(this)];
        Common.notifyEvent(this, UnoCompEvents.onLoad);
    }

    componentWillUnmount() {
        Common.notifyEvent(this, UnoCompEvents.onUnLoad);
        Common.unregisterEventHandlers(this._event_handlers, this);
        this.profiler.log('Un-Mounted', { ...this.state });
    }


    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        this.profiler.log('Rendering Updated', { ...this.state });
        Common.notifyEvent(this, UnoCompEvents.onViewUpdate);
    }

    render() {
        this.profiler.log('Being Rendered', { ...this.state });
        if (this.redirectTo) {
            return this.buildRedirect();
        } else if (!this.isLiveMode()) {
            const mode = this.getDisplayMode();
            switch (mode) {
                case DesignerConstants.Mode.DESIGN:
                    return getEntityDesign({ compID: this.state.compID });
                case DesignerConstants.Mode.PREVIEW:
                    return getEntityPreview({ compID: this.state.compID });
            }
        } else if (!this.getCategory()) {
            return (<UC.Loading target={this.reRender} />);
        } else if (!this.getEntity() && this.eInstanceActions.includes(this.getAction())) {
            return (<UC.Loading target={this.reRender} />);
        } else {
            this.profiler.log('Rendering content', this.getCategoryID(), { ...this.getCategory() }, this.getEntity(), this.getEntityList(), this.getLayout());
            return (
                <>
                    {this.buildContent()}
                </>
            );
        }
    }

    buildContent() {
        const layout = this.buildDesigner();
        return (
            <>
                {layout ? layout : (<div style={this.props.styles}><h1>{this.getCategoryLabel()}</h1></div>)}
            </>
        );
    }

    buildRedirect() {
        if (this.redirectTo) {
            // this.profiler.log(this.constructor.name, 'Redirect to:', this.redirectTo);
            const redirectView = <Redirect to={this.redirectTo} push={true} />
            this.redirectTo = undefined;
            return redirectView;
        } else {
            return null;
        }
    }

    buildDesigner(props: any = {}) {
        props = {
            entity: this.getEntity(),
            category: this.getCategory(),
            appID: this.getAppID(),
            otherProps: this.buildOtherPropsForEProp(),
            baseComp: this,
            ...props
        };

        let layout = this.getLayout();
        if (!layout) {
            const customComp: string | undefined = this.getCustomComponent();
            // this.profiler.log('No Custom Layout. Find Custom Comp: ', customComp, this);
            if (customComp && UC[customComp]) {
                const CustomComp = UC[customComp];
                return (
                    <CustomComp
                        {...props}
                        key={this.getUniqueKey()}
                    />
                )
            } else {
                return undefined;
            }
        } else {
            layout = { ...layout };
        }

        layout.props = props;
        this.profiler.log(this.getCategoryID(), this.getAction(), ` Building comp ${this.constructor.name}. Layout =  `, { ...layout },);
        return (
            <UC.LayoutRenderer
                config={layout}
                mode={DesignerConstants.Mode.LIVE}
                key={this.getUniqueKey()}
            />
        );
    }

    buildOtherPropsForEProp(noLabel: boolean = false, prop?: EntityProp) {
        let oProps: any = this.getOtherProps();
        if (!oProps) {
            oProps = {};
        }

        oProps.labelPosition = this.getOtherProps()?.propLabelPosition;
        oProps.hideLabel = noLabel;
        return oProps;
    }

    async loadCategory() {
        let appID = this.getAppID();
        let catID = this.getCategoryID();

        if (this.getCategory()) {
            await this.triggerSetCategory();
        } else if (this.getEntity()) {
            const entity = EntityConstants.build(this.getEntity());
            catID = entity.getCategoryID();
            appID = entity.getAppID();
        }

        if (!this.getCategory() && catID && appID) {
            // this.profiler.log(`${appID} - Loading category for id: ${catID}`);
            const category = await EntityCategoryService.getCategory(catID, true, appID);
            this.profiler.log(`${appID} - LOADED category for id: ${catID}`, category);
            if (category) {
                await this.setCategory(Common.safeParse(category));
            }
        }
    }

    async loadLayout(action = this.getAction()) {
        let layout = this.getLayout();
        if (!layout) {
            layout = await await AppScreenService.findLayout(this.getEntityScreen(), this.getAppID(), this.getCategoryID(), action, this.getEntity());
        }

        if (layout && Common.isEqual(layout, this.state.layout) === false) {
            this.profiler.log('Rendering Custom Layout : ', this.getAppID(), this.getCategoryID(), this.getAction(), layout);
            this.reRender(
                { layout: layout },
                () => {
                    // this.profiler.log('Custom Layout rendered: ', this.getAppID(), this.getCategoryID(), this.getAction());
                }
            );
        }
    }

    setRedirectTo = (to?: string) => {
        this.redirectTo = to;
        this.reRender();
    }

    reRender = (state: Object = {}, callback: any = () => { }) => {
        this.profiler.log('State Change:', this.getCategoryID(), this.getAction(), { ...state }, { ...this.state });
        this.setState(state, callback);
    }

    getCategoryActionScreen(action = this.getAction()) {
        const catScreens = this.getCategory()?.screens;
        const categoryActionScreen = catScreens ? catScreens[action] : undefined;
        if (categoryActionScreen) {
            // this.profiler.log('Cat Action Screen: ', this.getCategoryID(), this.getAction(), categoryActionScreen);
        }
        return categoryActionScreen;
    }

    getDisplayMode() {
        return this.state?.displayMode || this.props?.displayMode;
    }

    protected setPropValue(p: EntityProp, val: any, entity: BaseEntity = this.getEntity(), truncate: boolean = true) {
        if (entity) {
            const pEx = Common.safeParse(p.extras) || {};
            if (Object.keys(pEx).includes('truncate')) {
                truncate = pEx.truncate;
            } else if (p.dataType === EntityConstants.PropType.ENTITY_INLINE) {
                truncate = false;
            }

            EntityConstants.setValue(p, val, entity, truncate);
            /*
            if (truncate) {
                val = EntityConstants.buildTruncatedEntity(val)
            }
            entity.setValue(p.id, val);
            */

        }
    }

    handleActionRequestFailed = (result: any) => {
        // this.setRedirectTo(`/${this.getAppID()}`);
        window?.history.back();
    }

    protected getPropValue(p: EntityProp, entity: BaseEntity = this.getEntity()) {
        if (entity) {
            entity = EntityConstants.build(entity);
            return entity.getValue(p.id);
        }
        return undefined;
    }

    protected getUniqueKey = (prop?: EntityProp) => {
        return EntityConstants.getUniqueKey(prop?.id);
    }

    protected buildEmptyElement() {
        return (<UC.Empty key={Common.getUniqueKey()} />);
    }

    protected async setCategory(category: any, doCascade = true) {
        if (category?.id && Common.isEqual(this.getCategory(), category) === false) {
            // this.profiler.log(this.getAction(), ' - Initiating Set Category: ', category, this.category,);
            // change in category. 
            this.reRender({ category: category },);
            if (doCascade) {
                await this.triggerSetCategory(category);
            }
        }
    }

    async triggerSetCategory(category = this.getCategory()) {
        const event = this.getAction() ? this.Event.Action.getRequested() : undefined;
        if (event &&
            (
                this.findTrail(event)?.length === 0
                || (
                    !this.getEntity()
                    && (
                        !this.getEntityList()
                        || this.getEntityList()?.length === 0
                    )
                )
            )
        ) {
            this.profiler.log('triggerSetCategory: ', { ...this.state });
            await this.sendTrigger(
                event,
                async (result: any, inputs: any) => {
                    this.profiler.log('Setting Category: ', this.getCategory(), result, inputs);
                    await this.handleTriggerResult(result, inputs);
                    await this.handle_CategoryLoaded();
                    this.profiler.log('Category Set: ', this.getCategory());
                },
                this.handleActionRequestFailed,
                undefined,
                undefined,
                category,
            );
        } else {

        }
    }

    async handle_CategoryLoaded() {
        await this.loadLayout();
    }

    getEntity(): any {
        const entity = this.state.entity;
        return entity;
    }

    setEntity(entity: BaseEntity, doLoad: boolean = true) {
        if (entity) {
            entity = EntityConstants.build(Common.safeParse(entity));
            if (Common.isEqual(entity, this.state.entity)) {
                this.profiler.log('No change in entity');
            }
            // this.profiler.log('Reload for entity: ', doLoad, entity,);
            this.reRender({ entity: entity, }, doLoad ? this.doLoad : this.loadLayout);
        }
        // this.profiler.log('Setting entity:', entity);
    }

    getEntityScreen(): any {
        return this.state?.entityScreen;
    }

    setEntityScreen(screen: any) {
        if (screen) {
            this.reRender({ entityScreen: screen });
        }
    }

    getEntityId(): any {
        if (this.getEntity()) {
            return this.getEntity().getID();
        } else {
            return this.props.entityID;
        }
    }

    getLayout(): any {
        return this.state.layout;
    }

    getCustomComponent(): string | undefined {
        let customComponent: string | undefined = this.state.customComponent;

        if (!customComponent) {
            const catScreens = this.getCategory()?.screens;
            const actionScreen = Common.safeParse(catScreens ? catScreens[this.getAction()] : undefined);
            if (actionScreen && Common.checkType.String(actionScreen)) {
                customComponent = actionScreen;
                // this.profiler.log('Found Custom Component for Category Action: ', customComponent, this.getAction(), this.getCategoryID(), this.getAppID());
            }
        }
        return customComponent;
    }

    async loadEntityLayout(action: string = this.getAction()) {
        const viewType = this.getOtherProps()?.viewType;

        const layout = await AppScreenService.findLayout(
            this.getEntityScreen(),
            this.getAppID(),
            this.getCategoryID(),
            action,
            undefined,
        );
        if (layout) {
            this.reRender({ entityLayout: layout });
        }
    }

    getCategory(): any {
        return this.state.category || Common.safeParse(this.state.the_category);
    }

    getCategoryID(): any {
        let catID = this.getCategory()?.id;
        if (!catID) {
            const eList = this.getEntityList();
            if (this.getEntity()) {
                catID = EntityConstants.build(this.getEntity()).getCategoryID();
            } else if (eList && eList.length > 0) {
                catID = EntityConstants.build(eList[0]).getCategoryID();
            } else {
                catID = this.state.categoryID || this.props.categoryID;
            }
        }
        return catID;
    }

    setCategoryID(categoryID: string) {
        // this.profiler.log('Setting Entity Category: ', categoryID, this.constructor.name);
        this.reRender({ categoryID: categoryID }, this.doLoad);

    }

    getAction(): string {
        // return this.state.action ? this.state.action : Router.CatAction.MAIN;
        return this.state.action;
    }

    getSaveAction() {
        return Common.Event.getActionStage(this.getAction(), Router.CatAction.SAVE);
    }

    notifyHandlers(eventID: string, eventData: any) {
        EM.emit(eventID, eventData);
    }

    getOtherProps(): any {
        return this.state.otherProps;
    }

    getExtras(): any {
        let extras = undefined;
        const otherProps = this.getOtherProps();
        if (otherProps) {
            extras = otherProps.extras;
        }
        return extras;
    }

    setOtherProps(otherProps: any): any {
        return this.reRender({ otherProps: otherProps });
    }

    getTitle(): any {
        const otherProps = this.getOtherProps();
        if (otherProps) {
            if (otherProps.hideTitle) {
                return undefined;
            } else if (otherProps.title) {
                return otherProps.title;
            }
        }
        return this.getCategoryLabel();
    }

    getCategoryLabel() {
        const category = this.getCategory();
        if (category) {
            return category.label ? category.label : category.id;
        }
        return this.getCategoryID();

    }

    buildTitle() {
        const title = this.getTitle();
        if (!title) {
            return null;
        }
        return (
            <h3 style={{ padding: '20px 0px' }}>{title}</h3>
        );

    }

    doInflate(items?: any) {
        const isLiveMode = DesignerConstants.isLiveMode(this);
        if (isLiveMode && items) {
            const mode = DesignerConstants.Mode.LIVE;
            const options = [UnoComponentManager.getProps({ id: 'dummy', compID: 'Dummy', props: this.state }, mode)];
            const inflatedItems = Common.safeParse(UnoComponentManager.inflate(items, options, mode));
            if (Common.isEqual(items, inflatedItems) === false) {
                items = inflatedItems;
            }
        };
        return items;
    }

    getCategoryActions = (entityAction = this.getAction()) => {
        const actions: Array<any> = [];
        // Print Action...
        const printViewID = this.getPrintViewID();
        if (this.canPrint() && printViewID) {
            actions.push({
                id: 'Print',
                icon: Images.Icon.printer,
                action: async () => {
                    printPDF(printViewID, printViewID);
                }
            })
        }
        // Permission Action...
        if (this.canSetPermission()) {
            actions.push({
                id: 'Set Permissions',
                icon: Images.Icon.permission,
                action: async () => {
                    const dlg = { id: 'set_permission', content: '<b>Set Permission</b>' }
                    Common.showDialog(dlg);
                    if (!this.permission?.resource) {
                        const result = await TriggerService.send(
                            { entity: this.getEntity(), action: this.getAction() },
                            Common.Event.CREATE_ENTITY_ACTION_RESOURCE,
                        );
                        this.permission = { resource: result?.resource, permitted: true };
                    }

                    if (this.permission?.resource) {
                        this.setRedirectTo(Router.getEditRoute(this.permission.resource));
                    }
                    Common.hideDialog(undefined, dlg.id);
                },
            })
        }

        // View Change History Action...
        if (this.getEntityId() && this.canViewChangeHistory()) {
            actions.push({
                id: 'Change History',
                icon: Images.Icon.history,
                to: this.buildRoute(Router.CatAction.EDIT, this.getEntityId()),
                action: async (n: any, evt: any) => {
                    evt?.preventDefault();
                    Common.showDialog({
                        title: `Change History - ${this.getEntity()?.getName()}`,
                        comp: (
                            <UC.ChangeHistory
                                entity={this.getEntity()}
                                key={Common.getUniqueKey()}
                            />
                        ),
                        onClose: () => {
                            // Do nothing
                        }
                    });
                }
            });
        }

        this.getCatActionNavs(entityAction)
            .forEach(ca => {
                actions.push(ca);
            });
        // this.profiler.log('Category Actions: ', actions, this.permission);

        return actions;
    }

    // Actual Category Actions...
    buildCatActionNav = (nav: any) => {
        const theComp: any = this;
        const session = this.getSession();

        nav = { ...nav };
        const navActions: Array<string> = nav.actions;
        if (navActions?.length > 0) {
            let navActionsLogic = ' ';
            for (let i = 0; i < navActions.length; i++) {
                navActionsLogic += '\n\r' + navActions[i];
            }
            nav.action = async (n: any) => {
                const inputs = { theNav: n, theComp: theComp, session: session, API: Source.API };
                // this.profiler.log('Session: ', theSession, n, theComp, navActionsLogic);
                const fn = Source.getFunction(navActionsLogic);
                if (fn && Common.checkType.Function(fn)) {
                    await fn(inputs);
                } else {
                    await Source.execute(navActionsLogic, inputs);
                }
            }
        }

        nav.icon = this.doInflate(nav.icon);
        nav.label = this.doInflate(nav.label);
        nav.id = this.doInflate(nav.id);
        nav.to = this.doInflate(nav.to);
        nav.entity = this.getEntity();

        // process children;
        nav.children = nav.children?.map((child: any) => {
            return this.buildCatActionNav(child);
        });
        return nav;
    };

    getCategoryExtras() {
        return Common.safeParse(this.getCategory()?.extras) || {};
    }

    getCategoryConfigs = (entityAction = this.getAction()) => {
        const catConfigs = this.getCategoryExtras()?.configs;
        // this.profiler.log('Cat Config: ', entityAction, catConfigs);

        if (catConfigs) {
            return Common.safeParse(catConfigs[entityAction]);
        } else {
            return undefined;
        }
    }

    protected getCategoryPropByID = (id: any) => {
        return EntityConstants.getCategoryProp(this.getCategory(), id);
    }

    getCatActionNavs(entityAction: string) {
        const actions: any[] = [];
        const catActionsForEntityAction = this.getCategoryExtras()?.actions;
        if (catActionsForEntityAction) {
            let catActions: Array<any> = Common.safeParse(catActionsForEntityAction[entityAction]);
            catActions?.forEach(
                (act: any) => {
                    const actionNav = this.buildCatActionNav(act);
                    actions.push(actionNav);
                }
            );
        }
        return actions
    }

    getAppID() {
        let appID = this.state.appID || this.state.app?.id || this.state.app_id || AppInfoService.getActiveApp()?.id;
        return appID;
    }

    getSession() {
        return (this.state.session || SessionManager.activeSession);
    }

    getCompProp(id: string) {
        const thisProps: any = this.props;
        return thisProps[id];
    }

    getEntityService() {
        return new BaseEntityService(this.getCategory(), this.getAppID());
    }

    async loadEntity(id: string, callback?: any) {
        // this.profiler.log('Loading entity by ID : ', id);
        this.getEntityService().findByID(id).then(
            (entity: any) => {
                this.handleEntityLoaded(entity, callback);
            }
        );
    }

    async handleEntityLoaded(entity: any, callback?: any) {
        this.originalEntity = entity;
        if (entity) {
            this.permission = await AuthorizationService.getPermission(entity, this.getAction());
        }
        //await AuthorizationService.find({ appID: this.getAppID(), categoryID: this.getCategoryID(), action: this.getAction(), entityID: id });
        this.profiler.log('Permisson: ', this.permission);

        this.setEntity(entity, false);
        this.profiler.log('Loaded Entity : ', this.state.entity);
        if (callback) {
            callback(entity);
        }
    }

    loadEntities = async (queryEntity: any) => {
        this.reRender({ otherProps: { ...this.getOtherProps(), searching: true }, entities: [] });
        if (queryEntity) {
            queryEntity = EntityConstants.build(queryEntity)
            // this.profiler.log('Reloading based on crietria. ', queryEntity);

            const result = await this.getEntityService().findRecords(queryEntity, this.getCategory());
            this.handleLoadEntitiesResult(result);
        }
    }

    handleLoadEntitiesResult(result: any[]) {
        let entities: Array<any> = [];
        if (result && result.length && result.length > 0) {
            entities = result;
        } else {
            const msg = 'No [' + this.getCategory().label + '] found';
            this.profiler.log(msg);
        }

        if (this.getOtherProps().onSearchResult) {
            this.getOtherProps().onSearchResult(undefined, entities);
        } else {
            this.reRender({ otherProps: { ...this.getOtherProps(), searching: false }, entities: entities });
        }

        // this.reRender({ entities: entities });
        // this.profiler.log('Reloaded ', entities.length);
    }

    sendTrigger = async (
        event: string,
        callback?: Function,
        errorCallback?: Function,
        entity: BaseEntity = this.getEntity(),
        originalEntity = this.originalEntity,
        category: any = this.getCategory(),
        appID: any = this.getAppID(),
        session: any = this.getSession(),
        extras: any = undefined,
        entities: Array<BaseEntity> = this.getEntityList(),
    ) => {
        const inputs = this.buildTriggerInputs(event, entity, originalEntity, category, appID, session, extras, entities);

        // this.profiler.log(`${event} - Triggered - `, inputs);
        // this.notifyHandlers(event, inputs);

        const result = await TriggerService.send(inputs, inputs.event, true);
        // add to event trail
        this.eventTrail.push({
            event: inputs.event,
            timestamp: new Date().getTime(),
            // inputs: { ...inputs }, 
            // result: { ...result } 
        });

        // this.profiler.log(`${event} Trigger result - `, result);
        const resultError = result?.error;
        if (resultError) {
            Common.showError(resultError, true);
            if (errorCallback) {
                return errorCallback(result);
            }
        }

        if (result?.message) {
            // this.profiler.log(event, '- ', result.message);
            Common.showMessage(result.message);
        }

        if (result?.redirectTo) {
            // this.profiler.log('Redirect to : ', result.redirectTo);
            Common.hideDialog();
            this.setRedirectTo(result.redirectTo);
        } else if (callback) {
            return callback(result, inputs);
        } else if (result) {
            await this.handleTriggerResult(result, inputs);
        } else {
            // nothing happened - no need to refresh
            // this.reRender({ entity: EntityConstants.build(entity) });
            // this.profiler.log('Loaded Entity : ', this.state.entity);
        }
    }

    getEntityList = () => {
        return this.state.entities;
    }

    private buildTriggerInputs(
        event: string = this.getAction(),
        entity: BaseEntity = this.getEntity(),
        originalEntity = this.originalEntity,
        category: any = this.getCategory(),
        appID: any = this.getAppID(),
        session: any = this.getSession(),
        extras: any = undefined,
        entities: Array<BaseEntity> = this.getEntityList(),
    ) {
        return {
            appID: appID,
            category: category,
            entity: entity,
            originalEntity: originalEntity,
            event: event,
            action: this.getAction(),
            session: session,
            route: window.location?.href,
            entities: entities,
            ...extras
        };
    }

    async handleTriggerResult(result: any, inputs: any) {
        if (!result) {
            console.error('Error in Trigger Result: ', inputs,);
            return;
        } else {
            this.profiler.log('Entity Trigger Result: ', result, inputs)
        }

        if (result.entity) {
            result.entity = EntityConstants.build(result.entity);
        }

        let entityUpdated = false;
        let categoryUpdated = false;

        if (result.session && Common.isEqual(inputs.session, result.session) === false) {
            this.updateSession(result.session, result.appID);
        }

        if (Common.isEqual(inputs.category, result.category) === false) { // check for category
            this.profiler.log(`Category changed. Needs refresh:`, result.event, { ...result.category }, { ...inputs.category });
            // categoryUpdated = true;
            // this.category = result.category;
            await this.setCategory(result.category, false);
        }

        let inputEntity = inputs.entity;
        if (!result.entity || Common.isEqual(inputEntity, result.entity, false, true) === false) { // check for entity
            this.profiler.log(`Entity State changed. Needs refresh: `, result.event, { ...inputEntity }, { ...result.entity });
            entityUpdated = true;
        } else if (inputs.propChanged) {
            // TODO:
            // this.profiler.log('A Prop Changed: ', inputs.propChanged);
            const prop = inputs.propChanged.prop;
            if (EntityConstants.isEntityType(prop.dataType)) {
                // entityUpdated = true;
            }
        }
        if (categoryUpdated || entityUpdated) {
            // this.profiler.log('To Re-render - ', result.event, ' - Category Updated: ', categoryUpdated, ' or Entity Updated: ', entityUpdated);
            // this.profiler.log('Category - Action: ', this.getAppID(), this.getCategoryID(), this.getAction());
            this.reRenderOnTriggerResult(result);
        }
    }

    reRenderOnTriggerResult(result: any) {
        const entity = result?.entity || this.state.entity;

        this.profiler.log(this.constructor.name, 'reRenderOnTriggerResult: ', entity);
        if (entity) {
            this.setEntity(entity, false);
        }
    }

    protected updateSession(session: any, appID: any = this.getAppID()) {
        SessionManager.updateSession(appID, session);
    }

    buildRoute(action: any, entityID?: any) {
        return Router.buildRoute(
            this.getAppID(),
            this.getCategoryID(),
            action,
            entityID
        )
    }

    getQuickViewProps(category: EntityCategory = this.getCategory()): Array<EntityProp> {
        if (!category) {
            return [];
        }
        const viewProps = category.props?.filter(
            (prop: EntityProp) => {
                if (prop.noQV || prop.hidden) {
                    return false;
                }
                return true;
            }
        );
        return (viewProps) ? viewProps : [];
    }

    getViewProps(quickView = false, category: EntityCategory = this.getCategory(),) {
        let viewProps: Array<EntityProp> = [];

        if (category?.props) {
            const visibleProps = this.getOtherProps()?.visibleProps || [];
            if (visibleProps?.length > 0 && category.props.length > 0) {
                viewProps = category.props.filter(p => {
                    return visibleProps.includes(p.id);
                });
            } else if (quickView) {
                viewProps = this.getQuickViewProps(category);
            } else {
                viewProps = category.props.filter(p => {
                    return (p.hidden === undefined || p.hidden === false);
                });
            }
        }

        return viewProps;
    }

    getEditProps(category: EntityCategory = this.getCategory()): Array<EntityProp> {
        if (!category) {
            return [];
        }
        const viewProps = category.props?.filter(
            (prop: EntityProp) => {
                if (this.getAction() === Router.CatAction.EDIT && prop.noEdit) {
                    return false;
                } else if (this.getAction() === Router.CatAction.CREATE && prop.noNew) {
                    return false;
                } else {
                    return true;
                }
            }
        );
        return (viewProps) ? viewProps : [];
    }

    buildActions = (actions: Array<any> = [], horizontal: boolean = false, isToolbar: boolean = false) => {
        /*
        // this.profiler.log('Building Actions: ', actions, horizontal);
        let actionComp = null;
        if (horizontal) {
            actionComp = (<UC.Navigation navs={actions} orientation='h' isToolbar={isToolbar} key={Common.getUniqueKey('Actions__')} />)
        } else {
            actionComp = (<UC.SelectBox options={actions} key={Common.getUniqueKey('Actions__')} />);
        }
        return (
            <>
                {actionComp}
            </>
        );
        */

        return (
            <UC.AccessControlledActions
                key={Common.getUniqueKey('entity_actions_')}
                actions={actions}
                horizontal={horizontal}
                isToolbar={isToolbar}
            />
        );
    }

    getPrinterComp(): any {
        return undefined;
    }

    getPrintViewID(): any {
        return undefined;
    }

    canSetPermission() {
        const entity = this.getEntity();
        const person = this.getSession()?.person;

        if (this.getOtherProps()?.showPermissions === false || !person || !entity) {
            return false;
        }

        // check if the base url is for this entity
        const params = Router.parseParams(window.location.pathname);
        const baseEntity = EntityConstants.create(params[Router.ParamType.CATEGORY], params[Router.ParamType.APPLICATION]);
        baseEntity.setID(params[Router.ParamType.ENTITY_ID]);

        if (!Common.isEqual(entity, baseEntity) || ![Router.CatAction.EDIT, Router.CatAction.VIEW].includes(this.getAction())) {
            return false;
        }

        // this.profiler.log('Permission: ', this.permission, this.getAction(), this.getEntity());

        const showPermissions =
            this.permission?.permitted
            || (Common.isEqual(person, entity?.getCreatedByID()))
            // || (Common.isEqual(person, entity?.getLastModifiedByID()))
            || ((entity !== undefined) ? Defaults.PERMISSIONS : false);
        // this.profiler.log('Entity Permissions: ', showPermissions, this.getAction(), this.getEntity(),)
        return showPermissions;


    }

    canViewChangeHistory() {
        return this.canEdit() &&
            (
                (this.state.viewChange === true)
                || (this.getOtherProps()?.viewChange === true)
                || (this.getCategoryExtras()?.viewChange === true)
                || (this.getCategory()?.versioned === true)
            );
        /*
        const viewHistory = this.getOtherProps()?.viewHistory;
        return (viewHistory === undefined) ? Defaults.CHANGE_HISTORY : viewHistory;
        */
    }

    canPrint() {
        const showPrint = this.getOtherProps()?.showPrint;
        return (showPrint === undefined) ? false : showPrint;
    }

    canEdit() {
        let canEdit = true;

        const canEditConf = this.getCategoryConfigs()?.actions?.canEdit;
        if (canEditConf !== undefined) {
            canEdit = canEditConf;
        }

        const ce = this.getOtherProps()?.canEdit;
        // this.profiler.log('Edit - Other Props', this.getOtherProps(), this.getOtherProps()?.canEdit);
        if (ce !== undefined) {
            canEdit = ce;
        }
        return canEdit;
    }

    canViewMain() {
        let canViewMain = true;

        const canViewMainConf = this.getCategoryConfigs()?.actions?.canViewMain;
        if (canViewMainConf !== undefined) {
            canViewMain = canViewMainConf;
        }

        const cvm = this.getOtherProps()?.canViewMain;
        // this.profiler.log('Can View Main - Other Props', cvm);
        if (cvm !== undefined) {
            canViewMain = cvm;
        }
        return canViewMain;
    }

    canViewFull() {
        let canViewFull = true;

        const canViewFullConf = this.getCategoryConfigs()?.actions?.canViewFull;
        if (canViewFullConf !== undefined) {
            canViewFull = canViewFullConf;
        }
        // this.profiler.log('Can View Full Conf', canViewFull, this.getAction(), this.getCategoryConfigs());

        const cvf = this.getOtherProps()?.canViewFull;
        if (cvf !== undefined) {
            // purposely specified to not enable full view
            canViewFull = cvf;
        }
        // this.profiler.log('CVF', canViewFull, this.getAction(), this.getOtherProps());
        return canViewFull;
    }

    getDefaultFilters = () => {
        const catID = this.getCategoryID() ? this.getCategoryID() : this.getCategory()?.id;
        const filters: Array<any> = [];
        filters.push(
            {
                operator: FilterConstants.Operator.EQUAL.id,
                propID: EntityConstants.Attr.CATEGORY,
                value: catID,
                readonly: true,
            }
        );

        let extraFilters: Array<any> = [];

        const processExtraFilters = (filters: any) => {
            if (!filters) {
                return;
            }
            // this.profiler.log(`Processing extra filters: `, filters);

            if (Common.checkType.String(filters)) {
                filters = Common.safeParse(filters);
            }

            if (!Array.isArray(filters)) {
                filters = [filters];
            }

            if (filters.length > 0) {
                extraFilters = extraFilters.concat(filters); // Object.assign(extraFilters, filters);
            }
        }

        processExtraFilters(this.getCategoryExtras()?.filters);
        processExtraFilters(this.getExtras()?.filters);
        processExtraFilters(this.state?.filters);

        extraFilters.forEach((ef: any) => {
            filters.push(ef);
        });

        this.profiler.log(this.getCategoryID(), ` - Default Search filters: `, filters, this.getCategory());
        return filters;
    }

    isLiveMode = () => {
        return DesignerConstants.isLiveMode(this);
    }

    getStyles() {
        const styles = Common.safeParse(this.state.styles || this.getOtherProps()?.styles || this.getExtras()?.styles);
        return styles;
    }

    getStyleClasses() {
        const styles = this.state.styleClasses || this.getOtherProps()?.styleClasses || this.getExtras()?.styleClasses; // Common.safeParse(this.state.styleClasses);
        return styles ? styles : '';
    }


    canProfile() {
        let enabled = false;
        return enabled;
    }

    // Fine event trail
    findTrail = (event: string) => {
        const trail = this.eventTrail.filter(ev => {
            return ev.event === event;
        });
        return trail || [];
    }
}

