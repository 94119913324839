import React from 'react';
import { EntityConstants, Images } from '../../api';
import { Common, UC, UnoComponent } from '../../core';

@UnoComponent({
    id: 'Carousel',
    label: 'The Caraousel',
    props: [
        {
            id: 'animate',
            label: 'Animate',
            description: '',
            dataType: EntityConstants.PropType.BOOLEAN,
        },
        {
            id: 'autoNavTime',
            label: 'Automatic Navigation Time',
            description: '',
            dataType: EntityConstants.PropType.NUMBER,
        },
        {
            id: 'hideNav',
            label: 'Hide Navigation Buttons',
            description: '',
            dataType: EntityConstants.PropType.BOOLEAN,
        },
    ],
    paletteable: true,
    isContainer: true,
})
export class Carousel extends React.Component<any, any> {

    public static NAV_POSITION = {
        TOP: 'top',
        BOTTOM: 'bottom',
    }

    children: any = undefined;
    activeIndex: number = 0;
    navPosition = Carousel.NAV_POSITION.TOP;
    autoNavInterval: number = -1;
    autoNavigator: any;
    inFocus = false;

    constructor(props: any) {
        super(props);
        this.state = { ...props };
        this.initState();
    }

    private initState() {
        this.children = this.props.children;
        if (this.props.animate) {
            this.state = { ...this.state, animate: 'animate' };
        }

        if (this.props.navPosition) {
            this.navPosition = this.props.navPosition;
        }
        if (this.props.autoNavTime) {
            this.autoNavInterval = this.props.autoNavTime;

            if (this.autoNavInterval > 0) {
                console.log(`Auto Nav Interval: `, this.autoNavInterval);
                this.autoNavigator = setInterval(
                    () => {
                        if (this.inFocus) {
                            return;
                        }
                        if (this.activeIndex !== (this.children.length - 1)) {
                            this.navNext();
                        } else {
                            this.navFirst();
                        }
                    },
                    this.autoNavInterval * 1000
                );
            }
        }
    }

    render() {
        const children = this.children;
        let activeChild: any;
        if (children && Array.isArray(children) && children.length > 0) {
            activeChild = children[this.activeIndex];
        } else {
            return null;
        }

        return (
            <div className='caraousel' onMouseOver={() => { this.inFocus = true; }} onMouseOut={() => { this.inFocus = false; }}>
                {(this.navPosition === Carousel.NAV_POSITION.TOP) ? this.buildNavs() : null}
                <div className={this.state.animate} key={Common.getUniqueKey()}>
                    {activeChild}
                </div>
                {(this.navPosition === Carousel.NAV_POSITION.BOTTOM) ? this.buildNavs() : null}
            </div>
        )
    }

    componentWillUnmount() {
        if (this.autoNavigator) {
            clearInterval(this.autoNavInterval);
        }
    }

    navFirst = () => {
        this.testLimit(0);
    }

    navLast = () => {
        this.testLimit(Number.MAX_VALUE);
    }

    navPrevious = () => {
        this.testLimit(--this.activeIndex);
    }

    navNext = () => {
        this.testLimit(++this.activeIndex);
    }

    testLimit = (index: number, refresh: boolean = true) => {
        const children = this.children;
        if (children && Array.isArray(children)) {
            if (index < 0) {
                index = 0;
            } else if (index >= children.length) {
                index = children.length - 1;
            }
            this.activeIndex = index;
            this.setState({});
        }
    }

    buildNavs = () => {
        if (this.props.hideNav) {
            return null;
        }

        const navs = [
            {
                id: 'First', label: 'First <<', icon: Images.Icon.first, action: this.navFirst,
            },
            {
                id: 'Previous', label: 'Previous <', icon: Images.Icon.previous, action: this.navPrevious,
            },
            {
                id: 'Next', label: '> Next', icon: Images.Icon.next, action: this.navNext,
            },
            {
                id: 'Last', label: '>> Last', icon: Images.Icon.last, action: this.navLast,
            },
        ]

        return (<div className='center' style={{ overflow: 'hidden' }}><UC.Navigation navs={navs} isToolbar={true} orientation='h' /></div>);

    }

}
