import React from 'react';
import { Common } from '../../api/common.service';
import { EM } from '../../api/event-mgmt.service';
import { UC } from '../../core';
import { UnoCoreBaseComp } from './uno-core.base.comp';
import { DesignerConstants, UnoComponent } from '../../defs/uno-component';
import { EntityConstants } from '../../api';
import { AppScreenService } from '../../../@uno-app/service/app.screen.service';

@UnoComponent({ id: 'ShowDialog' })
export class ShowDialog extends UnoCoreBaseComp {
    private dialogDisplayer: any;
    private dialogCloser: any;
    // private dlgConfig: any;
    private dlgQueue: Array<any> = [];

    componentDidMount() {
        super.componentDidMount();
        this.dialogDisplayer = EM.register(Common.Event.SHOW_DIALOG, async (dlg: any) => {
            dlg.id = dlg.id || Common.getUniqueKey('dlg_');
            let layout = Common.safeParse(dlg.layout);

            const screenDef = Common.safeParse(dlg.screen);
            if (screenDef) {
                const screen: any = Common.checkType.String(screenDef) ? { _id: screenDef, } : EntityConstants.build(screenDef);
                const appID = screen.app_id || dlg.app_id || this.getAppID();
                // console.log('Loading Screen for dialog: ', screen, appID);
                layout = await AppScreenService.getScreenByID(screen._id, appID);
            }

            if (layout) {
                layout.props = {
                    _dlg_id: dlg.id,
                    ...layout.props,
                    ...dlg.props,
                };

                dlg.comp = (
                    <UC.LayoutRenderer
                        config={layout}
                        mode={DesignerConstants.Mode.LIVE}
                        key={Common.getUniqueKey('_dialog_comp')}
                    />
                );
            }

            if (dlg.comp || dlg.content) {
                const orgOnClose = dlg.onClose;
                if (orgOnClose) {
                    dlg.onClose = () => {
                        // console.log('Close Dialog: ', dlg);
                        EM.emit(Common.Event.HIDE_DIALOG, { clbk: orgOnClose, id: dlg.id });
                    }
                }

                // add default styles
                const styles = {
                    backgroundColor: '#FFFFFF',
                };
                dlg.styles = (dlg.styles) ? { ...styles, ...dlg.styles } : styles;

                const titleStyles = {
                    /* backgroundColor: '#D3D3D3', */
                };
                dlg.titleStyles = (dlg.titleStyles) ? { ...titleStyles, ...dlg.titleStyles } : titleStyles;

                // add to queue
                this.dlgQueue.push(dlg);
                // console.log('Adding to Dlg Queue: ', dlg.id);
            }
            // this.dlgConfig = config;
            this.reRender();
        });

        this.dialogCloser = EM.register(Common.Event.HIDE_DIALOG, async (data: any) => {
            const id = data.id;
            let toCloseDlgs = [];
            if (!id) {
                toCloseDlgs = this.dlgQueue.splice(0);
            } else {
                const idx = this.findIndex(id);
                if (idx >= 0) {
                    toCloseDlgs = this.dlgQueue.splice(idx, 1);
                }
            }

            // console.log('To Close Dialogs: ', data, toCloseDlgs);

            try {
                toCloseDlgs.forEach(dlg => {
                    if (dlg.onClose) {
                        dlg.onClose();
                    }

                    const dlgNode = document.getElementById(dlg.id);
                    if (dlgNode) {
                        /*
                        try {
                            const parent = dlgNode.parentNode;
                            console.log('Parent Node of the dialog: ', dlg.id, parent)
                            if (parent) {
                                parent.removeChild(dlgNode);
                            }
                        } catch (de) {

                        }
                        */
                        dlgNode.style.display = 'none';
                    }
                });

                const clbk = data.clbk;
                if (clbk) {
                    clbk();
                }
            } catch (e) { }

            if (this.dlgQueue?.length === 0) {
                this.reRender();
            }
        });
    }

    componentWillUnmount() {
        EM.unregister(this.dialogDisplayer);
        EM.unregister(this.dialogCloser);
        super.componentWillUnmount();
    }

    buildComp() {
        const dialogs = this.dlgQueue.map(
            dlg => {
                // console.log('Render Dialog: ', dlg);
                return (
                    <UC.Dialog
                        key={dlg.id}
                        anchorID={dlg.id}
                        {...dlg}
                        title={dlg.dlgTitle || dlg.title}
                    >
                        {dlg.comp}
                    </UC.Dialog>
                );
            }
        );

        return (<>{dialogs}</>);
    }

    findIndex = (id: string) => {
        for (let i = 0; i < this.dlgQueue.length; i++) {
            const dlg = this.dlgQueue[i];
            if (dlg.id === id) {
                return i;
            }
        }
        return -1;
    }
}
