import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import { DesignerConstants, UC, UnoComponent, Common, Images, EM } from '../../@uno/core';
import { AppInfo } from '../service/app.info.service';
import { UnoAppBaseComp } from './uno-app.base.comp';

interface Tab {
    name: string,
    comp: any,
    active?: boolean,
}


@UnoComponent({
    id: 'Content',
    label: 'App Content',
    getPreview: (config?: any, clbk?: any, buildChildren?: Function) => {
        return (<div>The Content Pane - PREVIEW</div>);
    },
    getDesign: (config?: any, clbk?: any, buildChildren?: Function) => {
        return (<div>The Content Pane - DESIGN</div>);
    },
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Layout.id,
})
export class Content extends UnoAppBaseComp {
    private tabbed = false;
    private tabKey = Common.getUniqueKey();
    private tabs: Array<Tab> = [];

    private previousPath: any;
    private previousQP: any;

    private _handler_user_session_cleared: any;
    private _handler_user_session_created: any;

    constructor(props: any) {
        super(props);
        this.state = { ...this.props };
        this.tabbed = (this.props.tabbed === true);
        // reset UI session timer
        localStorage.setItem(Common.LS_SESSION, '' + new Date().getTime());
    }

    canProfile(): boolean {
        return false;
    }

    componentDidMount(): void {
        super.componentDidMount();
        this._handler_user_session_cleared = EM.register(Common.Event.USER_SESSION_CLEARED,
            (data: any) => {
                const routeTo = data?.route || Common.Router.getQueryParam('path') || `/${this.getAppID()}`;
                Common.routeTo(routeTo);
            }
        );

        this._handler_user_session_created = EM.register(Common.Event.USER_SESSION_CREATED,
            (data: any) => {
                const routeTo = data?.route || Common.Router.getQueryParam('path') || `/${this.getAppID()}`;
                Common.routeTo(routeTo);
            }
        );
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        EM.unregister(this._handler_user_session_cleared);
        EM.unregister(this._handler_user_session_created);
    }

    buildComp() {
        // this.profiler.log(`Rendering the Content component`);
        return (
            <Route render={this.doRender} />
        );
    }

    doRender = () => {
        // process route
        let NewPathContent = <UC.Empty />;
        let newPath = window.location.pathname; // Common.Router.getPath(); // window.location.pathname;
        let newQP = window.location.search;

        if (newPath && newPath === '/') {
            newPath = '';
        }
        this.profiler.log('Get Content of new Path: ', newPath, this.previousPath);
        if (
            !this.previousPath
            || this.previousPath !== newPath
            || this.previousQP !== newQP
        ) {
            // this.profiler.log('Navigating away? ', newPath, this.previousPath);
            this.previousPath = newPath;
            this.previousQP = newQP;

            this.tabKey = Common.getUniqueKey(); // change it to force re-build

            NewPathContent = (<UC.RouteContent route={newPath} hostname={window.location.hostname} key={Common.getUniqueKey('route_content_')} />);
            if (this.tabbed === false) {
                this.tabs = [];
            }
            this.tabs.unshift({ name: newPath, comp: NewPathContent, active: true, });
        }

        return (
            <div className='app-content'>
                {
                    /*
                        this.buildBackAndTranslate()
                     */
                }
                <UC.TabbedPane tabs={this.tabs} noSingleTab={true} key={this.tabKey} />
            </div>
        );

    }

    handleAppSwitched = async (app: AppInfo) => {
        if (this.tabs && this.tabs.length > 1) {
            this.tabs.splice(1);
            this.setState({});
        }
    }

    buildBackAndTranslate() {
        return (
            <UC.VSection cols='2'>
                <UC.Section >
                    {/* <UC.WindowNav /> */}
                </UC.Section>
                <UC.Section styleClasses='right' styles={{ marginRight: '50px' }}>
                    {/* <UC.Translator /> */}
                </UC.Section>
            </UC.VSection>
        );
    }
}

/*
let previousPath: any = undefined;
let tabs: Array<any> = [];


class RouteElement extends React.Component<any, any>{

    render(): React.ReactNode {
        // process route
        let newPath = window.location.pathname;
        this.profiler.log(`Routed to path: `, newPath);
        if (newPath && newPath === '/') {
            newPath = '';
        }
        if (!previousPath || previousPath !== newPath) {
            previousPath = newPath;
            this.profiler.log(`Render route path: `, newPath);
            const NewPathContent = (<UC.RouteContent route={newPath} />);
            if (this.props.tabbed === false) {
                tabs = [];
            }
            tabs.unshift({ name: newPath, comp: NewPathContent, active: true, });
        }

        return (
            <UC.TabbedPane tabs={tabs} noSingleTab={true} key={Common.getUniqueKey()} />
        );
    }
}
*/