import 'aframe';
import 'aframe-particle-system-component';
import { Entity, Scene } from 'aframe-react';
import React from 'react';
import { Common } from '../../../@uno/api';
import { DesignerConstants, UnoComponent, UnoCoreBaseComp } from '../../../@uno/core';

declare var Sprintful: any;

const SprintfulType = {
    Inline: { id: 'inline', label: 'Embed Inline' },
    // Widget: { id: 'widget', label: 'Popup Widget' },
    Text: { id: 'text', label: 'Popup Text' },
}

@UnoComponent(
    {
        id: 'Sprintful',
        label: 'Sprintful',
        paletteable: true,
        props: [
            {
                groupID: 'General',
                id: 'url',
                label: 'URL',
            },
            {
                groupID: 'General',
                id: 'label',
                label: 'Label'
            },
            {
                groupID: 'General',
                id: 'type', label: 'Type', extras: {
                    options: Object.values(SprintfulType),
                },
                viewer: 'OptionViewer',
                editor: 'OptionSelector'
            },
        ],
        group: DesignerConstants.PaletteGroup.Integration.id,

        getPreview: (config?: any, clbk?: any, buildChildren?: Function) => {
            return (<div>The Sprintful - PREVIEW</div>);
        },
        getDesign: (config?: any, clbk?: any, buildChildren?: Function) => {
            return (<div>The Sprintful - DESIGN</div>);
        },
    }
)
export class SprintfulComp extends UnoCoreBaseComp {

    componentDidMount(): void {
        super.componentDidMount();

        Common.addScript(
            'https://app.sprintful.com/widget/v1.js',
            'text/javascript',
            false,
        );

        switch (this.getType()) {
            case SprintfulType.Text.id:
                Common.AppSettings.setLink('stylesheet', 'https://app.sprintful.com/widget/v1.css');
                break;
            case SprintfulType.Inline.id:
            default:
        }

    }

    buildComp(): JSX.Element {
        const type = this.getType();

        let Cal = undefined;
        switch (type) {
            case SprintfulType.Text.id:
                Cal = (
                    <a
                        className="sprintful-button"
                        href="#"
                        onClick={
                            () => {
                                if (Sprintful !== undefined) {
                                    Sprintful.showPopup({
                                        url: 'https://on.sprintful.com/ritesh-prasad-3081?hide-logo=false&hide-message=false&show-close=false',
                                        copyParentsQuery: 'false'
                                    });
                                }
                                return false;
                            }
                        }
                    >
                        {this.getLabel()}
                    </a>
                );
                break;
            case SprintfulType.Inline.id:
            default:
                Cal = (
                    <>
                        <div
                            className="sprintful-inline-widget"
                            data-copy-parents-query={false}
                            data-url={this.getURL()}
                            style={{ minWidth: '320px', height: '630px', backgroundColor: '#fff' }}
                        >
                        </div>
                    </>
                );
        }

        return (
            <div className={this.getStyleClasses()} >
                {Cal}
            </div>
        );
    }

    getURL() {
        return `${this.state.url || 'https://on.sprintful.com/ritesh-prasad-3081'}?hide-logo=true&hide-message=false&show-close=true`;
    }

    getLabel() {
        return this.state.label || 'Schedule time with me';
    }

    getType() {
        return this.state.type || 'inline';
    }

}