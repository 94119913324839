import { Common } from './common.service';
import { EM } from './event-mgmt.service';
import { RemoteService } from './remote.service';
import { SessionManager } from '../../@uno-app/service/session.service';

// Interfaces
export interface EntityProp {
    id: string,
    label?: string,
    description?: string,

    editor?: string,
    viewer?: string,

    dataType?: string,
    category?: string,
    multiplicity?: number, // undefined: 1, not a positive integer: unlimited, 
    extras?: any,
    defaultValue?: any,

    noNew?: boolean,
    noEdit?: boolean,
    noQV?: boolean,
    noSearch?: boolean,
    quickSearch?: boolean,
    readonly?: boolean,
    translate?: boolean,

    // control visibility
    hidden?: boolean;
    disabled?: boolean;

    validators?: Array<string | Function>;
    dependsOnProps?: Array<string>;

    groupID?: string;
    serialNo?: number;
}

export interface EntityPropGroup {
    id: string;
    label?: string;
    serialNo?: number;
    hidden?: boolean;
    active?: boolean;
}

export interface EntityCategory {
    id: string;
    label?: string;
    description?: string;
    props?: Array<EntityProp>;
    screens?: any;
    appID?: any;
    notInNavs?: boolean;
    // inherits?: Array<string>, // inherits other categories
    extras?: any;
    isCore?: boolean;

    propGroups?: Array<EntityPropGroup>;
    versioned?: boolean,
}

export const EntityConstants = {
    Status: {
        ACTIVE: 'active',
        INACTIVE: 'inactive',
    },

    Limit: {
        NumberOfPages: 1000,
        EntitiesPerPage: 5,
    },

    Attr: {
        NAME: 'name',
        ID: '_id',
        APP_ID: 'app_id',
        DESCRIPTION: 'description',
        CATEGORY: 'category',
        STATUS: 'status',
        COMMENT: 'comment',
        ATTRIBUTES: 'attributes',

        CREATED_BY_ID: 'createdByID',
        CREATED_ON: 'createdOn',

        LAST_MODIFIED_BY_ID: 'lastModifiedByID',
        LAST_MODIFIED_ON: 'lastModifiedOn',

        DELETED: 'deleted',
        DELETED_ON: 'deletedOn',
        DELETED_BY_ID: 'deletedByID',
    },

    PropType: {
        DEFAULT: '@default',

        STRING: '@string',
        EMAIL: '@email',
        JSON: '@json',
        STANDARD_FUNCTION: '@standard_function',
        FUNCTION: '@function',
        MULTILINE: '@multiline',
        HTML: '@html',
        PASSWORD: '@password',
        ENCRYPT: '@encrypt',
        NUMBER: '@number',
        DATE: '@date',
        TIME: '@time',
        DATETIME: '@datetime',
        BOOLEAN: '@boolean',
        COLOR: '@color',

        IMAGE: '@image',
        FILE: '@file',

        LAYOUT: '@layout',
        ENTITY: '@entity',
        ENTITY_INLINE: '@entity_inline',
        ARRAY: '@array',

        DERIVED: '@derived',
    },

    ListViewTypes: {
        Table: { id: 'eTable', label: 'Entity Table', },
        Grid: { id: 'eList', label: 'Entity List', },
        Carousel: { id: 'eCaraousel', label: 'Entity Carousel', },
        // Tree: { id: 'eTree', label: 'Tree', },
        Chips: { id: 'eChips', label: 'Entity Chips', },
    },
    ListEditTypes: {
        Table: { id: 'eTableEdit', label: 'Entity Table', },
        Grid: { id: 'eListEdit', label: 'Entity List', },
        Carousel: { id: 'eCaraouselEdit', label: 'Entity Carousel', },
        // Tree: { id: 'eTreeEdit', label: 'Tree', },
        Chips: { id: 'eChipsEdit', label: 'Entity Chips', },
    },

    Aggregation: {
        NONE: { id: undefined, label: 'None', },
        COUNT: { id: 'count', label: 'Count', },
        SUM: { id: 'sum', label: 'Sum', },
        AVERAGE: { id: 'average', label: 'Average', },
        MINIMUM: { id: 'minimum', label: 'Minimum', },
        MAXIMUM: { id: 'maximum', label: 'Maximum', },
    },

    Position: {
        TOP: 'top',
        LEFT: 'left',
        RIGHT: 'right',
        NONE: 'none',
    },

    Align: {
        LEFT: 'left',
        RIGHT: 'right',
        CENTER: 'center',
    },

    Layout: {
        FORM: 'form',
        ROW: 'row',
    },

    ROLE: {
        ANONYMOUS: 'ANONYMOUS',
        AUTHENTICATED: 'AUTHENTICATED',
        ADMININISTRATOR: 'ADMININISTRATOR',
        // STICKY: 'STICKY',
        // HOME: 'HOME',
    },

    Validator: {
        REQUIRED: 'required',
        UNIQUE: 'unique_val',
        SYSTEM_ID: 'system_id',
        // MIN_LENGTH: 'min_length',
        // MAX_LENGTH: 'max_length',
        // ONE_OF_THESE: 'one_of_these',
        // IS_ARRAY: 'is_array',
        // ARRAY_MIN_LENGTH: 'array_min_length',
        // ARRAY_MAX_LENGTH: 'array_max_length',
        JSON: 'json',
        EMAIL: 'email',
    },

    OpType: {
        CREATE: 'CREATE',
        READ: 'READ',
        UPDATE: 'UPDATE',
        REMOVE: 'REMOVE',
    },

    Event: {
        Create: {
            REQUESTED: 'Create_REQUESTED',
            COMPLETED: 'Create_COMPLETED',
            FAILED: 'Create_FAILED',
        },
        Read: {
            REQUESTED: 'Read_REQUESTED',
            COMPLETED: 'Read_COMPLETED',
            FAILED: 'Read_FAILED',
        },
        Update: {
            REQUESTED: 'Update_REQUESTED',
            COMPLETED: 'Update_COMPLETED',
            FAILED: 'Update_FAILED',
        },
        Delete: {
            REQUESTED: 'Delete_REQUESTED',
            COMPLETED: 'Delete_COMPLETED',
            FAILED: 'Delete_FAILED',
        },
        StatusChange: {
            REQUESTED: 'Delete_REQUESTED',
            COMPLETED: 'Delete_COMPLETED',
            FAILED: 'Delete_FAILED',
        },
    },

    inflate(input: any, options: Array<any> = [], ignoreMissing: boolean = false) {
        const choices: any = {}
        options.forEach(opt => {
            const v = EntityConstants.getAttributes(opt);
            Object.assign(choices, v);
        });

        // console.log(`Choices for placeholders - `, choices);
        let template = Common.checkType.Object(input) ? Common.stringify(input) : input;

        if (Common.checkType.String(template)) {
            let valStr = '' + template;
            const varPattern = new RegExp('{{([^}}]*)}}', 'g');
            const matches = valStr['matchAll'](varPattern);
            if (matches) {
                // console.log('Choices - ', choices,);
            }
            for (const match of matches) {
                // console.log(' :: match - ', match);
                if (match.length >= 2) {
                    const whole = match[0];
                    const exp = match[1];
                    const vars = exp.split('.');

                    let replacement = choices;
                    vars.forEach(v => { // iterate into the property depth.
                        if (replacement !== undefined) {
                            replacement = replacement[v];
                        }
                    });

                    // console.log(vars, ' - Choices - ', choices, replacement);
                    if (replacement !== undefined) {
                        valStr = valStr.replace(new RegExp(whole, 'g'), Common.checkType.Object(replacement) ? Common.stringify(replacement) : replacement);
                    } else if (ignoreMissing) {
                        valStr = valStr.replace(new RegExp(whole, 'g'), '');
                    }
                }
            }
            template = valStr;
        }
        // console.log('Substituted - ', input, template);
        return template;
    },

    isEntityType: (propType: any) => {
        if (propType) {
            const entityDataTypes = [
                EntityConstants.PropType.ENTITY,
                EntityConstants.PropType.ENTITY_INLINE,
            ];
            if (entityDataTypes.includes(propType)) {
                return true;
            }
        } else {
            return false;
        }
    },

    getCommonProps: () => {
        const cProps: Array<EntityProp> = [
            {
                id: EntityConstants.Attr.ID,
                label: 'UUID',
                disabled: true,
                hidden: true,
            },
            {
                id: EntityConstants.Attr.CREATED_BY_ID,
                label: 'Created By',
                dataType: EntityConstants.PropType.ENTITY,
                category: Common.CategoryID.Person,
                disabled: true,
                // hidden:true,
                noNew: true,
                noEdit: true,
                noQV: true,
                groupID: 'Change History',
            },
            {
                id: EntityConstants.Attr.CREATED_ON,
                label: 'Created On',
                dataType: EntityConstants.PropType.DATETIME,
                disabled: true,
                // hidden:true,
                noNew: true,
                noEdit: true,
                noQV: true,
                groupID: 'Change History',
            },
            {
                id: EntityConstants.Attr.LAST_MODIFIED_BY_ID,
                label: 'Modified By',
                dataType: EntityConstants.PropType.ENTITY,
                category: Common.CategoryID.Person,
                disabled: true,
                hidden: true,
                noNew: true,
                noEdit: true,
                noQV: true,
                groupID: 'Change History',
            },
            {
                id: EntityConstants.Attr.LAST_MODIFIED_ON,
                label: 'Last Modification On',
                dataType: EntityConstants.PropType.DATETIME,
                disabled: true,
                hidden: true,
                noNew: true,
                noEdit: true,
                noQV: true,
                groupID: 'Change History',
            },
            {
                id: EntityConstants.Attr.DELETED,
                label: 'Is Deleted?',
                dataType: EntityConstants.PropType.BOOLEAN,
                disabled: true,
                hidden: true,
                noNew: true,
                noEdit: true,
                noQV: true,
                groupID: 'Change History',
            }, {
                id: EntityConstants.Attr.DELETED_BY_ID,
                label: 'Deleted By',
                dataType: EntityConstants.PropType.ENTITY,
                category: Common.CategoryID.Person,
                disabled: true,
                // hidden:true,
                noNew: true,
                noEdit: true,
                noQV: true,
                groupID: 'Change History',
            },
            {
                id: EntityConstants.Attr.DELETED_ON,
                label: 'Deleted On',
                dataType: EntityConstants.PropType.DATETIME,
                disabled: true,
                // hidden:true,
                noNew: true,
                noEdit: true,
                noQV: true,
                groupID: 'Change History',
            },
        ];

        const commonProps: any = {};
        cProps.forEach(cp => {
            commonProps[cp.id] = cp;
        });

        return commonProps;
    },

    getUniqueKey: Common.getUniqueKey,

    getCategoryEventID: (category: string, eventType: string) => {
        return category + '_' + eventType;
    },

    getCategoryProp: (category: EntityCategory, id: any) => {
        return EntityConstants.getPropByID(category.props ? category.props : [], id);
    },

    getPropByID: (props: Array<EntityProp>, id: any) => {
        // console.log('find matching prop: ', id);
        const matchingProps = props?.filter(
            (p: EntityProp) => {
                if (p.id === id) {
                    return p;
                }
            }
        );
        // console.log(matchingProps);
        if (matchingProps && matchingProps.length > 0) {
            return matchingProps[0];
        }
        return undefined;
    },

    setValue: (p: EntityProp, val: any, entity: BaseEntity | undefined, truncate: boolean = true) => {
        if (p && entity) {
            if (p.multiplicity && p.multiplicity !== 1) {
                // console.log('Multiple values. Calling setAttribute on ', p.id);
                entity.setValue(p.id, val);
            } else {
                switch (p.dataType) {
                    case EntityConstants.PropType.DERIVED:
                        // do nothing.
                        break;
                    case EntityConstants.PropType.ENTITY_INLINE:
                    case EntityConstants.PropType.ENTITY:
                        // console.log('Set prop : ', p.id, ' : Value: ', val, truncate);
                        if (val && truncate) {
                            const truncatedEntity = EntityConstants.buildTruncatedEntity(val);
                            // console.log('Truncated Entity. Calling setAttribute on ', p.id);
                            entity.setValue(p.id, truncatedEntity);
                        } else {
                            // console.log('Entity. Calling setAttribute on ', p.id);
                            entity.setValue(p.id, val);
                        }
                        break;
                    default:
                        // console.log('Default. Calling setAttribute on ', p.id);
                        entity.setValue(p.id, val);
                        break;
                }
            }
        }
    },

    getValue: (p: EntityProp, entity?: BaseEntity, defaultValue?: any) => {
        let value: any = defaultValue;
        if (!entity || !p) {
            return value;
        }


        if (p.multiplicity && p.multiplicity !== 1) {
            value = entity.getValue(p.id);
            // console.log('Multiple values. Calling getAttribute on ', p.id, value, entity);
        } else {
            let baseProp: any = undefined;
            entity.getProps().forEach(
                eProp => {
                    if (eProp.id === p.id) {
                        baseProp = eProp;
                    }
                }
            )
            if (baseProp && baseProp.getter) {
                // console.log('Base prop. Calling GETTER on ', p.id);
                value = baseProp.getter.call(entity);
            } else {
                switch (p.dataType) {
                    case EntityConstants.PropType.ENTITY_INLINE:
                    case EntityConstants.PropType.ENTITY:
                        // console.log('Entity. Calling getAttributeValue on ', p.id);
                        const propEVal = entity.getValue(p.id);
                        // console.log('Prop: ', p.id, ' :: Value: ', propEVal);
                        if (propEVal) {
                            value = EntityConstants.build(propEVal);
                        }
                        break;
                    default:
                        // console.log('Default. Calling getAttributeValue on ', p.id);
                        value = entity.getValue(p.id);
                }
            }

        }
        return ((value !== undefined) ? value : defaultValue);
    },

    buildTruncatedEntity: (val: any, withName = true) => {
        if (val === undefined) {
            return undefined;
        }
        const valEntity = EntityConstants.build(val);
        // console.log('Given Value Entity: ', valEntity.getCategoryID());
        const truncatedEntity = EntityConstants.buildEmpty(valEntity.getCategoryID());
        truncatedEntity.setAppID(valEntity.getAppID());
        truncatedEntity.setID(valEntity.getID());
        if (withName) {
            truncatedEntity.setName(valEntity.getName());
        }
        // console.log('The truncated Entity - ', truncatedEntity);
        return truncatedEntity;
    },

    truncate: (val: any, withName = true) => {
        return EntityConstants.buildTruncatedEntity(val, withName);
    },

    build: (from?: any) => {
        from = Common.safeParse(from); // try to convert to Object
        if (from && !Common.checkType.Object(from)) {
            // throw Error(`Problem building an Entity from - ${from}`);
        }
        return new BaseEntity(undefined, from);
    },

    buildEmpty: (categoryID: any) => {
        return new BaseEntity(categoryID);
    },

    create: (categoryID: any, appID?: string, eID?: string) => {
        const entity = new BaseEntity(categoryID);
        if (appID) {
            entity.setAppID(appID);
        }
        if (eID) {
            entity.setID(eID);
        }
        return entity;
    },

    reload: async (entity: any) => {
        entity = Common.safeParse(entity);
        if (!Common.isEntity(entity)) {
            return undefined;
        } else {
            return EntityConstants.build(entity).reload();
        }
    },

    buildCriteria: (category: any, id?: string, name?: string, desc?: string, status?: string, attributes?: Array<Attribute>) => {
        // console.log('Attributes: ', attributes);
        const criteria: any = {};
        criteria[EntityConstants.Attr.CATEGORY] = category;
        if (id) {
            criteria[EntityConstants.Attr.ID] = id;
        }
        if (name) {
            criteria[EntityConstants.Attr.NAME] = { $regex: name, $options: '$i' };
        }
        if (desc) {
            criteria[EntityConstants.Attr.DESCRIPTION] = { $regex: desc, $options: '$i' };
        }
        if (status) {
            criteria[EntityConstants.Attr.STATUS] = status;
        }
        if (attributes) {
            attributes.forEach((attr: Attribute) => {
                let attrValueCond: any = {};
                const val = attr.getValue();
                if (val === undefined || val === null) {
                    attrValueCond = null;
                } else if (Array.isArray(val)) {
                    if (val.length === 1) {
                        attrValueCond = { $elemMatch: val[0] };
                    } else {
                        attrValueCond = { $in: val };
                    }
                } else if (Common.checkType.Object(val)) {
                    attrValueCond = val;
                } else if (Common.checkType.String(val) && 0 === val.trim().length) {
                    attrValueCond = { $ne: null };
                } else {
                    attrValueCond = val; // { $regex: val, $options: '$i' };
                }
                criteria[attr.getID()] = attrValueCond;
            });

        }
        return criteria;
    },

    getAttributes: (entity: BaseEntity) => {
        const compProps: any = {};
        if (entity) {
            entity = EntityConstants.build(entity);
            compProps['_id'] = entity.getID();
            // console.log('Building component props: ');
            entity.getAllAttributes().forEach(
                (attr: Attribute) => {
                    compProps[attr.getID()] = attr.getValue();
                }
            );

            /*
            entity.getProps().forEach((p: EntityProp) => {
                if (p.getter) {
                    compProps[p.id] = p.getter.call(entity);
                }
            });
            */
            // console.log('props: ', compProps);

        }
        return compProps;
    },

    getEntityService: (appID: any, categoryID: any) => {
        return new BaseEntityService(categoryID, appID);
    },

};


// Classes - Implementations
export class BaseEntityService {
    category: any = undefined;
    appID: any = undefined;

    constructor(category: any, appID?: any) {
        if (category) {
            if (Common.checkType.String(category)) {
                this.category = category;
            } else if (Common.checkType.Object(category)) {
                this.category = category.id;
            }
        }
        if (appID) {
            this.appID = appID;
        }
    }

    getActiveAppID() {
        if (!this.appID) {
            //throw new Error('Invalid App');
        }
        return this.appID;
    }

    getEventID(eventType: string) {
        return EntityConstants.getCategoryEventID(this.category, eventType);
    }

    findRecords(entity?: BaseEntity, category?: EntityCategory, options?: any, session: any = SessionManager.activeSession): Promise<Array<BaseEntity>> {
        // console.log('Find by Entity ', JSON.stringify(entity));
        const id = entity?.getID();
        const name = entity?.getValue(EntityConstants.Attr.NAME);
        const desc = entity?.getDescription();
        const status = entity?.getStatus();
        let attrs: Array<Attribute> = [];

        const addAttr = (pID: string) => {
            const attrib = new Attribute();
            attrib.setID(pID);
            attrib.setValue(entity?.getValue(pID));
            if (attrib && attrib.getValue()) {
                // console.log('Attribute : ', attrib);
                attrs.push(attrib);
            }
        }
        // console.log('Category Props : ', category?.props);
        if (category) {
            category.props?.forEach((prop: any) => {
                addAttr(prop.id);
            });
        } else if (entity) {
            Object.keys(entity).forEach(pID => {
                addAttr(pID);
            })
        }
        // console.log(`Find ${this.category} By :: ID: ${id}, Name: ${name}, Desc: ${desc}, Status: ${status}, Attributes: `, attrs);
        return this.findRecordsBy(id, name, desc, status, attrs, options, session);
    }

    findByID(id: any, session: any = SessionManager.activeSession): Promise<BaseEntity | undefined> {
        return new Promise<BaseEntity | undefined>(
            (resolve, reject) => {
                const testEntity = EntityConstants.buildEmpty(this.category);
                testEntity.setID(id);
                this.findRecords(testEntity,).then(
                    (records: Array<any>) => {
                        let rec: BaseEntity | undefined = undefined;
                        if (records && records.length > 0) {
                            rec = records[0];
                        }
                        resolve(rec);
                    }
                );
            }
        );
    }

    buildRecords = (resp: Response) => {
        return new Promise<Array<BaseEntity>>(
            (resolve, reject) => {
                resp.json().then(
                    (json: any) => {
                        let entities: Array<BaseEntity> = [];
                        const result = json.result;
                        if (result && result.length && result.length > 0) {
                            entities = result.map(
                                (record: any) => {
                                    return EntityConstants.build(record);
                                }
                            );
                        }
                        resolve(entities);
                    }
                ).catch(err => { reject(err) });
            }
        );

    }

    findRecordsBy(id?: string, name?: string, desc?: string, status?: string, attributes?: Array<Attribute>, options?: any, session: any = SessionManager.activeSession): Promise<Array<BaseEntity>> {
        return new Promise<Array<BaseEntity>>(
            (resolve, reject) => {
                this.findBy(id, name, desc, status, attributes, options, session).then(
                    (resp: Response) => {
                        this.buildRecords(resp).then(bRes => { resolve(bRes) });
                    }

                );
            }
        );
    }

    findBy(id?: string, name?: string, desc?: string, status?: string, attributes?: Array<Attribute>, options?: any, session: any = SessionManager.activeSession): Promise<Response> {
        const criteria = EntityConstants.buildCriteria(this.category, id, name, desc, status, attributes);
        return this.find(criteria, options, session);

    }

    findEntities(condition: any, options?: any, session: any = SessionManager.activeSession): Promise<Array<BaseEntity>> {
        return new Promise<Array<BaseEntity>>(
            (resolve, reject) => {
                this.find(condition, options, session).then(
                    (resp: Response) => {
                        this.buildRecords(resp).then(entities => {
                            resolve(entities);
                        }).catch(err => { reject(err) });
                    }
                ).catch(err => { reject(err) });
            }
        );

    }

    find(condition: any, options?: any, session: any = SessionManager.activeSession): Promise<Response> {
        const selector = { ...condition, category: this.category };
        this.setAppID(selector);
        // console.log('Finding : ', Common.stringify(selector));
        return RemoteService.post('entity/find', { selector: selector, session: session, options: options, appID: this.getAppID([selector]) });
    }

    setAppID(doc: any) {
        let appID = doc[EntityConstants.Attr.APP_ID];
        if (!appID) {
            appID = this.getActiveAppID();
            doc[EntityConstants.Attr.APP_ID] = appID;
        }
    }


    getAppID(docs: Array<any>) {
        return docs ? docs[0][EntityConstants.Attr.APP_ID] : undefined;
    }

    save(docs: any, session: any = SessionManager.activeSession): Promise<Response> {
        // console.log('Docs to save', docs);
        const docsWithCategory = Object.assign([], docs).map((doc: any) => {
            const docWithCat = { ...doc, category: this.category, };
            let id = docWithCat[EntityConstants.Attr.ID];
            if (!id) {
                id = this.category + '_' + Math.round(Math.random() * 999999999);
                docWithCat[EntityConstants.Attr.ID] = id;
            }
            this.setAppID(docWithCat);

            return docWithCat;
        });
        // console.log('To Save: ', docsWithCategory);
        return RemoteService.post('entity/save', { data: docsWithCategory, session: session, appID: this.getAppID(docsWithCategory) });
    }

    remove(conditions: Array<any>, session: any = SessionManager.activeSession): Promise<Response> {
        const conditionsWithCategory = conditions.map(cond => {
            const conditionWithCategory = { ...cond, category: this.category };
            this.setAppID(conditionWithCategory);
            return conditionWithCategory;
        });
        return RemoteService.post('entity/remove', { data: conditionsWithCategory, session: session, appID: this.getAppID(conditionsWithCategory) });
    }

}

class Attribute {
    private _id: any;
    private value: any = undefined;

    constructor(from?: any) {
        if (from) {
            Object.assign(this, from)
        }
    }

    setID(id: string) {
        this._id = id;
    }

    getID(): string {
        return this._id;
    }

    setValue(value: any) {
        this.value = value;
    }

    getValue(): any {
        return this.value;
    }

}

export class BaseEntity {

    private category: string = 'entity';
    private _id: any;

    constructor(categoryID?: string, from?: BaseEntity) {
        // super(from);
        if (from) {
            Object.assign(this, from);
        }

        if (categoryID) {
            this.setCategoryID(categoryID);
        } else if (from && from.category) {
            this.setCategoryID(from.category);
        }
    }

    async reload() {
        if (this.getID()) {
            const eService = EntityConstants.getEntityService(this.getAppID(), this.getCategoryID());
            return await eService.findByID(this.getID());
        } else {
            return undefined;
        }
    }


    protected setCategoryID(val: any) {
        this.category = val;
    }

    getCategoryID(): string {
        return this.category ? this.category : this.getValue(EntityConstants.Attr.CATEGORY);
    }

    getEventID(eventType: string) {
        return EntityConstants.getCategoryEventID(this.category, eventType);
    }

    getValue(id: string, defaultValue?: any): any {
        const attr = this.getAttribute(id);
        return (attr) ? attr.getValue() : defaultValue;

    }

    getAllAttributes(): Array<Attribute> {
        const obj: any = this;
        const attributes = Object.keys(obj).map(
            id => {
                const attr: any = this.getAttribute(id);
                return attr;
            }
        )
        return attributes;
    }

    getAttribute(id: string): Attribute | undefined {
        const obj: any = this;
        if (Object.keys(obj).includes(id)) {
            const attr = new Attribute();
            attr.setID(id);
            // attr.setName(id);
            attr.setValue(obj[id]);
            return attr;
        }
        return undefined;
    }

    setValue(id: string, value?: any,) {
        const obj: any = this;
        obj[id] = value;
    }

    public createID() {
        if (!this.getID()) {
            const id = this.getCategoryID()
                // + '_' + Math.floor(Math.random() * 98989)
                // + '_' + Math.floor(Math.random() * 98989)
                // + '_' + Math.floor(Math.random() * 98989)
                + '_' + Math.floor(Math.random() * 999999999);

            this.setID(id);
            // this.setCreatedOn(new Date().getTime());
        }
    }

    save(appID: any, session: any = SessionManager.activeSession): Promise<BaseEntity> {
        return new Promise<BaseEntity>(
            (resolve, reject) => {
                let opType: any = null;
                if (!this.getID()) {
                    opType = EntityConstants.OpType.CREATE;
                    this.createID();

                    // this.setCreatedByID(EntityConstants.getActivePerson()?.getID());
                    // this.setCreatedOn(new Date().getTime());
                } else {
                    opType = EntityConstants.OpType.UPDATE;

                    // this.setLastModifiedByID(EntityConstants.getActivePerson()?.getID());
                    // this.setLastModifiedOn(new Date().getTime());
                }

                const entity = this;
                if (!appID) {
                    appID = entity.getAppID();
                }
                const service = new BaseEntityService(entity.getCategoryID(), appID);
                service.save([entity], session).then((res: any) => {
                    if (res) {
                        switch (opType) {
                            case EntityConstants.OpType.CREATE:
                                EM.emit(service.getEventID(EntityConstants.Event.Create.COMPLETED), entity);
                                break;
                            case EntityConstants.OpType.UPDATE:
                                EM.emit(service.getEventID(EntityConstants.Event.Update.COMPLETED), entity);
                                break;
                        }
                        resolve(entity);
                    } else {
                        reject(entity);
                    }
                });
            }
        );
    }

    setAppID(value: any) {
        this.setValue(EntityConstants.Attr.APP_ID, value);
    }

    getAppID(): any {
        return this.getValue(EntityConstants.Attr.APP_ID);
    }

    setCreatedByID(value: any) {
        this.setValue(EntityConstants.Attr.CREATED_BY_ID, value);
    }

    getCreatedByID(): any {
        return this.getValue(EntityConstants.Attr.CREATED_BY_ID);
    }

    setCreatedOn(value: any) {
        this.setValue(EntityConstants.Attr.CREATED_ON, value);
    }

    getCreatedOn(): any {
        return this.getValue(EntityConstants.Attr.CREATED_ON);
    }

    setLastModifiedByID(value: any) {
        this.setValue(EntityConstants.Attr.LAST_MODIFIED_BY_ID, value);
    }

    getLastModifiedByID(): any {
        return this.getValue(EntityConstants.Attr.LAST_MODIFIED_BY_ID);
    }

    setLastModifiedOn(value: any) {
        this.setValue(EntityConstants.Attr.LAST_MODIFIED_ON, value);
    }

    getLastModifiedOn(): any {
        return this.getValue(EntityConstants.Attr.LAST_MODIFIED_ON);
    }

    setStatus(value: any) {
        this.setValue(EntityConstants.Attr.STATUS, value);
    }

    getStatus(): any {
        return this.getValue(EntityConstants.Attr.STATUS);
    }

    setID(id: string) {
        this._id = id;
    }

    getID(): string {
        return this._id ? this._id : this.getValue(EntityConstants.Attr.ID);
    }

    setName(name: string) {
        this.setValue(EntityConstants.Attr.NAME, name);
    }

    getName(): string {
        let name = this.getValue(EntityConstants.Attr.NAME);
        return name ? name : this.getID();
    }

    setDescription(desc: string | undefined) {
        this.setValue(EntityConstants.Attr.DESCRIPTION, desc);
    }

    getDescription(): string | undefined {
        return this.getValue(EntityConstants.Attr.DESCRIPTION);
    }

    setComment(comment: string) {
        this.setValue(EntityConstants.Attr.COMMENT, comment);
    }

    getComment(): string | undefined {
        return this.getValue(EntityConstants.Attr.COMMENT);
    }

    getProps(): Array<EntityProp> {
        return [
            {
                id: EntityConstants.Attr.NAME,
                label: 'Name',
                dataType: EntityConstants.PropType.STRING,
                validators: [EntityConstants.Validator.REQUIRED],
                translate: true,
            },
            {
                id: EntityConstants.Attr.DESCRIPTION,
                label: 'Description',
                dataType: EntityConstants.PropType.MULTILINE,
                noQV: true,
                translate: true,
            },
        ];
    }

    toString() {
        return this.getName() ? this.getName() : this.getID()
    }

}

class ValidatorRegistryImpl {
    validators: any = {};

    constructor() {
        this.register(EntityConstants.Validator.REQUIRED, this.validatorREQUIRED);
        this.register(EntityConstants.Validator.UNIQUE, this.validatorUNIQUE);
        this.register(EntityConstants.Validator.SYSTEM_ID, this.validatorSYSTEM_ID);
        this.register(EntityConstants.Validator.JSON, this.validatorJSON);
        this.register(EntityConstants.Validator.EMAIL, this.validatorEMAIL);
    }

    register(id: string, validtor: Function) {
        this.validators[id] = validtor;
    }

    find(id: string) {
        return this.validators[id];
    }

    async validate(value: any, validators: string | Array<string | Function>, params?: any, propDef?: EntityProp, entity?: BaseEntity, appID?: string, session: any = SessionManager.activeSession) {
        if (typeof validators === Common.Datatype.String) {
            validators = [validators.toString()];
        }
        for (let i = 0; i < validators.length; i++) {
            let validator = validators[i];
            let validatorFn: any = undefined;
            if (typeof validator === Common.Datatype.Function) {
                validatorFn = validator
            } else {
                validatorFn = this.find(validator.toString());
            }
            if (typeof validatorFn === Common.Datatype.Function) {
                const errors: any = await validatorFn(value, params, propDef, entity, appID, session);
                if (errors) {
                    return errors;
                }
            }
        }
        return undefined;
    }

    // validator functions

    validatorREQUIRED = async (value: any, params?: any, propDef?: EntityProp, entity?: BaseEntity, appID?: string, session: any = SessionManager.activeSession) => {
        // value = Common.safeParse(value);
        const errMsg = 'This is REQUIRED';
        if (value === undefined || value === null || value.length === 0) {
            return errMsg;
        } else if (Common.checkType.String(value)) {
            value = value.trim();
            if (value.length === 0) {
                return errMsg;
            }
        }
    }

    validatorUNIQUE = async (value: any, params?: any, propDef?: EntityProp, entity?: BaseEntity, appID?: string, session: any = SessionManager.activeSession) => {
        // value = Common.safeParse(value);
        const errMsg = 'This is NOT Unique';
        if (value === undefined || value === null || value.length === 0) {
            // return errMsg;
            return;
        } else {
            if (Common.checkType.String(value)) {
                value = value.trim();
            }
            entity = EntityConstants.build(entity);
            appID = appID || entity.getAppID() || session?.appID;
            const catID = entity.getCategoryID();
            if (catID && propDef) {
                const filters: Array<any> = [
                    { propID: 'category', value: catID, operator: 'EQUAL' },
                ];

                if (Common.checkType.String(value)) {
                    filters.push(
                        { propID: propDef.id, value: `^${value}$`, operator: 'LIKE' },
                    );
                } else {
                    filters.push(
                        { propID: propDef.id, value: value, operator: 'EQUAL' },
                    );
                }
                // console.log('Unique Validator Filters: ', filters);

                const records = await RemoteService.getData(
                    await RemoteService.post('filter/result',
                        {
                            appID: appID,
                            conditions: filters,
                            options: {},
                            category: undefined,
                            session: session,
                        }
                    )
                );

                // console.log('Checking for unique: ', appID, filters, records);
                if (records?.length > 0) {
                    for (let rec of records) {
                        if (rec._id !== entity.getID()) {
                            // console.log('Unique Validation Failed: ', errMsg, filters);
                            return errMsg;
                        }
                    }
                    // return errMsg;
                }
            }

        }
    }
    validatorSYSTEM_ID = (value: any, params?: any, propDef?: EntityProp, entity?: BaseEntity, session: any = SessionManager.activeSession) => {
        const allowedChars = 'a-zA-Z0-9_';
        const errMsg = 'Value is not allowed to contain anything but these : ' + allowedChars;
        if (value) {
            const extras = value.match(new RegExp('[^' + allowedChars + ']', 'g'));
            // console.log('Extras - ', extras);
            if (extras && extras.length > 0) {
                return errMsg;
            }
        }
    }

    validatorJSON = (value: any, params?: any, propDef?: EntityProp, entity?: BaseEntity, appID?: string, session: any = SessionManager.activeSession) => {
        const errMsg = 'Value must be in JSON format';
        // console.log('Apply JSON Validator on - ', value);
        if (value) {
            try {
                Common.parse(value);
            } catch (e) {
                console.log('Failed JSON Validator on - ', value, e);
                return errMsg;
            }
        }
    }

    validatorEMAIL = (value: any, params?: any, propDef?: EntityProp, entity?: BaseEntity, appID?: string, session: any = SessionManager.activeSession) => {
        const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
        const errMsg = `${value} is not in proper email format `;
        if (value) {
            const matches = value.match(new RegExp(emailPattern, 'g'));
            // console.log('Emails - ', matches);
            if (matches && matches.length > 0) {
                return;
            } else {
                return errMsg;
            }
        }
    }
}

export const ValidatorRegistry = new ValidatorRegistryImpl();