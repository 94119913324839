import { UnoComponent } from '../../../@uno/core';
import layout from './../layouts/plugin-config-view-layout.json';
import { EntityView } from '../../../@uno-entity/components/entity-view.comp';

// PluginConfig - VIEW
const ID = 'UnoPluginConfigView';
@UnoComponent({
    id: ID,
    label: 'Plugin Configuration - Viewer'
})
export class UnoPluginConfigView extends EntityView {

    constructor(props: any) {
        super(props);
        this.state = {
            ...this.state,
            layout: layout,
            otherProps: { ...this.state.otherProps, noActions: false, },
        }
        // console.log('Plugin Config View - Layout: ', layout);
    }

    canProfile(): boolean {
        return false;
    }
}