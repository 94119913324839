import React from 'react';
import { CSSProperties } from 'react';
import { EM, EntityConstants } from '../../api';
import { UC, UnoComponent, Common, DesignerConstants } from '../../core';
import { UnoCoreBaseComp } from './uno-core.base.comp';
import { Tab } from 'react-tabs';

export interface Tab {
    name: string,
    comp?: any,
    active?: boolean,
    hidden?: boolean,
    id?: string,
}

const TabPosition = {
    TOP: 'top',
    BOTTOM: 'bottom',
    LEFT: 'left',
    RIGHT: 'right',
}

const STYLE_ACTIVE_TAB = 'active-tab';

const Horizontal = 'tab-horizontal', Vertical = 'tab-vertical';

@UnoComponent({
    id: 'TabbedPane',
    label: 'Tabbed Pane',
    props: [
        {
            groupID: 'Config',
            id: 'tabs',
            label: 'Tab Info',
            dataType: EntityConstants.PropType.JSON,
        },
        {
            groupID: 'Config',
            id: 'position',
            label: 'Position',
            editor: 'OptionSelector',
            viewer: 'OptionViewer',
            extras: {
                options: [
                    { id: TabPosition.TOP, isDefault: true },
                    { id: TabPosition.LEFT, },
                    { id: TabPosition.BOTTOM, },
                    { id: TabPosition.RIGHT, },
                ]
            }
        },
        {
            groupID: 'Style',
            id: 'tabNavClasses',
            label: 'Tab Nav Style Classes',
            hidden: true,
        },
        {
            groupID: 'Style',
            id: 'tabNavStyles',
            label: 'Tab Nav Styles',
            dataType: EntityConstants.PropType.JSON,
            hidden: true,
        },
    ],
    isContainer: true,
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Frequent.id,
})
export class TabbedPane extends UnoCoreBaseComp {
    private paneID = Common.getUniqueKey('tabbed_pane_');
    private activeIndex = -1;

    constructor(props: any) {
        super(props);
        this.state = { position: TabPosition.TOP, ...this.state };
    }

    buildComp() {
        const tabs = this.getTabs();
        if (!tabs || tabs.length === 0) {
            return <UC.Empty />;
        }
        // console.log(`Active Tab Index - `, this.activeIndex);
        this.setActiveTabIndex(tabs);
        // find active tab contet
        let activeTab: any = tabs[this.activeIndex]?.comp;
        // build tab links
        let tabNavs: any = this.buildTabLinks(tabs);
        const after = (this.state.position === TabPosition.BOTTOM);

        const tabContentStyles = this.getTabStyles();
        if (this.state.noSingleTab || this.state.noBorder) {
            tabContentStyles.border = 'none';
        }

        let activeTabView: any = (
            <div className={`tabcontent ${this.getStyleClasses()}`} style={{ ...tabContentStyles, ...this.getStyles() }} >
                {activeTab}
            </div>
        );

        if (!this.isLiveMode()) {
            activeTabView = tabs.map(
                (t: Tab, index: number) => {
                    return (
                        <div className='tabcontent' style={tabContentStyles} key={Common.getUniqueKey('tab_content_')}>
                            <div
                                className={`right tab tab-nav ${this.getStyleClasses()}`}
                                style={{
                                    border: '1px dashed',
                                    marginTop: '15px',
                                    padding: '2px 20px',
                                    ...this.getStyles(),
                                }}
                            >
                                {t.name}
                            </div>
                            {t.comp}
                        </div>
                    );
                }
            );
        }

        return (
            <>
                {(!after) ? tabNavs : undefined}
                {activeTabView}
                {(after) ? tabNavs : undefined}
            </>
        );
    }

    buildTabLinks(tabs: Array<Tab>) {
        const orientation = this.getOrientation();
        const tabLinks = tabs.map((t: Tab, index: number) => {
            if (t.hidden) {
                return undefined;
            }

            if (!t.id) {
                t.id = Common.getUniqueKey(t.name);;
            }

            const activeTab = t.active ? STYLE_ACTIVE_TAB : '';
            return (
                <a
                    id={this.getTabKey(t)}
                    href='#'
                    onClick={(event: any) => {
                        this.activateNav(t);
                    }}
                >
                    <div
                        // key={this.getTabKey(t)}
                        className={`${orientation} tab-nav ${activeTab}`}
                    /*
                    onClick={(event: any) => {
                        this.activateNav(t);
                    }}
                        */
                    >
                        {t.name}
                    </div>
                </a>
            );
        });

        if (this.state.noSingleTab && tabLinks?.length === 1) {
            return <UC.Empty />;
        } else if (this.state.noSingleTab === false && tabLinks?.length <= 0) {
            return <UC.Empty />;
        } else {
            return (
                <div className={`tab`} style={this.getTabStyles()}>
                    {tabLinks}
                </div>
            )
        }
    }

    setActiveTabIndex = (tabs: Array<Tab>) => {
        tabs.forEach(
            (t: Tab, index: number) => {
                if (t.active) {
                    this.activeIndex = index;
                }
            }
        );

        // if no tab marked active, set the first tabe active.
        if (this.activeIndex < 0 && tabs.length > 0) {
            this.activeIndex = 0;
        }

        tabs.forEach(
            (t: Tab, index: number) => {
                if (this.activeIndex === index) {
                    t.active = true;
                } else {
                    t.active = false;
                }
            }
        );

        EM.emit('TABBED_PANE_INDEX_SET',
            {
                compID: this.state.compConfig?.id,
                activeIndex: this.activeIndex,
                activeTab: tabs[this.activeIndex],
                tabs: tabs,
            }
        );
    }

    activateNav(activeTab: Tab) {
        let newIndex = -1;
        const tabs = this.getTabs();
        // console.log(`Active Tab ID: `, this.activeIndex, activeTab?.name, tabs);
        tabs?.forEach((t: Tab, index: number) => {
            const clsList = document.getElementById(this.getTabKey(t))?.classList;
            if (t.id === activeTab?.id || t.name === activeTab?.name) {
                clsList?.add(STYLE_ACTIVE_TAB);
                t.active = true;
                newIndex = index;
            } else {
                clsList?.remove(STYLE_ACTIVE_TAB);
                t.active = false;
            }
            // console.log(t.id, clsList);
            // this.setState({}); // refresh
        });

        if (newIndex >= 0) {
            this.activeIndex = newIndex;
            // console.log(`Changed Tab ID: `, this.activeIndex, tabs[this.activeIndex]);
            this.reRender({ activeIndex: newIndex });
        }
    }

    getOrientation() {
        let orientation = Horizontal;
        switch (this.state.position) {
            case TabPosition.LEFT:
            case TabPosition.RIGHT:
                orientation = Vertical;
                break;
            case TabPosition.TOP:
            case TabPosition.BOTTOM:
            default:
                orientation = Horizontal;
                break;
        }
        return orientation;
    }

    getTabStyles() {
        const styles: CSSProperties = {};

        switch (this.state.position) {
            case TabPosition.RIGHT:
                // styles.display = 'inline-block';
                styles.float = 'right';
                break;
            case TabPosition.LEFT:
                //styles.display = 'inline-block';
                styles.float = 'left';
                break;
            case TabPosition.TOP:
            case TabPosition.BOTTOM:
            default:
                // styles.display = 'block';
                break;
        }
        return styles;
    }

    getTabs() {
        let children: any = this.state.children;
        if (!children) {
            children = [];
        }
        // let linkedChild = 0;
        let tabs = this.state.tabs;
        if (tabs) {
            tabs = Common.safeParse(tabs);
            // console.log('Given Tabs: ', tabs);
        }
        const tabConfs: Array<any> = tabs?.map((t: any, index: number) => {
            if (!t.comp && children.length > index) {
                t.comp = children[index];
            }
            return t;
        });

        return tabConfs;
    }

    getTabKey = (tab: Tab) => {
        return `${this.paneID}-${tab.id ? tab.id : tab.name}`;
    }

    getTabNavStyleClasses() {
        return this.state.tabNavClasses || '';
    }

    getTabNavStyles() {
        return Common.safeParse(this.state.tabNavStyles);
    }

}