import React, { CSSProperties } from 'react';
import { BaseEntity, EM, EntityConstants, Router, ValidatorRegistry } from '../../@uno/api';
import { EntityCategory, EntityProp, EntityPropGroup } from '../../@uno/api/entity.service';
import { Common, DesignerConstants, Images, UC, UnoCompEvents, UnoComponent } from '../../@uno/core';
import { ENTITY_COMP_PROPS, EntityBaseComp } from './entity-base.comp';

// ENTITY - NEW, EDIT
export const ENTIY_EDIT_COMP_PROPS: Array<EntityProp> = [
    { groupID: 'Entity', id: 'noActions', label: 'Do not show Entity Actions', dataType: EntityConstants.PropType.BOOLEAN },
    { groupID: 'Entity', id: 'entityID', label: 'Entity ID', },
    { groupID: 'Others', id: 'invalidInputs', label: 'Invalid Inputs Warning', },
    { groupID: 'Others', id: 'hideSave', label: 'Hide Save?', dataType: EntityConstants.PropType.BOOLEAN, },
    { groupID: 'Others', id: 'hideCancel', label: 'Hide Cancel?', dataType: EntityConstants.PropType.BOOLEAN, },
];

@UnoComponent({
    id: 'EntityEdit',
    label: 'Entity Editor',
    paletteable: true,
    props: [
        ...ENTIY_EDIT_COMP_PROPS,
        ...ENTITY_COMP_PROPS,
    ],
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    group: DesignerConstants.PaletteGroup.Entity.id,
})
export class EntityEdit extends EntityBaseComp {
    protected _id = Common.getUniqueKey('entity_');

    //editing = false;
    cancelTitle = 'Cancel';
    saveTitle = 'Save';
    viewTitle = 'View';
    validationErrors: any = {};
    editableProps: any = {};

    lastChangedProp: EntityProp | undefined = undefined;

    /*
    constructor(props: any) {
        super(props);
        if (this.getEntityId()) {
            this.editing = true;
        }
    }
    */

    canProfile(): boolean {
        return false;
    }

    async handle_CategoryLoaded() {
        // await super.handle_CategoryLoaded();
        this.profiler.log(`Category ${this.getCategoryID()} loaded. Entity = `, this.getEntity(), this.getCategory());
        if (this.getEntity()) { // first priority to 'entity' property
            // this.setState({ entity: EntityConstants.build(this.getEntity()) });
            this.setEntity(EntityConstants.build(this.getEntity()), false);
            // this.sendTrigger(this.Event.Action.getRequested(), undefined, this.handleActionRequestFailed);
        } else if (this.getCategoryID()) { // next priority to 'category - entityID'
            const extras = this.getExtras();
            if (extras && extras.inline) {
                const entity = EntityConstants.buildEmpty(this.getCategoryID());
                entity.setAppID(this.getAppID());
                entity.setID(this.getEntityId());
                if (!entity.getID()) {
                    // entity.createID();
                }
                this.profiler.log(`Created Entity: `, entity);
                // this.setState({ entity: entity });
                this.setEntity(entity, false);
                // inline edit. Do not init via url parsing
                // this.sendTrigger(this.Event.Action.getRequested(), undefined, this.handleActionRequestFailed, entity);
            } else {
                this.initEntity();
            }
        }
    }

    showUUID() {
        if (this.getAction() === 'create') {
            return false;
        } else {
            return (this.state.showUUID === true) || (this.getOtherProps()?.canViewUUID === true) || (this.getOtherProps()?.showUUID === true);
            // return (this.state.showUUID !== undefined) ? this.state.showUUID : (this.getOtherProps()?.canViewUUID !== undefined) ? this.getOtherProps()?.canViewUUID : false;
        }
    }

    buildContent() {
        this.identifyEditableProps();
        // this.profiler.log('Build Content : ', this.getAction(), this.getCategoryID());
        const showUUID = this.showUUID()
        return (
            <div className={`entity-view ${this.getStyleClasses()}`} style={{ ...this.getOtherProps()?.styles, ...this.getStyles(), }}>
                {this.buildRedirect()}
                {
                    /*
                        <div style={{ float: 'right' }} >
                            <UC.VersionComp
                                entity={this.getEntity()}
                                category={this.getCategory()}
                            />
                        </div>
                        */
                }

                {this.buildPropLayout()}
                <UC.Section
                    styleClasses='right'
                    styles={{
                        fontSize: 'xx-small',
                        display: showUUID ? 'block' : 'none',
                    }}

                >
                    <i>_uuid_ :&nbsp; {this.getEntity()?.getID()}</i>
                </UC.Section>
            </div>
        );
    }

    buildPropLayout() {
        const layout = this.getPropLayout();
        switch (layout) {
            case EntityConstants.Layout.ROW:
                return this.buildRow();
            case EntityConstants.Layout.FORM:
            default:
                return this.buildForm();
        }
    }

    buildForm() {
        const layout = this.buildDesigner();
        return (
            <>
                {(this.setActionsAtTop() === true) ? this.buildActionButtons() : <UC.Empty />}
                {this.buildValidationErrors()}
                <span id={this.printViewID}>
                    {layout ? layout :
                        (
                            <>
                                {this.buildTitle()}
                                {this.buildEntityProps()}
                            </>
                        )
                    }
                </span>
                {this.buildExtras()}
                {(this.setActionsAtTop() === false) ? (<>{this.buildActionButtons()} <p>{/** An Empty para at the bottom for spacing */}</p></>) : <UC.Empty />}
            </>
        );
    }

    buildRow(eProps = this.getEditProps(this.getCategory())) {
        const cellStyle: CSSProperties = { border: '1px dashed snow', padding: '5px', borderRadius: '5px', };
        const cells: Array<any> = [];
        cells.push((
            <td className='prop-cell' style={cellStyle} >
                {this.buildActionButtons()}
                {this.buildValidationErrors()}
            </td>
        ));

        this.buildEntityProps(eProps)?.forEach(pv => {
            cells.push(<td className='prop-cell' style={cellStyle} >{pv}</td>);
        });
        return (
            <tr>
                {cells}
            </tr>
        );
    }

    initEntity() {
        if (!this.getAction()) {
            return;
        }

        this.profiler.log('Loading Entity - ', this.getEntityId(), this.getCategoryID(), this.getEntity());
        if (this.getEntityId()) {
            this.profiler.log('Loading Entity by ID: ', this.getEntityId());
            this.loadEntity(
                this.getEntityId(),
                (entity: BaseEntity) => { this.sendTrigger(this.Event.Action.getRequested(), undefined, this.handleActionRequestFailed, entity) }
            );
        } else {
            const newEntity = EntityConstants.create(this.getCategoryID(), this.getAppID());
            this.reRender({ entity: newEntity, }, () => {
                this.profiler.log('New Entity Initialized: ', newEntity);
                this.sendTrigger(this.Event.Action.getRequested(), undefined, this.handleActionRequestFailed, newEntity);
            });
        }
    }

    getPropLayout() {
        let layout = EntityConstants.Layout.FORM;
        if (this.getOtherProps()?.propLayout) {
            layout = this.getOtherProps()?.propLayout;
        }
        return layout;
    }

    getAction(): string {
        return (this.state.action) ? this.state.action : (this.isEditing() ? Router.CatAction.EDIT : Router.CatAction.CREATE);
    }

    isEditing() {
        return this.getEntityId();
    }

    validateBeforeSave = async (entity: BaseEntity = this.getEntity()) => {
        this.validationErrors = {}; // clean validation errors.
        // this.profiler.log('Validating before save: ', entity);
        let prop: any;
        for (prop of Object.values(this.editableProps)) {
            await this.validatePropValue(prop, entity);
        }
        if (Object.values(this.validationErrors).length > 0) {
            console.log('Entity Save Validation failed', this.validationErrors, this.getCategory());
            return false;
        } else {
            const vbs = this.getOtherProps()?.validateBeforeSave || this.getExtras()?.validateBeforeSave;
            if (vbs && Common.checkType.Function(vbs)) {
                return await vbs(entity);
            } else {
                return true;
            }
        }
    }

    validateAndSave = async () => {
        if (await this.validateBeforeSave(this.getEntity())) {
            this.showSavingMsg();
            this.sendTrigger(this.Event.Save.getRequested(), this.handleOnSave);
        } else {
            Common.showError(this.state.invalidInputs || 'Invalid inputs found.');
        }
    }

    handleOnSave = (result: any) => {
        if (result) {
            if (result.error) {
                console.log('Entity Save Can not proceed', result);
                this.showMessage(result.error, true);
            } else {
                let entity = EntityConstants.build(result.entity);
                if (entity.getCategoryID() !== this.getCategoryID()) {
                    entity = Object.assign(EntityConstants.buildEmpty(this.getCategoryID()), entity);
                }
                this.saveEntity(entity, result);
            }
        }
    };

    validatePropValue = async (prop: any, entity: BaseEntity = this.getEntity()) => {
        if (prop.validators) {
            const value = this.getPropValue(prop, entity);
            const errors = await ValidatorRegistry.validate(value, prop.validators, undefined, prop, entity, this.getAppID(), this.getSession());
            if (errors) {
                const msg = { label: prop.label, error: errors };
                // this.profiler.log(msg);
                this.validationErrors[prop.id] = msg;
                this.reRender({});
            } else if (this.validationErrors[prop.id]) { // check to clear error
                delete this.validationErrors[prop.id];
                this.reRender({});
            }
        }
    }

    showSavingMsg() {
        const category = this.getCategory();
        const eLabel = this.getEntity()?.getName() || '';
        const saveMsg = `${eLabel} ${category?.label || category?.id} ${this.getAction().toUpperCase()} is being completed.`;
        // Common.showMessage(saveMsg, true, 300);
        Common.showDialog({ id: this._id, content: saveMsg });
    }

    hideSavingMsg() {
        // Common.hideMessage();
        Common.hideDialog(undefined, this._id);
    }

    showMessage(msg: string, isError: boolean = false) {
        this.hideSavingMsg();
        if (isError) {
            Common.showError(msg);
        } else {
            Common.showMessage(msg);
        }
    }

    canSaveEntity = () => {
        let canSave = this.getOtherProps()?.canSave;
        if (canSave === undefined) {
            canSave = (this.state.hideSave !== true);
        }
        return (canSave !== undefined) ? canSave : true;
    }

    canCancelEdit = () => {
        let canCancel = this.getOtherProps()?.canCancel;
        if (canCancel === undefined) {
            canCancel = (this.state.hideCancel !== true);
        }
        return (canCancel !== undefined) ? canCancel : true;
    }

    handleOnCancelSave = () => {
        if (this.canCancelEdit()) {
            const onSaveCancel = this.getOtherProps()?.onSaveCancel;
            if (onSaveCancel) {
                onSaveCancel(this.getEntity());
            } else {
                window.history.back();
            }
        }
    }

    handleOnView = () => {
        alert('Navigate to View');
    }

    saveEntity(entity: BaseEntity, result?: any) {
        if (!entity.getAppID()) {
            entity.setAppID(this.getAppID());
        }
        // this.profiler.log('Entity to save: ', this.getEntity());
        const otherProps = this.getOtherProps();
        if (otherProps?.onInlineSave) {
            // this.profiler.log(`Saving Inline.`);
            this.hideSavingMsg();
            otherProps.onInlineSave(entity, result);
        } else {
            // this.showSavingMsg()
            entity.save(this.getAppID(), this.getSession())
                .then(
                    (savedEntity: any) => {
                        entity = EntityConstants.build(savedEntity);
                        // this.profiler.log(`Entity saved: `, entity);
                        this.sendTrigger(this.Event.Save.getCompleted(),
                            (res: any) => {
                                this.hideSavingMsg();
                                if (otherProps?.onSaveComplete) {
                                    otherProps.onSaveComplete(entity);
                                } else {
                                    // alert('Saved: ' + entity.getName());
                                    // this.editing = true;
                                    // set entity.
                                    this.setState({ entity: entity });
                                    this.sendTrigger(
                                        this.Event.Action.getCompleted(),
                                        async () => {
                                            this.setRedirectTo(this.getPostSaveRedirect(entity));
                                        },
                                        undefined,
                                        entity,
                                    );
                                }
                            },
                            undefined,
                            entity
                        );
                    }
                ).catch(() => {
                    this.sendTrigger(this.Event.Save.getFailed(),);
                }).finally(() => {
                });
            ;
        }
    }

    getPostSaveRedirect(entity: BaseEntity) {
        return this.getFullViewRoute(entity.getID())?.to;
    }

    buildEntityProps(props: Array<EntityProp> = this.getEditProps(this.getCategory())) {
        // initially sort the props
        props?.sort(
            (aProp, bProp) => {
                if (aProp?.serialNo !== undefined && bProp?.serialNo !== undefined) {
                    return (aProp.serialNo > bProp.serialNo) ? 1 : -1;
                }
                return 0;
            }
        );

        // Now start building the comps for each prop.

        const UNGROUPED = 'UNGROUPED';

        let catPropGroups: Array<EntityPropGroup> = [];
        const category: EntityCategory = this.getCategory();
        const cpg = category?.propGroups;
        if (category && cpg && cpg.length > 0) {
            catPropGroups = catPropGroups.concat(cpg);
            // this.profiler.log('Property Groups: ', catPropGroups, category, props);
        }

        const isNewGroup = (groupID: string) => {
            // groupID = groupID.trim().toUpperCase();
            // this.profiler.log('Checking if a New Group ID: ', groupID, catPropGroups);
            if (groupID === UNGROUPED) {
                return false;
            } else {
                for (let group of catPropGroups) {
                    // this.profiler.log(groupID, group, group.id, (group.id === groupID));
                    if (groupID === group.id) {
                        return false;
                    }
                }
            }
            // this.profiler.log('New Group ID: ', groupID, catPropGroups);
            return true;
        }

        // this.profiler.log('Entity Category Groups: ', catGroupIDs, category);

        const propGroups: any = { UNGROUPED: [] };

        const propsToEdit: Array<any> = [];

        for (let prop of props) {
            // this.profiler.log(`${this.getInitEntityEventID()} - Category:${this.getCategory().id}, Prop: ${p.id}`);
            const propComp = this.buildEntityProp(prop);
            if (propComp) {
                const pGroupID = (prop.groupID ? prop.groupID : UNGROUPED); // (p.groupID && catGroupIDs?.includes(p.groupID)) ? p.groupID : UNGROUPED;
                if (isNewGroup(pGroupID)) {
                    catPropGroups.push({ id: pGroupID, label: pGroupID });
                    // this.profiler.log('Added new Cat Group: ', catPropGroups);
                }

                if (!propGroups[pGroupID]) { // initialize if not existing
                    propGroups[pGroupID] = [];
                }

                propGroups[pGroupID].push({ prop: prop, comp: propComp });
            }
        }

        // sort props in each group.
        Object.values(propGroups).forEach(
            (propComps: any) => {
                if (Array.isArray(propComps)) {
                    propComps.sort(
                        (a, b) => {
                            const aProp: EntityProp = a.prop;
                            const bProp: EntityProp = b.prop;
                            if (aProp?.serialNo !== undefined && bProp?.serialNo !== undefined) {
                                return (aProp.serialNo > bProp.serialNo) ? 1 : -1;
                            }
                            return 0;
                        }
                    );
                }
            }
        );

        // sort category props groups:
        catPropGroups.sort(
            (aCatPG, bCatPG) => {
                if (aCatPG?.serialNo !== undefined && bCatPG?.serialNo !== undefined) {
                    return (aCatPG.serialNo > bCatPG.serialNo) ? 1 : -1;
                }
                return 0;
            }
        );

        const buildPropGroup = (propComps: Array<any>) => {
            return (
                <div key={Common.getUniqueKey()}>
                    {
                        propComps.map(
                            pc => {
                                return <span key={Common.getUniqueKey()}>{pc.comp}</span>;
                            }
                        )
                    }
                </div>
            )
        }

        // build the ungrouped props
        propsToEdit.push(buildPropGroup(propGroups[UNGROUPED]));

        // build the Tabbed Category Groups.
        const catGroupTabs: Array<any> = [];
        catPropGroups.forEach(catPG => {
            const props = propGroups[catPG.id];
            // this.profiler.log('Prop Group: ', catPG.id, props);
            if (props?.length > 0) {
                catGroupTabs.push(
                    {
                        ...catPG,
                        name: catPG.label ? catPG.label : catPG.id,
                        comp: buildPropGroup(props),
                    }
                );
            }
        });
        propsToEdit.push(<UC.TabbedPane tabs={catGroupTabs} key={this.getUniqueKey()} noSingleTab={true} />);

        // return the list.
        return propsToEdit;
    }

    identifyEditableProps(props: EntityProp[] = this.getEditProps(this.getCategory())) {
        this.editableProps = {};
        props?.forEach(p => {
            this.isEditable(p);
        });
    }

    buildExtras() {
        const oProps = this.getOtherProps();

        let extras = <UC.Empty />;
        if (oProps?.buildExtras) {
            extras = oProps.buildExtras();
        }
        if (oProps?.saving) {
            return (
                <>
                    <UC.Dialog show={oProps?.saving} key={this.getUniqueKey()}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <h1> {this.getTitle()}: {this.getSaveAction()} In Progress...</h1>
                        </div>
                    </UC.Dialog>
                    {extras}
                </>
            );
        } else {
            return <UC.Empty />;
        }

    }

    getCategoryProps() {
        return this.getCategory().props;
    }

    buildEntityProp(prop: EntityProp, noLabel: boolean = false) {

        if (prop.hidden || (!this.getEntityId() && prop.noNew)) { // new mode
            return undefined;
        }

        const otherProps = this.buildOtherPropsForEProp(noLabel, prop);
        // this.profiler.log(`Prop Edit OtherProps: `, prop.id, otherProps);

        return (
            <UC.PropComp
                key={Common.getUniqueKey()}
                entityProp={prop}
                entity={this.getEntity()}
                otherProps={otherProps}
                appID={this.getAppID()}
                editing={this.isEditable(prop)}
            />
        );


    }

    isEditable(prop: EntityProp) {
        if (this.editableProps[prop?.id]) {
            return true;
        }

        let editable = true;

        if (prop.hidden) { // hidden for this action
            editable = false;
        } else if (prop.disabled) { // disabled for this action
            editable = false;
        } else if (prop.readonly || prop.dataType === EntityConstants.PropType.DERIVED) {
            editable = false;
        } else if (!this.getEntityId() && prop.noNew) { // new mode
            editable = false;
        } else if (this.getEntityId() && prop.noEdit) {
            // view/read only
            editable = false;
        }

        if (editable) {
            this.editableProps[prop.id] = prop;
            // this.profiler.log(prop.label, ' - editable')
        } else {
            delete this.editableProps[prop.id];
        }

        return editable;
    }

    handleOnPropChanged = async (p: any, val: any) => {
        if (Common.checkType.String(p)) {
            // it is a prop-id. Find the prop
            p = EntityConstants.getPropByID(this.getCategory().props, p);
            if (p === undefined) {
                return;
            }
        }

        const setNewVal = async (prop: EntityProp, fromValue: any, toValue: any) => {
            this.setPropValue(prop, toValue,);
            this.validatePropValue(prop);
            const changedProp = { entity: this.getEntity(), prop: prop, from: fromValue, to: toValue, }
            const eProgressing = this.Event.Action.getProgressing();
            this.profiler.log('Entity Prop Changed: ', eProgressing, changedProp);
            await this.sendTrigger(eProgressing, undefined, undefined,
                undefined, undefined, undefined, undefined, undefined,
                { propChanged: changedProp }
            );

            EM.emit(Common.Event.ENTITY_PROP_CHANGED, changedProp);
        }

        const oldValue = this.getPropValue(p, this.getEntity());
        if (oldValue !== val) {
            // this.profiler.log(`The changed value for - ${p.id} is = '${val}'`, oldValue,);
            this.lastChangedProp = p;
            /*
            if (EntityConstants.isEntityType(p.dataType)) {
                // first clear 
                await setNewVal(p, oldValue, undefined);
                // then set new val 
                await setNewVal(p, undefined, val);
            } else {
                await setNewVal(p, oldValue, val);
            }
            */
            await setNewVal(p, oldValue, val);

        } else {
            // this.profiler.log('No change in prop-value.')
        }
    }

    buildOtherPropsForEProp(noLabel: boolean = false, prop: EntityProp) {
        const oProps = { ...super.buildOtherPropsForEProp(noLabel, prop) };
        oProps.onPropChanged = this.handleOnPropChanged;

        oProps.validationErrors = this.validationErrors;
        oProps.errorMsg = this.validationErrors[prop?.id];

        if (prop?.id === this.lastChangedProp?.id) {
            // this.profiler.log(`Set Focus on ${prop.id}`);
            this.lastChangedProp = undefined;
            oProps.setFocus = true;
        }
        return oProps;
    }

    getActionLinks() {
        const actionLinks: Array<any> = [];

        if (this.canSaveEntity()) {
            actionLinks.push(
                {
                    id: this.getSaveTitle(),
                    label: this.getSaveTitle(),
                    icon: this.getSaveIcon(),
                    action: this.validateAndSave,
                }
            );
        }

        if (this.canCancelEdit()) {
            actionLinks.push(
                {
                    id: this.getCancelTitle(),
                    label: this.getCancelTitle(),
                    icon: this.getCancelIcon(),
                    action: this.handleOnCancelSave,
                }
            );
        }

        if (this.isEditing()) {
            actionLinks.push(this.getFullViewRoute());
        }

        // Add Create/Edit Actions from Category
        this.addCatActions(actionLinks);

        return actionLinks;
    }

    canShowActions() {
        if (this.state.noActions !== undefined) {
            return !Boolean(this.state.noActions).valueOf();
        } else if (this.getOtherProps()?.noActions !== undefined) {
            return !Boolean(this.getOtherProps()?.noActions).valueOf();
        } else {
            return true;
        }
    }

    protected addCatActions(actions: any[]) {
        const catActions = this.getCategoryActions(this.getAction());

        catActions?.forEach(
            (viewAction: any) => {
                actions.push(viewAction);
            }
        );
    }

    getSaveTitle() {
        if (this.getOtherProps()?.saveTitle) {
            this.saveTitle = this.getOtherProps()?.saveTitle;
        }
        return this.saveTitle;
    }

    getSaveIcon() {
        return Images.Icon.save;
    }

    getCancelTitle() {
        if (this.getOtherProps()?.cancelTitle) {
            this.cancelTitle = this.getOtherProps()?.cancelTitle;
        }
        return this.cancelTitle;
    }

    getCancelIcon() {
        return Images.Icon.close;
    }

    getViewTitle() {
        if (this.getOtherProps()?.viewTitle) {
            this.viewTitle = this.getOtherProps()?.viewTitle;
        }
        return this.viewTitle;
    }

    buildActionButtons() {
        if (!this.canShowActions()) {
            this.profiler.log('Entity Actions are hidden.', this.getEntity(), this.state);
            return undefined;
        }
        let isToolBarActions = this.getCategoryConfigs()?.actions?.toolbar;
        if (isToolBarActions === undefined && Common.isSmallScreen()) {
            isToolBarActions = false;
        }

        return (
            <div style={{ textAlign: Common.isSmallScreen() ? 'center' : 'right' }}>
                {
                    this.buildActions(this.getActionLinks(), true, (isToolBarActions !== undefined) ? Boolean(isToolBarActions) : false)
                }
            </div>
        );
    }

    setActionsAtTop = () => {
        const defaultAtTop = false;
        let actionsAtTop: string = this.getCategoryConfigs()?.actions?.location;
        if (actionsAtTop) {
            actionsAtTop = actionsAtTop.trim().toLowerCase();
            switch (actionsAtTop) {
                case 'top':
                    return true;
                case 'bottom':
                    return false;
                default:
                    return defaultAtTop;
            }
        } else if (Common.isSmallScreen()) {
            return false;
        } else {
            return defaultAtTop;
        }

    }

    getMainRoute() {
        if (this.canViewMain()) {
            const toMain = this.buildRoute(Router.CatAction.MAIN, this.getEntityId());
            return { id: Router.CatAction.MAIN, label: `View All ${this.getCategory().label}`, to: toMain, icon: Images.Icon.home };
        }
        return undefined;
    }

    getEditRoute() {
        if (this.canEdit()) {
            const toEdit = this.buildRoute(Router.CatAction.EDIT, this.getEntityId());
            return { id: Router.CatAction.EDIT, label: 'Edit', to: toEdit, icon: Images.Icon.edit };
        }
        return undefined;
    }

    getFullViewRoute(entityID?: any) {
        if (this.canViewFull()) {
            const toView = this.buildRoute(Router.CatAction.VIEW, entityID ? entityID : this.getEntityId());
            return { id: Router.CatAction.VIEW, label: this.getViewTitle(), to: toView, icon: Images.Icon.view };
        }
        return undefined;
    }

    getTitle(): any {
        const otherProps = this.getOtherProps();
        if (otherProps) {
            if (otherProps.hideTitle) {
                return undefined;
            } else if (otherProps.title) {
                return otherProps.title;
            }
        }

        let name = Common.safeParse(this.getEntity()?.getName());
        if (name) {
            if (Common.checkType.Object(name)) {
                name = Common.stringify(name);
                // this.profiler.log('Object Name to String Name: ', name);
            }
            return (<span><i>{name} </i></span>);
        } else {
            return super.getTitle();
        }
    }

    buildValidationErrors = () => {
        return <></>;
        /*
        const errorItems = Object.values(this.validationErrors).map(
            (msg: any) => {
                return (<li key={this.getUniqueKey()} >{`${msg.label} : ${msg.error}`}</li>);
            }
        )
        return (errorItems?.length > 0) ?
            (
                <ul style={{ color: 'red' }} key={this.getUniqueKey()} >
                    {errorItems}
                </ul>
            ) 
            : <UC.Empty />;
        */
    }


}