import React from 'react';
import { DesignerConstants, UC, UnoCompEvents, UnoComponent, UnoCoreBaseComp } from '../../@uno/core';
import { Common, EntityConstants } from '../../@uno/api';
import { UnoSvgBase } from './uno-svg-base.comp';

@UnoComponent({
    id: 'UnoSvgCircle',
    label: 'SVG Circle',
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Graphics.id,
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    props: [
        {
            groupID: 'Config',
            id: 'radius',
            label: 'Radius',
            dataType: EntityConstants.PropType.NUMBER,
            validators: [EntityConstants.Validator.REQUIRED,],
        },
        {
            groupID: 'Config',
            id: 'center',
            label: 'Center',
            dataType: EntityConstants.PropType.JSON,
            editor: 'SVGPointEditor',
            viewer: 'SVGPointViewer'
        },
    ],
    isContainer: false,
})
export class UnoSvgCircle extends UnoSvgBase {

    buildSVG() {

        return (
            <circle
                key={this.getInputID()}
                style={this.getStyles()}
                className={this.getStyleClasses()}

                r={this.getRadius()}
                cx={this.getCX()}
                cy={this.getCY()}

            />
        );
    }

    getRadius() {
        return this.state.radius || '0';
    }

    getCX() {
        return this.getCenter()?.x || 0;
    }

    getCY() {
        return this.getCenter()?.y || 0;
    }

    getCenter() {
        return Common.safeParse(this.state.center);
    }
}

