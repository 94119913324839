import { UnoModule } from '../../@uno/core';
import { AframeComp } from './component/aframe.comp';
// The Module...

@UnoModule(
    {
        id: 'AframeModule',
        imports: [
        ],
        components: [
            AframeComp,
        ],
    }
)
export default class AframeModule {

}

