import React from 'react';
import { EntityCategoryService } from '../../../@uno-app/service/entity.category.service';
import { BaseEntity, Common } from '../../../@uno/api';
import { EntityConstants, EntityProp } from '../../../@uno/api/entity.service';
import { UC, UnoComponent } from '../../../@uno/core';
import { EntityViewTypes } from '../../service/entity-prop.service';
import { BasePropViewer } from '../prop-base.comp';

@UnoComponent({ id: 'PropEntityViewer' })
export class PropEntityViewer extends BasePropViewer {
    // propEntityCategory: EntityCategory | undefined;
    selectedEntity: BaseEntity | undefined;

    constructor(props: any) {
        super(props);
        this.state = { ...this.state, editing: false };
        this.setDefaultValue();
    }

    componentDidMount() {
        const catID = this.getCategoryID();
        // console.log(`Creating Entity Editor for : ${Common.stringify(this.getEProp())} , Category: ${this.getCategory()?.id}`);
        if (catID && !this.getCategory()) {
            EntityCategoryService.getCategory(catID)
                .then(
                    category => {
                        //this.category = category;
                        this.setState({ category: category });
                    }
                );
        }
    }

    // load selected entity
    setDefaultValue() {
        const defValObj = this.getDefaultValue();
        if (defValObj === undefined || defValObj === null) {
            this.selectedEntity = undefined;
            return;
        }
        const defVal = EntityConstants.build(defValObj ? Common.safeParse(this.getDefaultValue()) : undefined);
        if (!this.getCategoryID() || defVal.getCategoryID() === this.getCategoryID()) {
            if (this.selectedEntity?.getID() !== defVal.getID()) {
                // console.log(`Setting Default Value for prop - `, this.getEPropID(), defVal, this.selectedEntity);
                this.selectedEntity = defVal;

                // load full for quick view.
                if (this.selectedEntity.getAppID()) {
                    this.selectedEntity.reload().then(this.reRender);
                    /*
                    EntityConstants.getEntityService(this.selectedEntity.getAppID(), this.selectedEntity.getCategoryID())
                        .findByID(this.selectedEntity.getID())
                        .then(record => {
                            if (record) {
                                this.selectedEntity = EntityConstants.build(record);
                                this.setState({});
                            }
                        });
                    */
                }
            }
        }
    }

    getDefaultValue() {
        let defValue = super.getDefaultValue();
        if (defValue instanceof Object) {
            defValue = defValue.toString();
        }
        return defValue;
    }

    buildValue() {
        if (this.selectedEntity) {
            const PropEntityView = this.getViewer();
            const otherProps = {
                ...this.props.otherProps,
                canSelect: this.canSelect(),
                canEdit: this.canEdit(),
                hideTitle: this.hideTitle(),
                // hideLabel: this.hideLabel(),
                canViewFull: this.canViewFull(),
                canViewMain: this.canViewMain(),
                viewType: this.getViewType(),
                showPrint: this.canPrint(),
                showPermissions: this.canSetPermission(),
                propLayout: this.getPropLayout(),
                propLabelPosition: this.getPropLabelPosition(),
                canViewUUID: false,
            };


            // console.log('Prop Entity View: ', this.renderCnt, this.getCategory()?.id, this.selectedEntity.getID(), PropEntityView?.name);
            return (
                <PropEntityView
                    entity={this.selectedEntity}
                    category={this.getCategory()}
                    // categoryID={this.getCategoryID()}
                    otherProps={otherProps}
                    key={Common.getUniqueKey()}
                />
            );
        } else {
            // console.log('No SelectedEntity: ', this.getDefaultValue());
            return (
                <span>
                    {this.getDefaultValue()}
                </span>
            )
        }

    }

    canPrint() {
        return false;
    }

    canSetPermission() {
        return false;
    }

    getViewType() {
        return EntityViewTypes.QUICK;
    }

    canViewFull() {
        return false;
    }

    canEdit() {
        return false;
    }

    canSelect() {
        return false;
    }

    hideTitle() {
        return true;
    }

    hideLabel() {
        return true;
    }

    getViewer() {
        return UC.EntityView;
    }

    canViewMain() {
        return true;
    }
}

@UnoComponent({ id: 'PropInlineEntityViewer' })
export class PropInlineEntityViewer extends PropEntityViewer {
    getViewType() {
        return EntityViewTypes.FULL;
    }

    canEdit() {
        return false;
    }

    canViewFull() {
        return false;
    }

    canSelect() {
        return false;
    }

    getViewer() {
        return UC.EntityView;
    }

    canViewMain() {
        return false;
    }

    hideTitle() {
        return true;
    }
}
