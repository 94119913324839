import React, { useState } from "react";
import { LoginButton, useLogin } from "react-facebook";
import { EntityConstants } from "../../../@uno/api";
import { Common, ExternalUser } from "../../../@uno/api/common.service";
import { DesignerConstants, UnoComponent, UnoCoreBaseComp } from "../../../@uno/core";
import icon_sign from './fb-signin-button.png';
import { Source } from "../../../@uno/api/source.service";
import { FBLogin } from '../../../@uno/react/auth/fb';


function LoginButtonExample() {
    function handleSuccess(response: any) {
        console.log(response.status);
    }

    function handleError(error: any) {
        console.log(error);
    }

    return (
        <LoginButton
            scope="email"
            onError={handleError}
            onSuccess={handleSuccess}
        >
            Login via Facebook
        </LoginButton>
    );
}

function UnoFacebookLogin(props: { onSuccess: (data: ExternalUser) => void, graphData: any }) {
    const [profile, setProfile] = useState();

    const { login, status, isLoading, error } = useLogin();

    const handleLogin = async () => {
        try {
            // console.log('Do FB Login');
            const response = await login({
                scope: 'email,public_profile',
            });
            const token = response?.authResponse?.accessToken;

            // console.log('FB Login: ', response);
            const FB: any = FBLogin.fb; // window.FB;
            if (FB && token) {
                FB.api('/me', function (meRes: any) {
                    // console.log('FB User:', JSON.stringify(meRes));
                    // console.log('User Profile: ', res.data);
                    const profileData = meRes;

                    setProfile(profileData);
                    // trigger action
                    const action = props.onSuccess;
                    if (action) {
                        //props.onSuccess({ mode: 'google', token: user, profile: profileData });
                        const inputs = { data: { mode: 'facebook', token: token, profile: profileData } };
                        if (Common.checkType.Function(action)) {
                            action(inputs.data);
                        } else if (Common.checkType.String(action)) {
                            const fn = Source.getFunction(action);
                            if (fn) {
                                fn(inputs);
                            }
                        }
                    }
                });
            }


        } catch (error: any) {
            console.log(error);
        }
    }
    const theProfile: any = profile;

    return (
        <div>
            {theProfile ? (
                <div>
                    <div id="profile-div">
                        <div id="profile-div">
                            <p>
                                <strong>First Name: </strong> {theProfile.givenName}
                            </p>
                            <p>
                                <strong>Last Name: </strong> {theProfile.surname}
                            </p>
                            <p>
                                <strong>Email: </strong> {theProfile.userPrincipalName}
                            </p>
                            <p>
                                <strong>Id: </strong> {theProfile.id}
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <img
                    title='Sign In with Facebook'
                    src={icon_sign}
                    className=' signin signin-external '
                    onClick={handleLogin}
                />
            )}
        </div>
    );
}

@UnoComponent({
    id: 'UnoFacebookLogin',
    label: 'Facebook Login',
    props: [
        { id: 'onSuccess', label: 'On Login Success', dataType: EntityConstants.PropType.FUNCTION, },
    ],
    getDesign: () => { return (<div><button>Sign in with Facebook 🚀 </button></div>); },
    getPreview: () => { return (<div><button>Sign in with Facebook 🚀 </button></div>); },
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class UnoFacebookLoginComp extends UnoCoreBaseComp {
    
    buildComp(): JSX.Element | null | undefined {
        const theProps: any = this.props;
        if (!FBLogin.fb) {
            return undefined;
        } else {
            return (
                <>
                    <UnoFacebookLogin {...theProps} />
                </>
            );
        }
    }
}