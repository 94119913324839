import React from 'react';
import { Common, UC, UnoComponent } from "../../@uno/core";
import { BasePropEditor } from '../../@uno-entity-prop/components/prop-base.comp';

@UnoComponent({
    id: 'SVGPointEditor',
    label: 'SVG Point Editor',
    paletteable: false,
})
export class UnoSvgPointEditor extends BasePropEditor {
    XY: any = {};

    buildComp() {
        this.init();
        return (
            <>
                <UC.VSection
                    key={Common.getUUID()}
                    cols='2'
                    cellStyles={{ padding: '5px' }}
                >
                    <UC.VSection>
                        X-Cord
                        <input
                            defaultValue={this.getX()}
                            onBlur={(evt) => { this.setX(evt.target.value) }}
                            type='number'
                        />
                    </UC.VSection>
                    <UC.VSection>
                        Y-Cord
                        <input
                            defaultValue={this.getY()}
                            onBlur={(evt) => { this.setY(evt.target.value) }}
                            type='number'
                        />
                    </UC.VSection>
                </UC.VSection>
            </>
        )
    }

    init() {
        this.XY = Common.safeParse(this.getDefaultValue() || {});
        // console.log('XY: ', this.XY);
    }

    getX() {
        return this.XY.x;
    }

    setX(val: any) {
        this.XY.x = val;
        this.update();
    }

    getY() {
        return this.XY.y;
    }

    setY(val: any) {
        this.XY.y = val;
        this.update();
    }

    update() {
        // console.log('Updating XY: ', this.XY);
        this.setPropValue(this.XY);
    }

}

@UnoComponent({
    id: 'SVGPointViewer',
    label: 'SVG Point Viewer',
    paletteable: false,
})
export class UnoSvgPointViewer extends BasePropEditor {
    XY: any = {};

    buildComp() {
        this.init();

        return (
            <>
                X = {this.getX()}, Y = {this.getY()}
            </>
        )
    }

    init() {
        this.XY = Common.safeParse(this.getDefaultValue() || {});
        // console.log('XY: ', this.XY);
    }

    getX() {
        return this.XY.x;
    }

    getY() {
        return this.XY.y;
    }

}