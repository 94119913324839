import React from 'react';
import { Common, Router, ExternalUser } from '../../../@uno/api/common.service';
import { EM } from '../../../@uno/api/event-mgmt.service';
import { DesignerConstants, Images, UC, UnoComponent } from '../../../@uno/core';
import { TriggerService } from '../../service/entity-trigger.service';
import { UnoGoogleLoginComp } from './google.auth.comp';
import { SignUp } from './signup.comp';
import { AppInfoService } from '../../../@uno-app/service/app.info.service';
import { UnoMicrosoftLoginComp } from './ms.auth.comp';
import { UnoFacebookLoginComp } from './facebook.auth.comp';


// ENTITY - NEW, EDIT
@UnoComponent({
    id: 'SignIn',
    label: 'Sign In',
    props: [
    ],
    getDesign: () => { return (<div>Sign In - Design</div>); },
    // paletteable:true,
    group: DesignerConstants.PaletteGroup.Layout.id,
})
export class SignIn extends SignUp {

    componentDidMount(): void {
        super.componentDidMount();

        setTimeout(async () => {
            const modes = await AppInfoService.getSupportedLoginModes(this.getAppID());
            this.setState({ loginModes: modes });
        }, 1);
        /*
        const otplessUser = (otplessUser: any) => {
            var waName = otplessUser.waName;
            var waNumber = otplessUser.waNumber;
            // Signup/signin the user and redirect to next page
            const msg = 'OPTLess Sign In: ' + Common.stringify(otplessUser);
            console.log(msg);
            alert(msg);
        }

        const win: any = window;
        // win.otpless = otplessUser;

        if (!win.OTPlessScript) {
            Common.addScript('https://otpless.com/auth.js')
        } else {
            win.otplessUser = null;
            win.otplessInit();
        }

        win.OTPlessCallbackAction = otplessUser;
        // this.loadOTPlessScript();
        */
    }

    buildContent(): JSX.Element {
        const contentSuper = super.buildContent();
        const externals = this.buildExternalSignIns();
        return (
            <>
                {contentSuper}
                {externals}
            </>
        );
    }

    buildExternalSignIns() {
        const loginModes = Common.safeParse(this.state.loginModes) || [];
        const loginComps: Array<any> = loginModes.map((mode: string) => {
            switch (mode) {
                case 'google':
                    return (<UnoGoogleLoginComp onSuccess={this.triggerExternalSignInRequested} key={Common.getUniqueKey('login_comp_')} />);
                case 'microsoft':
                    return (<UnoMicrosoftLoginComp onSuccess={this.triggerExternalSignInRequested} key={Common.getUniqueKey('login_comp_')} />);
                case 'facebook':
                    return (<UnoFacebookLoginComp onSuccess={this.triggerExternalSignInRequested} key={Common.getUniqueKey('login_comp_')} />);
                default:
                    return (<></>);
            }
        });
        const cols = `${loginModes.length}`;

        return (
            <UC.VSection
                cols={cols}
                // cols='3'
                styleClasses='center'
                key={Common.getUniqueKey()}
            >
                {loginComps}
            </UC.VSection>
        );
    }

    triggerExternalSignInRequested = async (user: ExternalUser) => {
        const result = await TriggerService.send({ user: user }, Common.Event.getActionStage(Router.AppAction.SIGNIN_EXTERNAL, Common.Event.Stage.REQUESTED))
        this.handleOnSave(result);
    }

    getTitle() {
        return 'Sign In';
    }

    getSaveIcon() {
        return Images.Icon.signin;
    }

    getAction() {
        return Router.AppAction.SIGNIN;
    }

    handleOnSave = async (result: any) => {
        if (result) {
            if (result.error) {
                // this.showMessage(result.error, true);
                this.showMessage('Sign In Failed.', true);
            } else {
                result = await TriggerService.send(result, this.Event.Save.getCompleted(), false);
                if (result?.message) {
                    this.showMessage(result.message);
                }

                if (result?.error) {
                    this.showMessage(result.error, true);
                } else {
                    this.hideSavingMsg();
                    EM.emit(Common.Event.SIGNED_IN, { appID: this.getAppID(), session: result.session, route: Common.Router.getQueryParam('path'), });
                }
            }
        }
    }

    responseMessage = (response: any) => {
        console.log(response);
    };

    errorMessage = (error?: any) => {
        console.log(error);
    };

}
