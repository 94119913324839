import React from 'react';
import { EntityConstants } from '../../../@uno/api/entity.service';
import { DesignerConstants, UC, UnoComponent } from '../../../@uno/core';
import { ENTITY_LIST_COMP_PROPS, EntityListView } from './entity-list.view.comp';

// MULTI ENTITY - CAROUSEL VIEW
@UnoComponent({ 
    id: EntityConstants.ListViewTypes.Carousel.id, 
    label: EntityConstants.ListViewTypes.Carousel.label,
    props: ENTITY_LIST_COMP_PROPS,
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Entity.id,
})
export class EntityCaraouselView extends EntityListView {

    buildContent() {
        return (
            <UC.Carousel>
                {this.buildEntities()}
            </UC.Carousel>
        );
    }

}
