
// The Module...

import { UnoModule } from "../@uno/core";
import { UnoSvgCircle } from "./components/uno-svg-circle.comp";
import { UnoSvgEllipse } from "./components/uno-svg-ellipse.comp";
import { UnoSvgLine } from "./components/uno-svg-line.comp";
import { UnoSvgPointEditor, UnoSvgPointViewer } from "./components/uno-svg-point.comp";
import { UnoSvgPolygon } from "./components/uno-svg-polygon.comp";
import { UnoSvgPolyline } from "./components/uno-svg-polyline.comp";
import { UnoSvgRect } from "./components/uno-svg-rect.comp";
import { UnoSVG } from "./components/uno-svg.comp";

@UnoModule(
    {
        id: 'UnoSVGModule',

        imports: [
        ],

        components: [
            UnoSVG,
            UnoSvgRect,
            UnoSvgCircle,
            UnoSvgEllipse,
            UnoSvgLine,
            UnoSvgPolygon,
            UnoSvgPolyline,

            UnoSvgPointEditor,
            UnoSvgPointViewer,
        ],
    }
)
export default class UnoSVGModule  {

}

