import React from 'react';
import { EntityConstants } from '../../../api';
import { DesignerConstants, UnoComponent, Images } from '../../../core';
import { Dialog, DialogConstants, DialogCompProps } from './dialog.comp';

export const SectionCompProps = [
    ...DialogCompProps,
    { groupID: 'General', id: 'accordion', label: 'Show as Accordion?', dataType: EntityConstants.PropType.BOOLEAN },
];

@UnoComponent({
    id: 'Section',
    icon: Images.Icon.section,
    isContainer: true,
    paletteable: true,
    props: SectionCompProps,
    events: Object.values(DialogConstants.Event),
    group: DesignerConstants.PaletteGroup.Frequent.id,
    serialNo: 101,
})
export class Section extends Dialog {

    constructor(props: any) {
        super(props);
        this.state = { ...this.state, screenMode: DialogConstants.Screen.INLINE, }
    }

    buildOnResizeAction() {
        if (this.isAccordion()) {
            const toggleClass = (this.maximized) ? ' arrow-up ' : ' arrow-down ';
            let toggleLink = (
                <div
                    // onClick={this.doResize}
                    className={toggleClass}
                    title={this.getResizeActionTitle()}
                />
            );
            return toggleLink;
        } else {
            return super.buildOnResizeAction();
        }
    }

    isAccordion() {
        return Boolean(this.state.accordion).valueOf();
    }

    isResizable() {
        return (this.isAccordion() || super.isResizable());
    }

    isDialog() {
        return false;
    }

}
