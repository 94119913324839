import React, { useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Common, DesignerConstants, Images, UnoComponent, } from '../../../core';
import { UnoCoreBaseComp } from './../uno-core.base.comp';
import { EM, EntityConstants } from '../../../api';

const STTComp = (props: any) => {
    const [recording, setRecording] = useState(false);
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    if (!browserSupportsSpeechRecognition) {
        console.log('Browser doesnot support speech recognition.');
        return null;
    }

    const handleSTT = async (evt: any) => {
        const onSubmit = props.onSubmit;
        const onStart = props.onStart;

        if (listening) {
            setRecording(false);
            await SpeechRecognition.stopListening();
            if (transcript && onSubmit && Common.checkType.Function(onSubmit)) {
                onSubmit(transcript);
                // resetTranscript();
            }
            EM.emit('STT_OFF');
        } else {
            EM.emit('STT_ON');
            setRecording(true);
            await SpeechRecognition.startListening(props.options);
            if (onStart && Common.checkType.Function(onStart)) {
                onStart();
            }
        }

    }

    return (
        <div style={{ display: 'inline', margin: '5px' }}>
            <img
                src={recording ? Images.Icon.mic_on : Images.Icon.mic_off}
                title={recording ? 'Press to Stop' : `Press to turn it green. Speak. ${props.placeholder}`}
                className='tool-icon'
                onClick={handleSTT}
            />
            {/* <p>{transcript}</p> */}
        </div>
    );
};

// export default STT;

@UnoComponent({
    id: 'STT',
    label: 'Speech-to-Text',
    group: DesignerConstants.PaletteGroup.Editor.id,
    props: [
        { id: 'onSart', label: 'on Start Recording Handler', dataType: EntityConstants.PropType.FUNCTION },
        { id: 'onSubmit', label: 'on Submit Recording Handler', dataType: EntityConstants.PropType.FUNCTION },
        { id: 'options', label: 'Options', dataType: EntityConstants.PropType.JSON },
        { id: 'mic_on', label: 'On Mic Indicator:', dataType: EntityConstants.PropType.IMAGE },
        { id: 'mic_off', label: 'Off Mic Indicator:', dataType: EntityConstants.PropType.IMAGE },
    ],
    paletteable: false,
})
export class STT extends UnoCoreBaseComp {
    buildComp(): JSX.Element | null | undefined {
        return (<STTComp {...this.state} />);
    }
}