import { useMsal } from "@azure/msal-react";
import axios from 'axios';
import React, { useState } from "react";
import { EntityConstants } from "../../../@uno/api";
import { Common, ExternalUser, Router } from "../../../@uno/api/common.service";
import { DesignerConstants, UnoComponent, UnoCoreBaseComp } from "../../../@uno/core";
import icon_sign from './ms-signin-button.png';
import { Source } from "../../../@uno/api/source.service";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from '../../../@uno/react/auth/msal';

const loginRequest = {
    scopes: ["User.Read"],
};

function UnoMicrosoftLogin(props: { onSuccess: (data: ExternalUser) => void, graphData: any }) {
    const { instance } = useMsal();
    const [profile, setProfile] = useState();

    const handleLogin = (loginType: string) => {
        if (loginType === "popup") {
            const appOriginPath = Router.getOrigin();
            instance
                .loginPopup({ ...loginRequest, redirectUri: appOriginPath, })
                .then(user => {
                    // console.log('MS Login Successful: ', user);
                    axios
                        .get(`https://graph.microsoft.com/v1.0/me`, {
                            headers: {
                                Authorization: `Bearer ${user.accessToken}`,
                                Accept: 'application/json'
                            }
                        })
                        .then((res) => {
                            // console.log('User Profile: ', res.data);
                            const profileData = res.data;

                            setProfile(profileData);
                            // trigger action
                            const action = props.onSuccess;
                            if (action) {
                                //props.onSuccess({ mode: 'google', token: user, profile: profileData });
                                const inputs = { data: { mode: 'microsoft', token: user, profile: profileData } };
                                if (Common.checkType.Function(action)) {
                                    action(inputs.data);
                                } else if (Common.checkType.String(action)) {
                                    const fn = Source.getFunction(action);
                                    if (fn) {
                                        fn(inputs);
                                    }
                                }
                            }

                        })
                        .catch((err) => console.log(err));
                })
                .catch((e) => {
                    console.log(e);
                });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch((e) => {
                console.log(e);
            });
        }
    };

    const handleLogout = (logoutType: string) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                // postLogoutRedirectUri: "/",
                // mainWindowRedirectUri: "/",
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                // postLogoutRedirectUri: "/",
            });
        }
    };
    const theProfile: any = profile;

    return (
        <div>
            {theProfile ? (
                <div>
                    <div id="profile-div">
                        <div id="profile-div">
                            <p>
                                <strong>First Name: </strong> {theProfile.givenName}
                            </p>
                            <p>
                                <strong>Last Name: </strong> {theProfile.surname}
                            </p>
                            <p>
                                <strong>Email: </strong> {theProfile.userPrincipalName}
                            </p>
                            <p>
                                <strong>Id: </strong> {theProfile.id}
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <img
                    title='Sign In with Microsoft'
                    src={icon_sign}
                    className=' signin signin-external '
                    onClick={() => handleLogin("popup")}
                />
            )}
        </div>
    );
}

@UnoComponent({
    id: 'UnoMicrosoftLogin',
    label: 'Microsoft Login',
    props: [
        { id: 'onSuccess', label: 'On Login Success', dataType: EntityConstants.PropType.FUNCTION, },
    ],
    getDesign: () => { return (<div><button>Sign in with Microsoft 🚀 </button></div>); },
    getPreview: () => { return (<div><button>Sign in with Microsoft 🚀 </button></div>); },
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class UnoMicrosoftLoginComp extends UnoCoreBaseComp {

    buildComp(): JSX.Element | null | undefined {
        let msalInstance: any = undefined;
        try {
            msalInstance = new PublicClientApplication(msalConfig);
        } catch (e) {
            return undefined;
        }

        const theProps: any = this.props;
        return (
            <>
                <MsalProvider instance={msalInstance}>
                    <UnoMicrosoftLogin {...theProps} />
                </MsalProvider>
            </>
        );
    }
}