import React from 'react';
import { DesignerConstants, UnoCompEvents, UnoComponent } from '../../@uno/core';
import { UnoSvgBase } from './uno-svg-base.comp';

@UnoComponent({
    id: 'UnoSVG',
    label: 'SVG Container',
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Graphics.id,
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    props: [
        {
            groupID: 'Config',
            id: 'width',
            label: 'Width',
        },
        {
            groupID: 'Config',
            id: 'height',
            label: 'Height',
        },
    ],
    isContainer: true,
    serialNo: 1,
})
export class UnoSVG extends UnoSvgBase {

    buildSVG() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                key={this.getInputID()}
                width={this.getWidth()}
                height={this.getHeight()}
                style={this.getStyles()}
                className={this.getStyleClasses()}

            >
                {this.props.children}
            </svg>
        );
    }

    getWidth() {
        return this.state.width || '100%';
    }

    getHeight() {
        return this.state.height || '100%';
    }
}

