import React from 'react';
import { EntityConstants } from '../../@uno/api';
import { Common, DesignerConstants, UC, UnoComponent } from '../../@uno/core';
import { AppMenuService } from '../service/app.menu.service';
import { SessionManager } from '../service/session.service';
import { UnoAppBaseComp } from './uno-app.base.comp';

@UnoComponent({
    id: 'Menu',
    label: 'The Menu',
    props: [
        { groupID: 'Configuration', id: 'menu', label: 'Menu', dataType: EntityConstants.PropType.ENTITY, category: Common.CategoryID.MenuDef },
        { groupID: 'Configuration', id: 'navs', label: 'Menu Items', dataType: EntityConstants.PropType.JSON, },
        { groupID: 'Configuration', id: 'menuID', label: 'Menu ID' },
        {
            groupID: 'Settings',
            id: 'orientation', label: 'Orientation',
            editor: 'OptionSelector',
            viewer: 'OptionViewer',
            extras: {
                options: [
                    { id: 'h', label: 'Horizontal' },
                    { id: 'v', label: 'Vertical' },
                ]
            },
        },
        {
            groupID: 'Settings',
            id: 'isToolbar', label: 'Is Toolbar?',
            editor: 'OptionSelector',
            viewer: 'OptionViewer',
            extras: {
                options: [
                    { id: 'true', label: 'Yes', },
                    { id: 'false', label: 'No', isDefault: true, },
                ]
            }
        },
        { groupID: 'Settings', id: 'noTree', label: 'Not A Tree?', dataType: EntityConstants.PropType.BOOLEAN },
        { groupID: 'Settings', id: 'visibleChildCount', label: 'No of Visible Children', dataType: EntityConstants.PropType.NUMBER },
        { groupID: 'Settings', id: 'isAccessControlled', label: 'is Access Controlled?', dataType: EntityConstants.PropType.BOOLEAN },

        { groupID: 'Settings', id: 'otherInfo', label: 'More Settings', dataType: EntityConstants.PropType.JSON },
        { groupID: 'Style', id: 'styleClasses', label: 'Style Classes', dataType: EntityConstants.PropType.JSON, },
    ],
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Layout.id,
})
export class MenuComp extends UnoAppBaseComp {

    constructor(props: any) {
        super(props);
        this.state = { ...props };
    }

    componentDidMount() {
        super.componentDidMount();
        this.loadMenu();
    }

    buildComp() {
        const orientation: string = this.props.orientation;
        const isHorizontal = () => {
            return (orientation === 'h');
        }
        let navs = Common.safeParse(this.state.navs);
        // console.log(`Building ${navs?.length} Menu.`, this.state);
        let menuViews = undefined;
        const otherInfo = Common.safeParse(this.state.otherInfo || {});
        if (navs?.length > 0) {
            const Nav = (this.state.isAccessControlled === true) ? (
                <UC.AccessControlledActions
                    actions={navs}
                    horizontal={orientation === 'h'}
                    isToolbar={this.state.isToolbar || false}
                    key={Common.getUniqueKey()}
                    styleClasses={this.state.styleClasses}
                    otherInfo={{
                        visibleChildCount: this.state.visibleChildCount,
                        isTree: (this.state.noTree !== undefined) ? !this.state.noTree : !isHorizontal(),
                        ...otherInfo
                    }}
                />
            ) : (
                <UC.Navigation
                    navs={navs}
                    orientation={orientation}
                    isToolbar={this.state.isToolbar || false}
                    key={Common.getUniqueKey()}
                    isTree={(this.state.noTree !== undefined) ? !this.state.noTree : !isHorizontal()}
                    visibleChildCount={this.state.visibleChildCount}
                    styleClasses={this.state.styleClasses}
                    {...otherInfo}
                />
            );

            menuViews = (
                <div key={Common.getUniqueKey()} style={{ display: (isHorizontal() ? 'inline-block' : 'block'), marginTop: '2px' }}>
                    {Nav}
                </div>
            );
        } else {
            menuViews = (<UC.Empty />);
        }

        return (
            <>
                {menuViews}
            </>
        );
    }

    private async loadMenu(appID: string = this.getAppID()) {
        const session = SessionManager.activeSession;
        if (appID === undefined) {
            return;
        }
        const menuID = this.state.menuID;
        const menu = this.state.menu;
        // console.log('Refresh menu for app - ', appID, session?.id, menuID, menu);
        if (menuID || menu) {
            const navs = await AppMenuService.findMenu(appID, menuID, Common.safeParse(menu), session);
            this.setState({ navs: navs });
        }
    }

}
