import React from 'react';
import { DesignerConstants, UnoComponent, UnoCoreBaseComp } from '../../@uno/core';
import UnoFlow from './uno-flow.comp';

@UnoComponent(
    {
        id: 'SvgDesigner',
        label: 'SVG Designer',
        paletteable: true,
        props: [

        ],
        group: DesignerConstants.PaletteGroup.Graphics.id,
    }
)
export class SvgDesignerComp extends UnoCoreBaseComp {
    nodeClassName = (node: any) => node.type;

    buildComp() {

        return (
            <>
                {this.buildFlow()}
            </>
        )
    }

    buildFlow = () => {
        return (
            <>
                <UnoFlow />
            </>
        );
    }

}

